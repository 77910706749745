import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { getElementFromPage, getPage } from '../../../services/SanityService'
import { IPage, PageName } from '../../../typeDefinitions'
import InstallationInformation from '../../InstallInformation/InstallInformation'
import { LanguageContext } from '../../Providers/LanguageProvider'
import styles from './footer.module.scss'

export default function Footer() {
  const lang = useContext(LanguageContext)
  const { data: sanityData } = useQuery<IPage>([PageName.Layout, lang.language], () =>
    getPage(PageName.Layout, lang.language)
  )

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.linksContainer}>
        <a
          className={styles.linksContainerLink}
          href={lang.language === 'no' ? 'https://www.skyss.no/avvik/' : 'https://www.skyss.no/en/service-messages/'}
          target="_blank"
        >
          {getElementFromPage('hDeviationMessages', sanityData, lang.language)}
          <img
            className={styles.footerIconArrow}
            src="icons/footerPilOpp.svg"
            alt={lang.language === 'no' ? 'pil opp mot høyre' : 'right and up arrow'}
          />
        </a>
        <Link className={styles.linksContainerLink} to="/personvern">
          {getElementFromPage('headerPrivacy', sanityData, lang.language)}
          <img
            className={styles.footerIconArrow}
            src="icons/footerPilRett.svg"
            alt={lang.language === 'no' ? 'pil høyre' : 'right arrow'}
          />
        </Link>
        <a
          className={styles.linksContainerLink}
          href={getElementFromPage('linkPrivacy', sanityData, lang.language)}
          target="_blank"
        >
          {getElementFromPage('footerPrivacy', sanityData, lang.language)}
          <img
            className={styles.footerIconArrow}
            src="icons/footerPilOpp.svg"
            alt={lang.language === 'no' ? 'pil opp mot høyre' : 'right and up arrow'}
          />
        </a>
        <a className={styles.linksContainerLink} href="https://www.bybanen.no/" target="_blank">
          {getElementFromPage('bybanenHomepage', sanityData, lang.language)}
          <img
            className={styles.footerIconArrow}
            src="icons/footerPilOpp.svg"
            alt={lang.language === 'no' ? 'pil opp mot høyre' : 'right and up arrow'}
          />
        </a>
      </div>
      <div className={styles.trademarkContainer}>
        <p className={styles.copyrightText} aria-label="Bybanen copyright">
          {getElementFromPage('copyright', sanityData, lang.language)}
        </p>
        <InstallationInformation />
        <p className={styles.copyrightText}>{getElementFromPage('footerBouvet', sanityData, lang.language)}</p>
      </div>
    </footer>
  )
}
