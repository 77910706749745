import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import { ApiContext } from '../components/Providers/ApiProvider'
import { ILine } from '../typeDefinitions'

export type LineQueryResult = {
  line: ILine | undefined
  isLoading: boolean
  error: Error | null
}

export type LinesQueryResult = {
  lines: ILine[] | undefined
  isLoading: boolean
  error: Error | null
}

export function useLineQuery(id: number): LineQueryResult {
  const api = useContext(ApiContext)

  const { data, isLoading, error } = useQuery<ILine[], Error>(['lines'], () => api.getLines(), {
    refetchInterval: 10000,
  })

  const line = data?.find((line) => line.id === id)

  return { line, isLoading, error }
}

export function useLinesQuery(): LinesQueryResult {
  const api = useContext(ApiContext)

  const {
    data: lines,
    isLoading,
    error,
  } = useQuery<ILine[], Error>(['lines'], () => api.getLines(), {
    refetchInterval: 10000,
  })

  return { lines, isLoading, error }
}
