import { useContext, useState } from 'react'

import { LanguageContext } from '../Providers/LanguageProvider'
import styles from './ClickableStopCard.module.scss'

interface IClickableStopCardProps {
  stopName: string
  lineId: number
  rightIcon?: string
  isStopClosed?: boolean
  handleClick?: (lineId: number, stopName: string) => void
  isFirst?: boolean
  isLast?: boolean
  displayName?: string
  fromSearch?: boolean
}

export default function ClickableStopCard({
  stopName,
  lineId,
  rightIcon,
  isStopClosed,
  handleClick,
  isFirst,
  isLast,
  displayName,
  fromSearch,
}: IClickableStopCardProps) {
  const lang = useContext(LanguageContext)
  const [dropDown, setDropDown] = useState('clickableStopCard')

  return (
    <button
      className={styles[dropDown]}
      tabIndex={0}
      onClick={(event) => {
        event.preventDefault()
        handleClick?.(lineId, stopName)
        setDropDown(dropDown === 'clickableStopCard' ? 'clickableStopCardSelected' : 'clickableStopCard')
      }}
      onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && handleClick}
      data-testid="ClickableStopCard"
    >
      <span className={isFirst ? styles.circleLineFirst : isLast ? styles.circleLineLast : styles.circleLine}>
        {isStopClosed ? (
          <img src="icons\stop_closed.svg" />
        ) : fromSearch ? (
          <span className={styles.circle} />
        ) : stopName === 'Byparken' ? (
          <img src="icons\Byparken.svg" alt="Byparken" />
        ) : stopName === 'Bergen lufthavn' ? (
          <img src="icons\Bergen lufthavn.svg" alt="Bergen lufthavn" />
        ) : stopName === 'Kaigaten' ? (
          <img src="icons\Kaigaten.svg" alt="Kaigaten" />
        ) : stopName === 'Fyllingsdalen terminal' ? (
          <img src="icons\Fyllingsdalen.svg" alt="Fyllingsdalen" />
        ) : (
          <span className={styles.circle} />
        )}
      </span>
      <div className={styles.clickableStopCardTextContainer}>
        <div style={{ textAlign: 'left' }}>
          <span>{displayName ? displayName : stopName}</span>
        </div>
        {dropDown === 'clickableStopCard' ? (
          rightIcon && <img src={rightIcon} alt={lang.language === 'no' ? 'pil høyre' : 'arrow right'} />
        ) : (
          <img className={styles.downarrow} src="icons\down-arrow-black.svg" alt="arrow down" />
        )}
      </div>
    </button>
  )
}
