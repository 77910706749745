import { format, isValid, parseISO } from 'date-fns'
import { nb } from 'date-fns/locale'

export function formatTime(time?: string | number) {
  if (!time) return '--:--'
  var parsedTime = typeof time === 'number' ? time : Date.parse(time)
  if (!isValid(parsedTime) || Number(time) < 1640991600000)
    // Time must be after 01.01.2022
    return '--:--'
  return format(parsedTime, 'HH:mm', { locale: nb })
}

export function formatDate(date?: string) {
  if (!date) return 'dd.mm.yyyy'
  const parsedDate = parseISO(date)
  if (!isValid(parsedDate)) return 'dd.mm.yyyy'
  return format(parsedDate, 'dd.MM.yyyy')
}
