import { useContext } from 'react'
import { flushSync } from 'react-dom'

import { variablesForScrollling } from '../../../../utils/glbVariables'
import { ThemeContext } from '../../../Providers/ThemeProvider'
import styles from './DepartureContent.module.scss'

interface IDepartureContentProps {
  rightText: string
  leftText: string
  icon: string
  currentStopName: string
  isItemContent: boolean
  handleClick?: Function
  isLast?: boolean
  isFirst?: boolean
}

export default function DepartureContent({
  rightText,
  leftText,
  icon,
  currentStopName,
  isItemContent,
  handleClick,
  isLast,
  isFirst,
}: IDepartureContentProps) {
  const { theme } = useContext(ThemeContext)
  const circle = (): JSX.Element => {
    switch (icon) {
      case currentStopName:
        return <img className={styles.iconPin} src="icons/pin.svg" />
      case 'Byparken':
        return <img className={styles.icon} src={`${'icons/Byparken-'}${[theme]}${'.svg'}`} />
      case 'Bergen lufthavn':
        return <img className={styles.icon} src="icons/Sted=Bergen lufthavn.svg" />
      case 'Kaigaten':
        return <img className={styles.icon} src="icons/Sted=Kaigaten.svg" />
      case 'Fyllingsdalen terminal':
        return <img className={styles.icon} src="icons/Fyllingsdalen.svg" />
      case 'crunch':
        return window.innerWidth <= variablesForScrollling.screenSize ? (
          <img className={styles.crunchLineMobile} src="/icons/CrunchMobile.png" />
        ) : (
          <img className={styles.crunchLine} src="/icons/Crunch.png" />
        )
      case 'questionmark':
        return <img className={styles.iconQuestionmark} src="icons/questionmark.svg" />
      default:
        return <div className={`${styles.circle} ${styles[theme]}`} />
    }
  }

  return (
    <div id={rightText}>
      <div className={styles.dotDiv}>
        <div className={styles.beforeDot} />
        <div className={styles.aroundDot}>
          <div
            className={
              isFirst
                ? icon === 'Byparken' || icon === 'Bergen lufthavn'
                  ? styles.circleDotHide
                  : styles.circleDot
                : styles.notInUse
            }
          />
        </div>
        <div className={styles.afterDot} />
      </div>
      {isItemContent ? (
        <div className={`${styles.departureContent} ${styles[theme]}`} data-testid="DepartureContent">
          <div className={styles.time}>{leftText}</div>
          <div className={styles.lineWrapper}>
            <div
              className={
                isLast
                  ? styles.circleLineLast
                  : isFirst
                  ? icon === 'Byparken' || icon === 'Bergen lufthavn'
                    ? styles.circleLineFirstAndEndstop
                    : styles.circleLineFirst
                  : styles.circleLine
              }
            />
            {circle()}
          </div>
          <div className={styles.stopName}>
            <span>{rightText}</span>
          </div>
        </div>
      ) : (
        <div
          className={`${styles.departureContent} ${styles[theme]}`}
          data-testid="DepartureContent"
          onClick={() => flushSync(() => handleClick?.())}
          onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && flushSync(() => handleClick?.())}
        >
          <div className={styles.numCollapsedStops}>
            <span>{leftText}</span>
          </div>
          <div className={styles.lineWrapper}>{circle()}</div>
          <div className={`${styles.collapsedStopsText} ${styles[theme]}`}>
            <span role="button" tabIndex={0}>
              {rightText}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
