import { useCallback, useEffect } from 'react'

// Key events
export const KEY_NAME_ESC = 'Escape'
export const KEYUP_EVENT = 'keyup'

export function useEscapeKey(handleClose: Function) {
  const handleEscKey = useCallback(
    (event: KeyboardEvent) => {
      event.key === KEY_NAME_ESC && handleClose()
    },
    [handleClose]
  )

  useEffect(() => {
    document.addEventListener(KEYUP_EVENT, handleEscKey, false)
    return () => {
      document.removeEventListener(KEYUP_EVENT, handleEscKey, false)
    }
  }, [handleEscKey])
}
