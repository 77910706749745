import { useQuery } from '@tanstack/react-query'
import { useContext, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useEscapeKey } from '../../../../CustomHooks/useEscapeKey'
import { useOutsideClick } from '../../../../CustomHooks/useOutsideClick'
import { getElementFromPage, getPage } from '../../../../services/SanityService'
import { OccupancyEnum } from '../../../../typeDefinitions'
import { IPage, PageName } from '../../../../typeDefinitions'
import { LanguageContext } from '../../../Providers/LanguageProvider'
import Occupancy from '../Occupancy/Occupancy'
import styles from './SymbolExplanationModal.module.scss'

interface ISymbolExplanationModalProps {
  setIsOpen: (isOpen: boolean) => void
}

export default function SymbolExplanationModal({ setIsOpen }: ISymbolExplanationModalProps) {
  const ref = useRef<HTMLDivElement>(null)
  const lang = useContext(LanguageContext)

  const { data: sanityData } = useQuery<IPage>([PageName.RealtimePage, lang.language], () =>
    getPage(PageName.RealtimePage, lang.language)
  )
  useOutsideClick(handleClose, ref)
  useEscapeKey(handleClose)

  function handleClose() {
    return (document.body.style.overflowY = ''), setIsOpen(false)
  }

  return ReactDOM.createPortal(
    <dialog open className={styles.symbolExplanationModalBackground}>
      <div className={styles.symbolExplanationModal} data-testid="SymbolExplanationModal" ref={ref}>
        <h2 className={styles.symbolExplanationModalHeader}>
          {getElementFromPage('symbolExplanationHeader', sanityData, lang.language)}
        </h2>
        <p className={styles.symbolExplanationModalText}>
          {getElementFromPage('symbolExplanationText', sanityData, lang.language)}
        </p>
        <div className={styles.symbolExplanationOccupancyContainer}>
          <Occupancy occupancy={OccupancyEnum.ManySeatsAvailable} />
          <span title="largeAvailability">{getElementFromPage('availableSeatsText', sanityData, lang.language)}</span>
        </div>
        <div className={styles.symbolExplanationOccupancyContainer}>
          <Occupancy occupancy={OccupancyEnum.StandingAvailable} />
          <span title="mediumAvailability">
            {getElementFromPage('availableStandingSpace', sanityData, lang.language)}
          </span>
        </div>
        <div className={styles.symbolExplanationOccupancyContainer}>
          <Occupancy occupancy={OccupancyEnum.Full} />
          <span title="lowAvailability">{getElementFromPage('almostFullCapacity', sanityData, lang.language)}</span>
        </div>
        <button onClick={() => handleClose()} className={styles.symbolExplanationModalClose}>
          {getElementFromPage('closeBtn', sanityData, lang.language)}
        </button>
      </div>
    </dialog>,
    document.body
  )
}
