import { INextDeparturesPayload } from '../../../services/ItineraryService'
import { IStop } from '../../../typeDefinitions'
import DepartureContent from './DepartureContent/DepartureContent'

export function undefinedDirectionDepartures(selectedStopName: string) {
  return (
    <>
      <DepartureContent
        key={0}
        icon={selectedStopName}
        rightText={selectedStopName}
        leftText={'--:--'}
        currentStopName={selectedStopName}
        isItemContent={true}
        isFirst
      />
      <DepartureContent
        key={1}
        icon={'crunch'}
        rightText={''}
        leftText={''}
        currentStopName={selectedStopName}
        isItemContent={false}
      />
      <DepartureContent
        key={2}
        icon={'questionmark'}
        rightText={''}
        leftText={'--:--'}
        currentStopName={selectedStopName}
        isItemContent={true}
        isLast
      />
    </>
  )
}

export function collapsedDepartures(
  selectedStop: IStop,
  nextDepartures: INextDeparturesPayload[] | undefined,
  setIsOpenCollapsedStops: Function,
  expandStopsText: string
) {
  return (
    nextDepartures && (
      <>
        <DepartureContent
          key={0}
          icon={nextDepartures[0].name}
          rightText={nextDepartures[0].name}
          leftText={nextDepartures[0].value}
          currentStopName={selectedStop.name}
          isItemContent={true}
          isFirst
        />
        <DepartureContent
          key={1}
          icon={nextDepartures[1].name}
          rightText={nextDepartures[1].name}
          leftText={nextDepartures[1].value}
          currentStopName={selectedStop.name}
          isItemContent={true}
        />
        <DepartureContent
          key={2}
          icon={'crunch'}
          rightText={expandStopsText}
          leftText={''}
          currentStopName={selectedStop.name}
          isItemContent={false}
          handleClick={() => setIsOpenCollapsedStops(true)}
        />
        <DepartureContent
          key={3}
          icon={nextDepartures[nextDepartures.length - 1].name}
          rightText={nextDepartures[nextDepartures.length - 1].name}
          leftText={nextDepartures[nextDepartures.length - 1].value}
          currentStopName={selectedStop.name}
          isItemContent={true}
          isLast
        />
      </>
    )
  )
}
