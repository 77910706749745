import axios from 'axios'

import { IDeviationMessage, ILine, IStop, ITram } from '../../typeDefinitions'

export interface IApiManager {
  getLines(): Promise<ILine[]>
  getTrams(): Promise<ITram[]>
  getDeviationMessages(): Promise<IDeviationMessage[]>
  getStop(line: ILine, stopName: string | null): Promise<IStop | undefined>
  postFormdata(formdata: FormData): Promise<number>
}
export default class ApiManager implements IApiManager {
  private BASEURL = process.env.REACT_APP_API_URL
  private LINES_SUBSCRIPTION_ID = process.env.REACT_APP_LINES_SUBSCRIPTION_ID

  async getDeviationMessages(): Promise<IDeviationMessage[]> {
    const url = `${this.BASEURL}/serviceDeviationMessages`
    const { data } = await axios.get<IDeviationMessage[]>(url)
    return data
  }

  async getTrams(): Promise<ITram[]> {
    const url = `${this.BASEURL}/trams`
    const { data } = await axios.get<ITram[]>(url)
    return data
  }

  async getLines(): Promise<ILine[]> {
    const url = `${this.BASEURL}/lines/${this.LINES_SUBSCRIPTION_ID}`
    const { data } = await axios.get<ILine[]>(url)
    return data
  }

  public getStop(line: ILine, stopName: string | null): Promise<IStop | undefined> {
    if (stopName === null) return Promise.resolve(undefined)
    const stop = line.stops.find((stop) => stop.name === stopName)
    return Promise.resolve(stop)
  }

  public async postFormdata(formdata: FormData): Promise<number> {
    try {
      const url = `${this.BASEURL}/mail`
      const response = await axios.post(url, formdata)
      return response.status
    } catch (error: any | unknown) {
      return error.response.status
    }
  }
}
