import { ReactNode, createContext, useEffect, useState } from 'react'

export const LanguageContext = createContext<ILanguage>({ language: 'no', setLanguage: (language: LanguageType) => {} })

interface ILanguageProviderProps {
  children: ReactNode
}

export type LanguageType = 'no' | 'en'

interface ILanguage {
  language: LanguageType
  setLanguage: (language: LanguageType) => void
}

export default function LanguageProvider({ children }: ILanguageProviderProps) {
  const [language, setLanguage] = useState<LanguageType>('no')

  useEffect(() => {
    document.documentElement.lang = language
  }, [language])

  return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>
}
