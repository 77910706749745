import { useContext } from 'react'

import sharedStops from '../../lib/sharedStops'
import GenericClickableCard from '../GenericClickableCard/GenericClickableCard'
import { LanguageContext } from '../Providers/LanguageProvider'
import styles from './FavouriteStops.module.scss'

interface IFavouriteStopsProps {
  stopList: string[]
  handleClick: (lineId: number, stopName: string) => void
}

export default function FavouriteStops({ stopList, handleClick }: IFavouriteStopsProps) {
  const lang = useContext(LanguageContext)

  const stopListElements = stopList.map((stopName, idx) => {
    let lineId = 1
    if (stopName.substring(stopName.length - 2, stopName.length) == '_2') {
      stopName = stopName.substring(0, stopName.length - 2)
      lineId = 2
    }
    return (
      <GenericClickableCard
        leftIcon="/icons/FavModalStar.svg"
        rightIcon="/icons/right-arrow.svg"
        text={stopName}
        handleClick={() => handleClick(lineId, stopName)}
        key={idx}
        value={stopName}
        {...(sharedStops.includes(stopName) ? { lineId: lineId } : {})}
      />
    )
  })

  return (
    <div aria-label={lang.language === 'en' ? 'Favorite stop' : 'Favoritt stopp'} data-testid="FavouriteStops">
      <div className={styles.favouriteStopsContainer}>{stopListElements}</div>
    </div>
  )
}
