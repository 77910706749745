import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'

import { getArt } from '../../services/SanityService'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IArt } from '../../typeDefinitions'
import { IPage, PageName } from '../../typeDefinitions'
import GenericClickableCard from '../GenericClickableCard/GenericClickableCard'
import { LanguageContext } from '../Providers/LanguageProvider'
import { ThemeContext } from '../Providers/ThemeProvider'
import styles from './News.module.scss'

export default function News() {
  const [artData, setArtData] = useState<IArt>()
  const lang = useContext(LanguageContext)
  const { theme } = useContext(ThemeContext)

  const { data: sanityData } = useQuery<IPage>([PageName.RealtimePage, lang.language], () =>
    getPage(PageName.RealtimePage, lang.language)
  )

  useEffect(() => {
    getArt().then((data) => {
      setArtData(data[Math.floor(Math.random() * data.length)])
    })
  }, [])

  return (
    <>
      {artData && (
        <div className={`${styles.container} ${styles[theme]}`}>
          <div className={`${styles.gradient} ${styles[theme]}`}>
            <h1 className={`${styles.headerBox} ${styles[theme]}`}>
              {getElementFromPage('h1ArtHeader', sanityData, lang.language)}
            </h1>
          </div>
          <div
            className={`${styles.newsBoxImage} ${styles[theme]}`}
            aria-label={lang.language === 'en' ? 'Art along bybanen' : 'Kunst langs bybanen'}
          >
            <img
              className={styles.image}
              src={artData.imageUrl}
              alt={lang.language === 'no' ? 'bilde av kunst' : 'art picture'}
            />
            <div className={styles.textWrapper}>
              <h2 className={`${styles.newsSubtitle} ${styles[theme]}`}>{artData?.title}</h2>
              <div
                className={styles.newsText}
                aria-label={lang.language === 'en' ? 'Art description' : 'Beskrivelse av kunstverk'}
              >
                <p>{artData?.text[lang.language]}</p>
              </div>
              <div className={`${styles.linkToArtButton} ${styles[theme]}`}>
                <GenericClickableCard
                  text={artData?.buttonText[lang.language]}
                  rightIcon="/icons/art-right-arrow.svg"
                  handleClick={() => artData?.link && window.location.assign(artData.link)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
