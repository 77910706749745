import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { flushSync } from 'react-dom'

import { useLineQuery } from '../../CustomHooks/useLineQuery'
import { INextDeparturesPayload } from '../../services/ItineraryService'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { DirectionEnum, ILine, IStop } from '../../typeDefinitions'
import { IPage, PageName } from '../../typeDefinitions'
import { variablesForScrollling } from '../../utils/glbVariables'
import { LanguageContext } from '../Providers/LanguageProvider'
import { ServiceCollectionContext } from '../Providers/ServicesProvider'
import { ThemeContext } from '../Providers/ThemeProvider'
import Spinner from '../Spinner/Spinner'
import styles from './DepartureDetail.module.scss'
import { collapsedDepartures, undefinedDirectionDepartures } from './components/Constants'
import DepartureContent from './components/DepartureContent/DepartureContent'
import SymbolExplanationModal from './components/SymbolExplanationModal/SymbolExplanationModal'
import Tab from './components/Tab/Tab'

interface IDepartureDetailProps {
  selectedStop: IStop
  direction?: DirectionEnum
  lineId: number
}

export default function DepartureDetail({ selectedStop, direction, lineId }: IDepartureDetailProps) {
  const services = useContext(ServiceCollectionContext)
  const [activeTab, setActiveTab] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEndstopWithoutDepartures, setIsEndstopWithoutDepartures] = useState(findEndstopWithoutDepartures())
  const { line, isLoading, error } = useLineQuery(lineId)

  const { data: nextDepartures } = line ? getNextDepartures(line) : { data: undefined }
  const [isOpenCollapsedStops, setIsOpenCollapsedStops] = useState(nextDepartures ? nextDepartures.length < 5 : false)

  const lang = useContext(LanguageContext)
  const { theme } = useContext(ThemeContext)

  const { data: sanityData } = useQuery<IPage>([PageName.RealtimePage, lang.language], () =>
    getPage(PageName.RealtimePage, lang.language)
  )

  useEffect(() => {
    setIsEndstopWithoutDepartures(findEndstopWithoutDepartures())
  }, [direction, selectedStop])

  function getNextDepartures(line: ILine) {
    return useQuery<INextDeparturesPayload[]>(
      ['nextDeparturesPayload', lineId, activeTab, direction, selectedStop.id],
      () => {
        const departurePayloads = direction
          ? services.itineraryService.getArrivals(
              direction,
              services.itineraryService.getStopsByDirection(selectedStop, direction, line),
              //First needs to be departuretime, as it is the selected stations departure time
              findEstimatedCallsToUse(line)?.[activeTab - 1].expected_departure_time
            )
          : [
              {
                id: selectedStop.id,
                backwards_id: selectedStop.backward_id,
                name: selectedStop.name,
                value: '',
              },
            ]

        return departurePayloads
      },
      {
        refetchInterval: 10000,
        enabled: !!activeTab && !!direction && !!findEstimatedCallsToUse(line) && !!selectedStop,
        keepPreviousData: true,
      }
    )
  }

  function findEstimatedCallsToUse(line: ILine) {
    // TODO: Delete this 'if' when Byparken times are correct AB#483
    if (selectedStop.name === 'Byparken') {
      return services.itineraryService.byparkenEstimatedCallsHack(line.stops[1], true)
    }

    if (direction === DirectionEnum.Backward) {
      return selectedStop.estimated_calls_backward
    } else if (direction === DirectionEnum.Forward) {
      return selectedStop.estimated_calls_forward
    }
    return []
  }

  // When the direction is set to continue past the endstop
  function findEndstopWithoutDepartures() {
    return (
      (direction === DirectionEnum.Backward &&
        selectedStop.estimated_calls_backward &&
        selectedStop.estimated_calls_backward.length === 0) ||
      (direction === DirectionEnum.Forward &&
        selectedStop.estimated_calls_forward &&
        selectedStop.estimated_calls_forward.length === 0)
    )
  }

  return (
    <div className={`${styles.departureDetailWrapper} ${styles[theme]}`} data-testid="DepartureDetail">
      {error ? (
        <span>Error: {error?.message}</span>
      ) : (
        line && (
          <div className={`${styles.tabsWrapper} ${styles[theme]}`}>
            {[0, 1, 2].map((i) => (
              <Tab
                key={i}
                active={activeTab === i + 1}
                onTabClick={() => setActiveTab(i + 1)}
                time={direction && findEstimatedCallsToUse(line)?.[i]?.expected_departure_time}
                occupancy={direction && findEstimatedCallsToUse(line)?.[i]?.occupancy}
              />
            ))}
          </div>
        )
      )}
      {direction ? (
        <button
          className={`${styles.symbolExplanationButton} ${styles[theme]}`}
          onClick={() => {
            setIsOpenModal(true)
            if (typeof window != 'undefined' && window.document) {
              document.body.style.overflowY = 'auto'
            }
          }}
        >
          <span className={`${styles.infoCircle} ${styles[theme]}`}>i</span>
          <span title="explanationText" className={`${styles.explanationText} ${styles[theme]}`}>
            {getElementFromPage('symbolExplanationButtonText', sanityData, lang.language)}
          </span>
        </button>
      ) : (
        <div className={`${styles.noDirectionSpacer} ${styles[theme]}`} />
      )}
      <div className={`${styles.contentWrapper} ${styles[theme]}`}>
        {isLoading ? (
          <Spinner />
        ) : error ? (
          <span>Error: {error?.message}</span>
        ) : (
          <div className={`${styles.departureContentWrapper} ${styles[theme]}`}>
            {direction && !isEndstopWithoutDepartures
              ? nextDepartures && (nextDepartures.length < 5 || isOpenCollapsedStops)
                ? nextDepartures.map((departure, i) =>
                    departure.value === '--:--' ? (
                      undefinedDirectionDepartures(selectedStop.name)
                    ) : (
                      <DepartureContent
                        key={i}
                        icon={departure.name}
                        rightText={departure.name}
                        leftText={departure.value}
                        currentStopName={selectedStop.name}
                        isItemContent={true}
                        isLast={i === nextDepartures.length - 1}
                        isFirst={i === 0}
                      />
                    )
                  )
                : sanityData &&
                  collapsedDepartures(
                    selectedStop,
                    nextDepartures,
                    setIsOpenCollapsedStops,
                    getElementFromPage('expandStopsText', sanityData, lang.language)!
                  )
              : undefinedDirectionDepartures(selectedStop.name)}

            <button
              className={
                nextDepartures && nextDepartures.length >= 4 && isOpenCollapsedStops
                  ? `${styles.minimizeStopsShow} ${styles[theme]}`
                  : styles.minimizeStopsHide
              }
              onClick={() => {
                flushSync(() => setIsOpenCollapsedStops(false)),
                  window.innerWidth < variablesForScrollling.screenSize && scrollTo(0, 450)
              }}
            >
              <h1>{getElementFromPage('collapseStopsText', sanityData, lang.language)}</h1>
              <img src="icons/CollapseIcon.svg" />
            </button>
          </div>
        )}
      </div>
      {isOpenCollapsedStops && <div className={styles.blueDivider} />}
      {isOpenModal && <SymbolExplanationModal setIsOpen={setIsOpenModal} />}
    </div>
  )
}
