import { MouseEventHandler, useContext } from 'react'

import { OccupancyEnum } from '../../../../typeDefinitions'
import { formatTime } from '../../../../utils/TimeUtilites'
import { ThemeContext } from '../../../Providers/ThemeProvider'
import Spinner from '../../../Spinner/Spinner'
import Occupancy from '../Occupancy/Occupancy'
import styles from './Tab.module.scss'

interface ITabProps {
  active: boolean
  onTabClick: MouseEventHandler
  time?: string
  occupancy?: OccupancyEnum
  isLoading?: boolean
}

export default function Tab({ active, onTabClick: onClick, time, occupancy, isLoading }: ITabProps) {
  const { theme } = useContext(ThemeContext)

  return (
    <button
      onClick={onClick}
      className={active ? `${styles.tabActive} ${styles[theme]}` : `${styles.tabInactive} ${styles[theme]}`}
      data-testid="Tab"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {time ? <span>{formatTime(time)}</span> : <span>--:--</span>}
          <Occupancy occupancy={occupancy} />
        </>
      )}
    </button>
  )
}
