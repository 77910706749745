import { useRef } from 'react'
import ReactDOM from 'react-dom'

import { useEscapeKey } from '../../CustomHooks/useEscapeKey'
import { useOutsideClick } from '../../CustomHooks/useOutsideClick'
import styles from './TurnOnGPSGuide.module.scss'

interface TurnOnGPSGuide {
  setIsOpen: (isOpen: boolean) => void
}

export default function TurnOnGpsGuide({ setIsOpen }: TurnOnGPSGuide) {
  const ref = useRef<HTMLDivElement>(null)

  function isIos(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
  }

  useEscapeKey(handleClose)
  useOutsideClick(handleClose, ref)

  function handleClose(): void {
    setIsOpen(false)
  }

  return ReactDOM.createPortal(
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.container} ref={ref}>
          <div className={styles.textContainer}>
            <h4>Stedstjenester</h4>
            <p>Bybanen Sanntid bruker stedstjenester for å gi deg sanntidsoppdateringer om din nærmesete holdplass.</p>
            <h5>Hvordan skrur jeg det på?</h5>
            {isIos() ? (
              <ol>
                <li>Innstillinger</li>
                <li>Personvern og sikkerhet</li>
                <li>Stedstjenester</li>
                <li>Din nettleser</li>
                <li>Tillat stedstjenester</li>
              </ol>
            ) : (
              <ol>
                <li>Innstillinger</li>
                <li>Posisjon</li>
                <li>Din nettleser</li>
                <li>Tillat stedstjenester</li>
              </ol>
            )}
          </div>
          <button
            className={styles.closeButton}
            onClick={() => {
              handleClose()
            }}
          >
            Lukk
          </button>
        </div>
      </div>
    </div>,
    document.body
  )
}
