import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useRef, useState } from 'react'

import { getElementFromPage, getPage } from '../../services/SanityService'
import { DirectionEnum, IStop } from '../../typeDefinitions'
import { IPage, PageName } from '../../typeDefinitions'
import { LanguageContext } from '../Providers/LanguageProvider'
import { ThemeContext } from '../Providers/ThemeProvider'
import { useSkipLink } from '../SkipToContent/SkipContext'
import DirectionCard from './Components/DirectionCard'
import DirectionTab from './Components/DirectionTab'
import styles from './Direction.module.scss'

interface IDirectionProps {
  backwardEndStop: IStop
  forwardEndStop: IStop
  setDirection: (direction: DirectionEnum | undefined) => void
  currentStop: string
  startState?: string
  lineNum: number
  onDirectionTabChange: (i: number) => void
}

export default function Direction({
  backwardEndStop,
  forwardEndStop,
  setDirection,
  currentStop,
  startState,
  lineNum,
  onDirectionTabChange,
}: IDirectionProps) {
  // chosenDirection chooses styling for the Direction cards based on what direction is chosen.
  // They may have three states: active, inactive (default), otherActive (the other card is active)
  const [chosenDirection, setChosenDirection] = useState('')
  const [lastDirection, setLastDirection] = useState('') // Only used for visuals so that text can fade out without abruptly changing
  const lang = useContext(LanguageContext)
  const { theme } = useContext(ThemeContext)
  const { setTargetRef } = useSkipLink()
  const contentRef = useRef<HTMLParagraphElement | null>(null)
  const [activeTab, setActiveTab] = useState(lineNum)

  const { data: sanityData } = useQuery<IPage>([PageName.RealtimePage, lang.language], () =>
    getPage(PageName.RealtimePage, lang.language)
  )

  useEffect(() => {
    setTargetRef(contentRef)
  }, [])

  function handleDirectionChange(cardDirection: string): void {
    setLastDirection((lastDirection) => (lastDirection === cardDirection ? lastDirection : cardDirection))
    setChosenDirection((prevchosenDirection) => (prevchosenDirection === cardDirection ? '' : cardDirection))
    const direction = getDirectionFromCard(cardDirection)
    if (direction === startState) {
      setDirection(undefined)
    } else {
      setDirection(direction)
    }
  }

  function getDirectionFromCard(cardDirection: string) {
    if (cardDirection === backwardEndStop.name) {
      return DirectionEnum.Backward
    } else if (cardDirection === forwardEndStop.name) {
      return DirectionEnum.Forward
    }
    return undefined
  }

  function changeDirection(cardDirection: string): void {
    if (currentStop === backwardEndStop.name) {
      handleDirectionChange(forwardEndStop.name)
    } else if (currentStop === forwardEndStop.name) {
      handleDirectionChange(backwardEndStop.name)
    } else {
      handleDirectionChange(cardDirection)
    }
  }

  function isDoubleDirection() {
    return ['Kronstad', 'Bergen busstasjon', 'Nonneseter'].includes(currentStop)
  }

  function onClickTab(i: number) {
    if (i != activeTab) {
      setActiveTab(i)
      onDirectionTabChange(i)
    }
  }

  useEffect(() => {
    if (startState === DirectionEnum.Forward) {
      setLastDirection(forwardEndStop.name)
      setChosenDirection(forwardEndStop.name)
    } else if (startState === DirectionEnum.Backward) {
      setLastDirection(backwardEndStop.name)
      setChosenDirection(backwardEndStop.name)
    }
  }, [activeTab])

  useEffect(() => {
    if (currentStop === backwardEndStop.name) {
      setLastDirection(forwardEndStop.name)
      setChosenDirection(forwardEndStop.name)
      setDirection(DirectionEnum.Forward)
    } else if (currentStop === forwardEndStop.name) {
      setLastDirection(backwardEndStop.name)
      setChosenDirection(backwardEndStop.name)
      setDirection(DirectionEnum.Backward)
    }
  }, [currentStop])

  return (
    <div className={styles.direction} data-testid="Direction">
      <p ref={contentRef} tabIndex={-1} className={`${styles.directionTextWhichWay} ${styles[theme]}`}>
        {getElementFromPage('pWhichDirection', sanityData, lang.language)}
      </p>
      {isDoubleDirection() && (
        <div className={styles.tabsWrapper}>
          {[0, 1].map((i) => (
            <DirectionTab key={i} active={activeTab === i + 1} lineId={i + 1} onTabClick={() => onClickTab(i + 1)} />
          ))}
        </div>
      )}
      <div className={styles.directionCardContainer}>
        <DirectionCard
          left
          chosenDirection={chosenDirection}
          endStopName={backwardEndStop.name}
          changeDirection={
            currentStop === forwardEndStop.name || currentStop === backwardEndStop.name ? () => {} : changeDirection
          }
          currentStop={currentStop}
        />
        <DirectionCard
          chosenDirection={chosenDirection}
          endStopName={forwardEndStop.name}
          changeDirection={
            currentStop === forwardEndStop.name || currentStop === backwardEndStop.name ? () => {} : changeDirection
          }
          currentStop={currentStop}
        />
      </div>

      {sanityData && (
        <p
          className={
            chosenDirection
              ? `${styles.directionTextDirectionChosen} ${styles[theme]}`
              : `${styles.directionTextDirectionChosenHidden} ${styles[theme]}`
          }
        >
          {getElementFromPage('directionTowards', sanityData, lang.language) + ' ' + lastDirection}
        </p>
      )}
      <p
        className={
          chosenDirection
            ? `${styles.directionTextDirectionNotChosenHidden} ${styles[theme]}`
            : `${styles.directionTextDirectionNotChosen} ${styles[theme]}`
        }
      >
        {getElementFromPage('chooseDirection', sanityData, lang.language)}
      </p>
    </div>
  )
}
