import { useRef, useState } from 'react'

import { useEscapeKey } from '../../CustomHooks/useEscapeKey'
import { useOutsideClick } from '../../CustomHooks/useOutsideClick'
import styles from './installInformation.module.scss'

export default function InstallationInformation() {
  const [isOpen, setIsOpen] = useState(() => notIosOrInstalled())
  const [moreInfo, setMoreInfo] = useState(false)
  const myRef = useRef<HTMLDialogElement>(null)

  function handleClose(): void {
    localStorage.setItem('installModalOpen', 'false')
    setMoreInfo(false)
    setIsOpen(false)
  }

  let safariAgent = navigator.userAgent.indexOf('Safari') > -1
  let chriOsAgent = navigator.userAgent.indexOf('CriOS') > -1
  let fxiOsAgent = navigator.userAgent.indexOf('FxiOS') > -1
  let edgiOsAgent = navigator.userAgent.indexOf('EdgiOS') > -1

  function isSafariBrowser(): boolean {
    //required logic for Safari browsers, since userAgent on apple products gives false feedback
    if (safariAgent && !chriOsAgent && !fxiOsAgent && !edgiOsAgent) {
      return true
    }
    return false
  }

  function notIosOrInstalled(): boolean {
    if (window.matchMedia('(display-mode: standalone)').matches) return false
    if (localStorage.getItem('installed') === 'true') return false
    if (localStorage.getItem('installModalOpen') === 'false') return false
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
  }

  useEscapeKey(handleClose)
  useOutsideClick(handleClose, myRef)

  return notIosOrInstalled() && isSafariBrowser() ? (
    <div className={styles.background}>
      {isOpen && (
        <dialog className={styles.installationContainer} ref={myRef}>
          <button
            className={styles.closeButton}
            onClick={() => {
              handleClose()
            }}
          >
            Ikke akkurat nå
          </button>
          <div className={styles.textContainer} onClick={() => setMoreInfo(!moreInfo)}>
            <h2>Bybanen Sanntid</h2>
            <p>
              Lagre denne appen på hjemskjermen for raskere tilgang og mulighet for å motta varsler om driftsavvik mm.
              <button className={styles.moreInfoButton}>
                <img src="/icons/info.svg" />
              </button>
            </p>
            {moreInfo && (
              <div className={styles.textContainer}>
                <p>Trykk på</p>
                <img src="/icons/iosShare.svg" className={styles.shareIcon} />
                <br />
                <p>deretter</p>
                <br />
                <div className={styles.iconText}>
                  legg til på hjem-Skjermen
                  <img src="/icons/iosAdd.svg" className={styles.iosAddIcon} />
                </div>
              </div>
            )}
          </div>
          <img src="/icons/arrowDown.svg" className={styles.arrowDownIcon} />
        </dialog>
      )}
    </div>
  ) : (
    <></>
  )
}
