import { useQuery } from '@tanstack/react-query'
import { RefObject, useContext, useEffect, useRef } from 'react'

import { LanguageContext } from '../../../components/Providers/LanguageProvider'
import { useSkipLink } from '../../../components/SkipToContent/SkipContext'
import { getElementFromPage, getPage } from '../../../services/SanityService'
import { IAllStops, IPage, PageName } from '../../../typeDefinitions'
import { DesktopView } from './DesktopView'
import tabletStyles from './LineViewDesktop.module.scss'
import styles from './LineViewMobile.module.scss'
import { MobileView } from './MobileView'

interface ILineViewProps {
  loading: boolean
  allStops: IAllStops[]
  handleClick: (cardNumber: number) => void
  directions: number[]
  lineId: number
  myRef: RefObject<HTMLDivElement>
  handleScroll?: (e: any) => void
  mobile?: boolean
}

export default function LineView({
  myRef,
  handleScroll,
  loading,
  allStops,
  handleClick,
  directions,
  lineId,
  mobile,
}: ILineViewProps) {
  const lang = useContext(LanguageContext)
  const { setTargetRef } = useSkipLink()
  const contentRef = useRef<HTMLHeadingElement | null>(null)

  useEffect(() => {
    setTargetRef(contentRef)
  }, [])

  const { data: sanityData } = useQuery<IPage>([PageName.LinePage, lang.language], () =>
    getPage(PageName.LinePage, lang.language)
  )
  return (
    <div className={mobile ? styles.mobile : tabletStyles.tablet}>
      <div className={mobile ? styles.headerTitle : tabletStyles.tabletHeaderTitle}>
        <h1>{getElementFromPage('h2LineOverview', sanityData, lang.language)}</h1>
      </div>
      {mobile && handleScroll ? (
        <MobileView
          handleScroll={handleScroll}
          loading={loading}
          allStops={allStops}
          myRef={myRef}
          handleClick={handleClick}
          directions={directions}
          lineId={lineId}
        />
      ) : (
        <DesktopView
          loading={loading}
          allStops={allStops}
          myRef={myRef}
          handleClick={handleClick}
          directions={directions}
        />
      )}
    </div>
  )
}
