import { ILine, IStop, OccupancyEnum } from '../typeDefinitions'

const API_MOCK_DATA_STOP: IStop = {
  id: 53130,
  backward_id: 53131,
  line_id: 1,
  order: 1,
  latitude: 60.39198,
  longitude: 5.325511,
  name: 'Byparken',
  response_timestamp: '2023-02-07T08:01:55.119+00:00',
  estimated_calls_forward: [
    {
      expected_departure_time: '2023-02-07T08:15:10+00:00',
      aimed_departure_time: '2023-02-07T08:10:00+00:00',
      expected_arrival_time: '2023-02-07T08:10:00+00:00',
      aimed_arrival_time: '2023-02-07T08:04:50+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:20:10+00:00',
      aimed_departure_time: '2023-02-07T08:15:00+00:00',
      expected_arrival_time: '2023-02-07T08:15:00+00:00',
      aimed_arrival_time: '2023-02-07T08:09:50+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:25:10+00:00',
      aimed_departure_time: '2023-02-07T08:20:00+00:00',
      expected_arrival_time: '2023-02-07T08:20:00+00:00',
      aimed_arrival_time: '2023-02-07T08:14:50+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:30:10+00:00',
      aimed_departure_time: '2023-02-07T08:25:00+00:00',
      expected_arrival_time: '2023-02-07T08:25:00+00:00',
      aimed_arrival_time: '2023-02-07T08:19:50+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
  ],
  estimated_calls_backward: [],
}

const API_MOCK_DATA_STOP_2: IStop = {
  id: 53136,
  backward_id: 53135,
  line_id: 1,
  order: 2,
  latitude: 60.39007,
  longitude: 5.33279,
  name: 'Nonneseter',
  response_timestamp: '2023-02-07T08:01:55.119+00:00',
  estimated_calls_forward: [
    {
      expected_departure_time: '2023-02-07T08:06:33+00:00',
      aimed_departure_time: '2023-02-07T08:06:33+00:00',
      expected_arrival_time: '2023-02-07T08:06:06+00:00',
      aimed_arrival_time: '2023-02-07T08:06:06+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:11:33+00:00',
      aimed_departure_time: '2023-02-07T08:11:33+00:00',
      expected_arrival_time: '2023-02-07T08:11:06+00:00',
      aimed_arrival_time: '2023-02-07T08:11:06+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:16:33+00:00',
      aimed_departure_time: '2023-02-07T08:16:33+00:00',
      expected_arrival_time: '2023-02-07T08:16:06+00:00',
      aimed_arrival_time: '2023-02-07T08:16:06+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:21:33+00:00',
      aimed_departure_time: '2023-02-07T08:21:33+00:00',
      expected_arrival_time: '2023-02-07T08:21:06+00:00',
      aimed_arrival_time: '2023-02-07T08:21:06+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:26:33+00:00',
      aimed_departure_time: '2023-02-07T08:26:33+00:00',
      expected_arrival_time: '2023-02-07T08:26:06+00:00',
      aimed_arrival_time: '2023-02-07T08:26:06+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:31:33+00:00',
      aimed_departure_time: '2023-02-07T08:31:33+00:00',
      expected_arrival_time: '2023-02-07T08:31:06+00:00',
      aimed_arrival_time: '2023-02-07T08:31:06+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
  ],
  estimated_calls_backward: [
    {
      expected_departure_time: '2023-02-07T08:03:45+00:00',
      aimed_departure_time: '2023-02-07T08:03:45+00:00',
      expected_arrival_time: '2023-02-07T08:03:14+00:00',
      aimed_arrival_time: '2023-02-07T08:03:14+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:08:45+00:00',
      aimed_departure_time: '2023-02-07T08:08:45+00:00',
      expected_arrival_time: '2023-02-07T08:08:14+00:00',
      aimed_arrival_time: '2023-02-07T08:08:14+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:13:45+00:00',
      aimed_departure_time: '2023-02-07T08:13:45+00:00',
      expected_arrival_time: '2023-02-07T08:13:14+00:00',
      aimed_arrival_time: '2023-02-07T08:13:14+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:18:45+00:00',
      aimed_departure_time: '2023-02-07T08:18:45+00:00',
      expected_arrival_time: '2023-02-07T08:18:14+00:00',
      aimed_arrival_time: '2023-02-07T08:18:14+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:25:05+00:00',
      aimed_departure_time: '2023-02-07T08:24:42+00:00',
      expected_arrival_time: '2023-02-07T08:24:34+00:00',
      aimed_arrival_time: '2023-02-07T08:24:11+00:00',
      occupancy: OccupancyEnum.StandingAvailable,
    },
    {
      expected_departure_time: '2023-02-07T08:31:42+00:00',
      aimed_departure_time: '2023-02-07T08:30:45+00:00',
      expected_arrival_time: '2023-02-07T08:31:11+00:00',
      aimed_arrival_time: '2023-02-07T08:30:14+00:00',
      occupancy: OccupancyEnum.SeatsAvailable,
    },
  ],
}

const API_MOCK_DATA_LINE: ILine = {
  id: 1,
  name: 'Byparken - Bergen lufthavn',
  destination_stop: 8027,
  stops: [
    {
      id: 53130,
      backward_id: 53131,
      line_id: 1,
      order: 1,
      latitude: 60.39198,
      longitude: 5.325511,
      name: 'Byparken',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:15:10+00:00',
          aimed_departure_time: '2023-02-07T08:10:00+00:00',
          expected_arrival_time: '2023-02-07T08:10:00+00:00',
          aimed_arrival_time: '2023-02-07T08:04:50+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:10+00:00',
          aimed_departure_time: '2023-02-07T08:15:00+00:00',
          expected_arrival_time: '2023-02-07T08:15:00+00:00',
          aimed_arrival_time: '2023-02-07T08:09:50+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:10+00:00',
          aimed_departure_time: '2023-02-07T08:20:00+00:00',
          expected_arrival_time: '2023-02-07T08:20:00+00:00',
          aimed_arrival_time: '2023-02-07T08:14:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:10+00:00',
          aimed_departure_time: '2023-02-07T08:25:00+00:00',
          expected_arrival_time: '2023-02-07T08:25:00+00:00',
          aimed_arrival_time: '2023-02-07T08:19:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [],
    },
    {
      id: 53136,
      backward_id: 53135,
      line_id: 1,
      order: 2,
      latitude: 60.39007,
      longitude: 5.33279,
      name: 'Nonneseter',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:06:33+00:00',
          aimed_departure_time: '2023-02-07T08:06:33+00:00',
          expected_arrival_time: '2023-02-07T08:06:06+00:00',
          aimed_arrival_time: '2023-02-07T08:06:06+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:11:33+00:00',
          aimed_departure_time: '2023-02-07T08:11:33+00:00',
          expected_arrival_time: '2023-02-07T08:11:06+00:00',
          aimed_arrival_time: '2023-02-07T08:11:06+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:33+00:00',
          aimed_departure_time: '2023-02-07T08:16:33+00:00',
          expected_arrival_time: '2023-02-07T08:16:06+00:00',
          aimed_arrival_time: '2023-02-07T08:16:06+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:33+00:00',
          aimed_departure_time: '2023-02-07T08:21:33+00:00',
          expected_arrival_time: '2023-02-07T08:21:06+00:00',
          aimed_arrival_time: '2023-02-07T08:21:06+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:33+00:00',
          aimed_departure_time: '2023-02-07T08:26:33+00:00',
          expected_arrival_time: '2023-02-07T08:26:06+00:00',
          aimed_arrival_time: '2023-02-07T08:26:06+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:33+00:00',
          aimed_departure_time: '2023-02-07T08:31:33+00:00',
          expected_arrival_time: '2023-02-07T08:31:06+00:00',
          aimed_arrival_time: '2023-02-07T08:31:06+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:03:45+00:00',
          aimed_departure_time: '2023-02-07T08:03:45+00:00',
          expected_arrival_time: '2023-02-07T08:03:14+00:00',
          aimed_arrival_time: '2023-02-07T08:03:14+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:45+00:00',
          aimed_departure_time: '2023-02-07T08:08:45+00:00',
          expected_arrival_time: '2023-02-07T08:08:14+00:00',
          aimed_arrival_time: '2023-02-07T08:08:14+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:45+00:00',
          aimed_departure_time: '2023-02-07T08:13:45+00:00',
          expected_arrival_time: '2023-02-07T08:13:14+00:00',
          aimed_arrival_time: '2023-02-07T08:13:14+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:45+00:00',
          aimed_departure_time: '2023-02-07T08:18:45+00:00',
          expected_arrival_time: '2023-02-07T08:18:14+00:00',
          aimed_arrival_time: '2023-02-07T08:18:14+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:05+00:00',
          aimed_departure_time: '2023-02-07T08:24:42+00:00',
          expected_arrival_time: '2023-02-07T08:24:34+00:00',
          aimed_arrival_time: '2023-02-07T08:24:11+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:42+00:00',
          aimed_departure_time: '2023-02-07T08:30:45+00:00',
          expected_arrival_time: '2023-02-07T08:31:11+00:00',
          aimed_arrival_time: '2023-02-07T08:30:14+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 53141,
      backward_id: 53140,
      line_id: 1,
      order: 3,
      latitude: 60.38814,
      longitude: 5.33417,
      name: 'Bergen busstasjon',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:03:44+00:00',
          aimed_departure_time: '2023-02-07T08:03:07+00:00',
          expected_arrival_time: '2023-02-07T08:03:17+00:00',
          aimed_arrival_time: '2023-02-07T08:02:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:07+00:00',
          aimed_departure_time: '2023-02-07T08:08:07+00:00',
          expected_arrival_time: '2023-02-07T08:07:40+00:00',
          aimed_arrival_time: '2023-02-07T08:07:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:07+00:00',
          aimed_departure_time: '2023-02-07T08:13:07+00:00',
          expected_arrival_time: '2023-02-07T08:12:40+00:00',
          aimed_arrival_time: '2023-02-07T08:12:40+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:07+00:00',
          aimed_departure_time: '2023-02-07T08:18:07+00:00',
          expected_arrival_time: '2023-02-07T08:17:40+00:00',
          aimed_arrival_time: '2023-02-07T08:17:40+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:07+00:00',
          aimed_departure_time: '2023-02-07T08:23:07+00:00',
          expected_arrival_time: '2023-02-07T08:22:40+00:00',
          aimed_arrival_time: '2023-02-07T08:22:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:07+00:00',
          aimed_departure_time: '2023-02-07T08:28:07+00:00',
          expected_arrival_time: '2023-02-07T08:27:40+00:00',
          aimed_arrival_time: '2023-02-07T08:27:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:02:14+00:00',
          aimed_departure_time: '2023-02-07T08:02:13+00:00',
          expected_arrival_time: '2023-02-07T08:01:45+00:00',
          aimed_arrival_time: '2023-02-07T08:01:44+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:07:13+00:00',
          aimed_departure_time: '2023-02-07T08:07:13+00:00',
          expected_arrival_time: '2023-02-07T08:06:44+00:00',
          aimed_arrival_time: '2023-02-07T08:06:44+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:13+00:00',
          aimed_departure_time: '2023-02-07T08:12:13+00:00',
          expected_arrival_time: '2023-02-07T08:11:44+00:00',
          aimed_arrival_time: '2023-02-07T08:11:44+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:13+00:00',
          aimed_departure_time: '2023-02-07T08:17:13+00:00',
          expected_arrival_time: '2023-02-07T08:16:44+00:00',
          aimed_arrival_time: '2023-02-07T08:16:44+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:36+00:00',
          aimed_departure_time: '2023-02-07T08:22:13+00:00',
          expected_arrival_time: '2023-02-07T08:22:07+00:00',
          aimed_arrival_time: '2023-02-07T08:21:44+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:10+00:00',
          aimed_departure_time: '2023-02-07T08:29:13+00:00',
          expected_arrival_time: '2023-02-07T08:29:41+00:00',
          aimed_arrival_time: '2023-02-07T08:28:44+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 53144,
      backward_id: 53145,
      line_id: 1,
      order: 4,
      latitude: 60.38453,
      longitude: 5.33262,
      name: 'Nygård',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:05:42+00:00',
          aimed_departure_time: '2023-02-07T08:05:05+00:00',
          expected_arrival_time: '2023-02-07T08:05:17+00:00',
          aimed_arrival_time: '2023-02-07T08:04:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:05+00:00',
          aimed_departure_time: '2023-02-07T08:10:05+00:00',
          expected_arrival_time: '2023-02-07T08:09:40+00:00',
          aimed_arrival_time: '2023-02-07T08:09:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:05+00:00',
          aimed_departure_time: '2023-02-07T08:15:05+00:00',
          expected_arrival_time: '2023-02-07T08:14:40+00:00',
          aimed_arrival_time: '2023-02-07T08:14:40+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:05+00:00',
          aimed_departure_time: '2023-02-07T08:20:05+00:00',
          expected_arrival_time: '2023-02-07T08:19:40+00:00',
          aimed_arrival_time: '2023-02-07T08:19:40+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:05+00:00',
          aimed_departure_time: '2023-02-07T08:25:05+00:00',
          expected_arrival_time: '2023-02-07T08:24:40+00:00',
          aimed_arrival_time: '2023-02-07T08:24:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:05+00:00',
          aimed_departure_time: '2023-02-07T08:30:05+00:00',
          expected_arrival_time: '2023-02-07T08:29:40+00:00',
          aimed_arrival_time: '2023-02-07T08:29:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:05:14+00:00',
          aimed_departure_time: '2023-02-07T08:05:14+00:00',
          expected_arrival_time: '2023-02-07T08:04:50+00:00',
          aimed_arrival_time: '2023-02-07T08:04:50+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:14+00:00',
          aimed_departure_time: '2023-02-07T08:10:14+00:00',
          expected_arrival_time: '2023-02-07T08:09:50+00:00',
          aimed_arrival_time: '2023-02-07T08:09:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:14+00:00',
          aimed_departure_time: '2023-02-07T08:15:14+00:00',
          expected_arrival_time: '2023-02-07T08:14:50+00:00',
          aimed_arrival_time: '2023-02-07T08:14:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:37+00:00',
          aimed_departure_time: '2023-02-07T08:20:14+00:00',
          expected_arrival_time: '2023-02-07T08:20:13+00:00',
          aimed_arrival_time: '2023-02-07T08:19:50+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:11+00:00',
          aimed_departure_time: '2023-02-07T08:27:14+00:00',
          expected_arrival_time: '2023-02-07T08:27:47+00:00',
          aimed_arrival_time: '2023-02-07T08:26:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 53150,
      backward_id: 53151,
      line_id: 1,
      order: 5,
      latitude: 60.38162,
      longitude: 5.332997,
      name: 'Florida',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:07:00+00:00',
          aimed_departure_time: '2023-02-07T08:06:23+00:00',
          expected_arrival_time: '2023-02-07T08:06:39+00:00',
          aimed_arrival_time: '2023-02-07T08:06:02+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:11:23+00:00',
          aimed_departure_time: '2023-02-07T08:11:23+00:00',
          expected_arrival_time: '2023-02-07T08:11:02+00:00',
          aimed_arrival_time: '2023-02-07T08:11:02+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:23+00:00',
          aimed_departure_time: '2023-02-07T08:16:23+00:00',
          expected_arrival_time: '2023-02-07T08:16:02+00:00',
          aimed_arrival_time: '2023-02-07T08:16:02+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:23+00:00',
          aimed_departure_time: '2023-02-07T08:21:23+00:00',
          expected_arrival_time: '2023-02-07T08:21:02+00:00',
          aimed_arrival_time: '2023-02-07T08:21:02+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:23+00:00',
          aimed_departure_time: '2023-02-07T08:26:23+00:00',
          expected_arrival_time: '2023-02-07T08:26:02+00:00',
          aimed_arrival_time: '2023-02-07T08:26:02+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:23+00:00',
          aimed_departure_time: '2023-02-07T08:31:23+00:00',
          expected_arrival_time: '2023-02-07T08:31:02+00:00',
          aimed_arrival_time: '2023-02-07T08:31:02+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:03:53+00:00',
          aimed_departure_time: '2023-02-07T08:03:53+00:00',
          expected_arrival_time: '2023-02-07T08:03:32+00:00',
          aimed_arrival_time: '2023-02-07T08:03:32+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:53+00:00',
          aimed_departure_time: '2023-02-07T08:08:53+00:00',
          expected_arrival_time: '2023-02-07T08:08:32+00:00',
          aimed_arrival_time: '2023-02-07T08:08:32+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:53+00:00',
          aimed_departure_time: '2023-02-07T08:13:53+00:00',
          expected_arrival_time: '2023-02-07T08:13:32+00:00',
          aimed_arrival_time: '2023-02-07T08:13:32+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:19:16+00:00',
          aimed_departure_time: '2023-02-07T08:18:53+00:00',
          expected_arrival_time: '2023-02-07T08:18:55+00:00',
          aimed_arrival_time: '2023-02-07T08:18:32+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:50+00:00',
          aimed_departure_time: '2023-02-07T08:25:53+00:00',
          expected_arrival_time: '2023-02-07T08:26:29+00:00',
          aimed_arrival_time: '2023-02-07T08:25:32+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54020,
      backward_id: 54019,
      line_id: 1,
      order: 6,
      latitude: 60.37562,
      longitude: 5.3397,
      name: 'Danmarks plass',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:03:25+00:00',
          aimed_departure_time: '2023-02-07T08:03:24+00:00',
          expected_arrival_time: '2023-02-07T08:02:56+00:00',
          aimed_arrival_time: '2023-02-07T08:02:55+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:09:01+00:00',
          aimed_departure_time: '2023-02-07T08:08:24+00:00',
          expected_arrival_time: '2023-02-07T08:08:32+00:00',
          aimed_arrival_time: '2023-02-07T08:07:55+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:24+00:00',
          aimed_departure_time: '2023-02-07T08:13:24+00:00',
          expected_arrival_time: '2023-02-07T08:12:55+00:00',
          aimed_arrival_time: '2023-02-07T08:12:55+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:24+00:00',
          aimed_departure_time: '2023-02-07T08:18:24+00:00',
          expected_arrival_time: '2023-02-07T08:17:55+00:00',
          aimed_arrival_time: '2023-02-07T08:17:55+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:24+00:00',
          aimed_departure_time: '2023-02-07T08:23:24+00:00',
          expected_arrival_time: '2023-02-07T08:22:55+00:00',
          aimed_arrival_time: '2023-02-07T08:22:55+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:24+00:00',
          aimed_departure_time: '2023-02-07T08:28:24+00:00',
          expected_arrival_time: '2023-02-07T08:27:55+00:00',
          aimed_arrival_time: '2023-02-07T08:27:55+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:06:54+00:00',
          aimed_departure_time: '2023-02-07T08:06:54+00:00',
          expected_arrival_time: '2023-02-07T08:06:25+00:00',
          aimed_arrival_time: '2023-02-07T08:06:25+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:11:54+00:00',
          aimed_departure_time: '2023-02-07T08:11:54+00:00',
          expected_arrival_time: '2023-02-07T08:11:25+00:00',
          aimed_arrival_time: '2023-02-07T08:11:25+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:17+00:00',
          aimed_departure_time: '2023-02-07T08:16:54+00:00',
          expected_arrival_time: '2023-02-07T08:16:48+00:00',
          aimed_arrival_time: '2023-02-07T08:16:25+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:51+00:00',
          aimed_departure_time: '2023-02-07T08:23:54+00:00',
          expected_arrival_time: '2023-02-07T08:24:22+00:00',
          aimed_arrival_time: '2023-02-07T08:23:25+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54023,
      backward_id: 54022,
      line_id: 1,
      order: 7,
      latitude: 60.37065,
      longitude: 5.348263,
      name: 'Kronstad',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:05:19+00:00',
          aimed_departure_time: '2023-02-07T08:05:18+00:00',
          expected_arrival_time: '2023-02-07T08:04:52+00:00',
          aimed_arrival_time: '2023-02-07T08:04:51+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:55+00:00',
          aimed_departure_time: '2023-02-07T08:10:18+00:00',
          expected_arrival_time: '2023-02-07T08:10:28+00:00',
          aimed_arrival_time: '2023-02-07T08:09:51+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:18+00:00',
          aimed_departure_time: '2023-02-07T08:15:18+00:00',
          expected_arrival_time: '2023-02-07T08:14:51+00:00',
          aimed_arrival_time: '2023-02-07T08:14:51+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:18+00:00',
          aimed_departure_time: '2023-02-07T08:20:18+00:00',
          expected_arrival_time: '2023-02-07T08:19:51+00:00',
          aimed_arrival_time: '2023-02-07T08:19:51+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:18+00:00',
          aimed_departure_time: '2023-02-07T08:25:18+00:00',
          expected_arrival_time: '2023-02-07T08:24:51+00:00',
          aimed_arrival_time: '2023-02-07T08:24:51+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:18+00:00',
          aimed_departure_time: '2023-02-07T08:30:18+00:00',
          expected_arrival_time: '2023-02-07T08:29:51+00:00',
          aimed_arrival_time: '2023-02-07T08:29:51+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:04:51+00:00',
          aimed_departure_time: '2023-02-07T08:04:51+00:00',
          expected_arrival_time: '2023-02-07T08:04:26+00:00',
          aimed_arrival_time: '2023-02-07T08:04:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:09:51+00:00',
          aimed_departure_time: '2023-02-07T08:09:51+00:00',
          expected_arrival_time: '2023-02-07T08:09:26+00:00',
          aimed_arrival_time: '2023-02-07T08:09:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:14+00:00',
          aimed_departure_time: '2023-02-07T08:14:51+00:00',
          expected_arrival_time: '2023-02-07T08:14:49+00:00',
          aimed_arrival_time: '2023-02-07T08:14:26+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:48+00:00',
          aimed_departure_time: '2023-02-07T08:21:51+00:00',
          expected_arrival_time: '2023-02-07T08:22:23+00:00',
          aimed_arrival_time: '2023-02-07T08:21:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:33+00:00',
          aimed_departure_time: '2023-02-07T08:29:51+00:00',
          expected_arrival_time: '2023-02-07T08:30:08+00:00',
          aimed_arrival_time: '2023-02-07T08:29:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54028,
      backward_id: 54027,
      line_id: 1,
      order: 8,
      latitude: 60.36548,
      longitude: 5.35203,
      name: 'Brann stadion',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:06:39+00:00',
          aimed_departure_time: '2023-02-07T08:06:38+00:00',
          expected_arrival_time: '2023-02-07T08:06:17+00:00',
          aimed_arrival_time: '2023-02-07T08:06:16+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:15+00:00',
          aimed_departure_time: '2023-02-07T08:11:38+00:00',
          expected_arrival_time: '2023-02-07T08:11:53+00:00',
          aimed_arrival_time: '2023-02-07T08:11:16+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:38+00:00',
          aimed_departure_time: '2023-02-07T08:16:38+00:00',
          expected_arrival_time: '2023-02-07T08:16:16+00:00',
          aimed_arrival_time: '2023-02-07T08:16:16+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:38+00:00',
          aimed_departure_time: '2023-02-07T08:21:38+00:00',
          expected_arrival_time: '2023-02-07T08:21:16+00:00',
          aimed_arrival_time: '2023-02-07T08:21:16+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:38+00:00',
          aimed_departure_time: '2023-02-07T08:26:38+00:00',
          expected_arrival_time: '2023-02-07T08:26:16+00:00',
          aimed_arrival_time: '2023-02-07T08:26:16+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:38+00:00',
          aimed_departure_time: '2023-02-07T08:31:38+00:00',
          expected_arrival_time: '2023-02-07T08:31:16+00:00',
          aimed_arrival_time: '2023-02-07T08:31:16+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:02:52+00:00',
          aimed_departure_time: '2023-02-07T08:03:19+00:00',
          expected_arrival_time: '2023-02-07T08:02:30+00:00',
          aimed_arrival_time: '2023-02-07T08:02:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:19+00:00',
          aimed_departure_time: '2023-02-07T08:08:19+00:00',
          expected_arrival_time: '2023-02-07T08:07:57+00:00',
          aimed_arrival_time: '2023-02-07T08:07:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:42+00:00',
          aimed_departure_time: '2023-02-07T08:13:19+00:00',
          expected_arrival_time: '2023-02-07T08:13:20+00:00',
          aimed_arrival_time: '2023-02-07T08:12:57+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:16+00:00',
          aimed_departure_time: '2023-02-07T08:20:19+00:00',
          expected_arrival_time: '2023-02-07T08:20:54+00:00',
          aimed_arrival_time: '2023-02-07T08:19:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:29:01+00:00',
          aimed_departure_time: '2023-02-07T08:28:19+00:00',
          expected_arrival_time: '2023-02-07T08:28:39+00:00',
          aimed_arrival_time: '2023-02-07T08:27:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54031,
      backward_id: 54032,
      line_id: 1,
      order: 9,
      latitude: 60.36055,
      longitude: 5.35117,
      name: 'Wergeland',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:03:47+00:00',
          aimed_departure_time: '2023-02-07T08:03:14+00:00',
          expected_arrival_time: '2023-02-07T08:03:21+00:00',
          aimed_arrival_time: '2023-02-07T08:02:48+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:15+00:00',
          aimed_departure_time: '2023-02-07T08:08:14+00:00',
          expected_arrival_time: '2023-02-07T08:07:49+00:00',
          aimed_arrival_time: '2023-02-07T08:07:48+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:51+00:00',
          aimed_departure_time: '2023-02-07T08:13:14+00:00',
          expected_arrival_time: '2023-02-07T08:13:25+00:00',
          aimed_arrival_time: '2023-02-07T08:12:48+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:14+00:00',
          aimed_departure_time: '2023-02-07T08:18:14+00:00',
          expected_arrival_time: '2023-02-07T08:17:48+00:00',
          aimed_arrival_time: '2023-02-07T08:17:48+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:14+00:00',
          aimed_departure_time: '2023-02-07T08:23:14+00:00',
          expected_arrival_time: '2023-02-07T08:22:48+00:00',
          aimed_arrival_time: '2023-02-07T08:22:48+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:14+00:00',
          aimed_departure_time: '2023-02-07T08:28:14+00:00',
          expected_arrival_time: '2023-02-07T08:27:48+00:00',
          aimed_arrival_time: '2023-02-07T08:27:48+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:06:59+00:00',
          aimed_departure_time: '2023-02-07T08:06:59+00:00',
          expected_arrival_time: '2023-02-07T08:06:35+00:00',
          aimed_arrival_time: '2023-02-07T08:06:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:22+00:00',
          aimed_departure_time: '2023-02-07T08:11:59+00:00',
          expected_arrival_time: '2023-02-07T08:11:58+00:00',
          aimed_arrival_time: '2023-02-07T08:11:35+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:19:56+00:00',
          aimed_departure_time: '2023-02-07T08:18:59+00:00',
          expected_arrival_time: '2023-02-07T08:19:32+00:00',
          aimed_arrival_time: '2023-02-07T08:18:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:27:41+00:00',
          aimed_departure_time: '2023-02-07T08:26:59+00:00',
          expected_arrival_time: '2023-02-07T08:27:17+00:00',
          aimed_arrival_time: '2023-02-07T08:26:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54038,
      backward_id: 54037,
      line_id: 1,
      order: 10,
      latitude: 60.356403,
      longitude: 5.358693,
      name: 'Sletten',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:05:50+00:00',
          aimed_departure_time: '2023-02-07T08:05:17+00:00',
          expected_arrival_time: '2023-02-07T08:05:20+00:00',
          aimed_arrival_time: '2023-02-07T08:04:47+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:18+00:00',
          aimed_departure_time: '2023-02-07T08:10:17+00:00',
          expected_arrival_time: '2023-02-07T08:09:48+00:00',
          aimed_arrival_time: '2023-02-07T08:09:47+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:54+00:00',
          aimed_departure_time: '2023-02-07T08:15:17+00:00',
          expected_arrival_time: '2023-02-07T08:15:24+00:00',
          aimed_arrival_time: '2023-02-07T08:14:47+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:17+00:00',
          aimed_departure_time: '2023-02-07T08:20:17+00:00',
          expected_arrival_time: '2023-02-07T08:19:47+00:00',
          aimed_arrival_time: '2023-02-07T08:19:47+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:17+00:00',
          aimed_departure_time: '2023-02-07T08:25:17+00:00',
          expected_arrival_time: '2023-02-07T08:24:47+00:00',
          aimed_arrival_time: '2023-02-07T08:24:47+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:17+00:00',
          aimed_departure_time: '2023-02-07T08:30:17+00:00',
          expected_arrival_time: '2023-02-07T08:29:47+00:00',
          aimed_arrival_time: '2023-02-07T08:29:47+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:05:00+00:00',
          aimed_departure_time: '2023-02-07T08:05:00+00:00',
          expected_arrival_time: '2023-02-07T08:04:33+00:00',
          aimed_arrival_time: '2023-02-07T08:04:33+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:23+00:00',
          aimed_departure_time: '2023-02-07T08:10:00+00:00',
          expected_arrival_time: '2023-02-07T08:09:56+00:00',
          aimed_arrival_time: '2023-02-07T08:09:33+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:57+00:00',
          aimed_departure_time: '2023-02-07T08:17:00+00:00',
          expected_arrival_time: '2023-02-07T08:17:30+00:00',
          aimed_arrival_time: '2023-02-07T08:16:33+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:42+00:00',
          aimed_departure_time: '2023-02-07T08:25:00+00:00',
          expected_arrival_time: '2023-02-07T08:25:15+00:00',
          aimed_arrival_time: '2023-02-07T08:24:33+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54041,
      backward_id: 54043,
      line_id: 1,
      order: 11,
      latitude: 60.35032,
      longitude: 5.36004,
      name: 'Slettebakken',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:07:21+00:00',
          aimed_departure_time: '2023-02-07T08:06:48+00:00',
          expected_arrival_time: '2023-02-07T08:07:00+00:00',
          aimed_arrival_time: '2023-02-07T08:06:27+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:11:49+00:00',
          aimed_departure_time: '2023-02-07T08:11:48+00:00',
          expected_arrival_time: '2023-02-07T08:11:28+00:00',
          aimed_arrival_time: '2023-02-07T08:11:27+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:25+00:00',
          aimed_departure_time: '2023-02-07T08:16:48+00:00',
          expected_arrival_time: '2023-02-07T08:17:04+00:00',
          aimed_arrival_time: '2023-02-07T08:16:27+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:48+00:00',
          aimed_departure_time: '2023-02-07T08:21:48+00:00',
          expected_arrival_time: '2023-02-07T08:21:27+00:00',
          aimed_arrival_time: '2023-02-07T08:21:27+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:48+00:00',
          aimed_departure_time: '2023-02-07T08:26:48+00:00',
          expected_arrival_time: '2023-02-07T08:26:27+00:00',
          aimed_arrival_time: '2023-02-07T08:26:27+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:48+00:00',
          aimed_departure_time: '2023-02-07T08:31:48+00:00',
          expected_arrival_time: '2023-02-07T08:31:27+00:00',
          aimed_arrival_time: '2023-02-07T08:31:27+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:03:19+00:00',
          aimed_departure_time: '2023-02-07T08:03:17+00:00',
          expected_arrival_time: '2023-02-07T08:02:59+00:00',
          aimed_arrival_time: '2023-02-07T08:02:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:40+00:00',
          aimed_departure_time: '2023-02-07T08:08:17+00:00',
          expected_arrival_time: '2023-02-07T08:08:20+00:00',
          aimed_arrival_time: '2023-02-07T08:07:57+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:14+00:00',
          aimed_departure_time: '2023-02-07T08:15:17+00:00',
          expected_arrival_time: '2023-02-07T08:15:54+00:00',
          aimed_arrival_time: '2023-02-07T08:14:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:59+00:00',
          aimed_departure_time: '2023-02-07T08:23:17+00:00',
          expected_arrival_time: '2023-02-07T08:23:39+00:00',
          aimed_arrival_time: '2023-02-07T08:22:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:17+00:00',
          aimed_departure_time: '2023-02-07T08:30:17+00:00',
          expected_arrival_time: '2023-02-07T08:29:57+00:00',
          aimed_arrival_time: '2023-02-07T08:29:57+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 54049,
      backward_id: 54050,
      line_id: 1,
      order: 12,
      latitude: 60.34591,
      longitude: 5.35291,
      name: 'Fantoft',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:03:25+00:00',
          aimed_departure_time: '2023-02-07T08:03:42+00:00',
          expected_arrival_time: '2023-02-07T08:02:58+00:00',
          aimed_arrival_time: '2023-02-07T08:03:15+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:09:15+00:00',
          aimed_departure_time: '2023-02-07T08:08:42+00:00',
          expected_arrival_time: '2023-02-07T08:08:48+00:00',
          aimed_arrival_time: '2023-02-07T08:08:15+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:43+00:00',
          aimed_departure_time: '2023-02-07T08:13:42+00:00',
          expected_arrival_time: '2023-02-07T08:13:16+00:00',
          aimed_arrival_time: '2023-02-07T08:13:15+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:19:19+00:00',
          aimed_departure_time: '2023-02-07T08:18:42+00:00',
          expected_arrival_time: '2023-02-07T08:18:52+00:00',
          aimed_arrival_time: '2023-02-07T08:18:15+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:42+00:00',
          aimed_departure_time: '2023-02-07T08:23:42+00:00',
          expected_arrival_time: '2023-02-07T08:23:15+00:00',
          aimed_arrival_time: '2023-02-07T08:23:15+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:42+00:00',
          aimed_departure_time: '2023-02-07T08:28:42+00:00',
          expected_arrival_time: '2023-02-07T08:28:15+00:00',
          aimed_arrival_time: '2023-02-07T08:28:15+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:06:47+00:00',
          aimed_departure_time: '2023-02-07T08:06:24+00:00',
          expected_arrival_time: '2023-02-07T08:06:22+00:00',
          aimed_arrival_time: '2023-02-07T08:05:59+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:14:21+00:00',
          aimed_departure_time: '2023-02-07T08:13:24+00:00',
          expected_arrival_time: '2023-02-07T08:13:56+00:00',
          aimed_arrival_time: '2023-02-07T08:12:59+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:06+00:00',
          aimed_departure_time: '2023-02-07T08:21:24+00:00',
          expected_arrival_time: '2023-02-07T08:21:41+00:00',
          aimed_arrival_time: '2023-02-07T08:20:59+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:24+00:00',
          aimed_departure_time: '2023-02-07T08:28:24+00:00',
          expected_arrival_time: '2023-02-07T08:27:59+00:00',
          aimed_arrival_time: '2023-02-07T08:27:59+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 50347,
      backward_id: 50345,
      line_id: 1,
      order: 13,
      latitude: 60.33627,
      longitude: 5.34696,
      name: 'Paradis',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:05:33+00:00',
          aimed_departure_time: '2023-02-07T08:05:33+00:00',
          expected_arrival_time: '2023-02-07T08:05:12+00:00',
          aimed_arrival_time: '2023-02-07T08:05:12+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:11:06+00:00',
          aimed_departure_time: '2023-02-07T08:10:33+00:00',
          expected_arrival_time: '2023-02-07T08:10:45+00:00',
          aimed_arrival_time: '2023-02-07T08:10:12+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:34+00:00',
          aimed_departure_time: '2023-02-07T08:15:33+00:00',
          expected_arrival_time: '2023-02-07T08:15:13+00:00',
          aimed_arrival_time: '2023-02-07T08:15:12+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:10+00:00',
          aimed_departure_time: '2023-02-07T08:20:33+00:00',
          expected_arrival_time: '2023-02-07T08:20:49+00:00',
          aimed_arrival_time: '2023-02-07T08:20:12+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:33+00:00',
          aimed_departure_time: '2023-02-07T08:25:33+00:00',
          expected_arrival_time: '2023-02-07T08:25:12+00:00',
          aimed_arrival_time: '2023-02-07T08:25:12+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:33+00:00',
          aimed_departure_time: '2023-02-07T08:30:33+00:00',
          expected_arrival_time: '2023-02-07T08:30:12+00:00',
          aimed_arrival_time: '2023-02-07T08:30:12+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:04:56+00:00',
          aimed_departure_time: '2023-02-07T08:04:33+00:00',
          expected_arrival_time: '2023-02-07T08:04:34+00:00',
          aimed_arrival_time: '2023-02-07T08:04:11+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:30+00:00',
          aimed_departure_time: '2023-02-07T08:11:33+00:00',
          expected_arrival_time: '2023-02-07T08:12:08+00:00',
          aimed_arrival_time: '2023-02-07T08:11:11+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:15+00:00',
          aimed_departure_time: '2023-02-07T08:19:33+00:00',
          expected_arrival_time: '2023-02-07T08:19:53+00:00',
          aimed_arrival_time: '2023-02-07T08:19:11+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:33+00:00',
          aimed_departure_time: '2023-02-07T08:26:33+00:00',
          expected_arrival_time: '2023-02-07T08:26:11+00:00',
          aimed_arrival_time: '2023-02-07T08:26:11+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 51253,
      backward_id: 51252,
      line_id: 1,
      order: 14,
      latitude: 60.32626,
      longitude: 5.346303,
      name: 'Hop',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:02:29+00:00',
          aimed_departure_time: '2023-02-07T08:02:29+00:00',
          expected_arrival_time: '2023-02-07T08:02:08+00:00',
          aimed_arrival_time: '2023-02-07T08:02:08+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:07:29+00:00',
          aimed_departure_time: '2023-02-07T08:07:29+00:00',
          expected_arrival_time: '2023-02-07T08:07:08+00:00',
          aimed_arrival_time: '2023-02-07T08:07:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:02+00:00',
          aimed_departure_time: '2023-02-07T08:12:29+00:00',
          expected_arrival_time: '2023-02-07T08:12:41+00:00',
          aimed_arrival_time: '2023-02-07T08:12:08+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:30+00:00',
          aimed_departure_time: '2023-02-07T08:17:29+00:00',
          expected_arrival_time: '2023-02-07T08:17:09+00:00',
          aimed_arrival_time: '2023-02-07T08:17:08+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:06+00:00',
          aimed_departure_time: '2023-02-07T08:22:29+00:00',
          expected_arrival_time: '2023-02-07T08:22:45+00:00',
          aimed_arrival_time: '2023-02-07T08:22:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:27:29+00:00',
          aimed_departure_time: '2023-02-07T08:27:29+00:00',
          expected_arrival_time: '2023-02-07T08:27:08+00:00',
          aimed_arrival_time: '2023-02-07T08:27:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:02:49+00:00',
          aimed_departure_time: '2023-02-07T08:02:36+00:00',
          expected_arrival_time: '2023-02-07T08:02:29+00:00',
          aimed_arrival_time: '2023-02-07T08:02:16+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:33+00:00',
          aimed_departure_time: '2023-02-07T08:09:36+00:00',
          expected_arrival_time: '2023-02-07T08:10:13+00:00',
          aimed_arrival_time: '2023-02-07T08:09:16+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:18+00:00',
          aimed_departure_time: '2023-02-07T08:17:36+00:00',
          expected_arrival_time: '2023-02-07T08:17:58+00:00',
          aimed_arrival_time: '2023-02-07T08:17:16+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:36+00:00',
          aimed_departure_time: '2023-02-07T08:24:36+00:00',
          expected_arrival_time: '2023-02-07T08:24:16+00:00',
          aimed_arrival_time: '2023-02-07T08:24:16+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 51261,
      backward_id: 51260,
      line_id: 1,
      order: 15,
      latitude: 60.31967,
      longitude: 5.352712,
      name: 'Nesttun terminal',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:04:14+00:00',
          aimed_departure_time: '2023-02-07T08:04:14+00:00',
          expected_arrival_time: '2023-02-07T08:03:46+00:00',
          aimed_arrival_time: '2023-02-07T08:03:46+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:09:14+00:00',
          aimed_departure_time: '2023-02-07T08:09:14+00:00',
          expected_arrival_time: '2023-02-07T08:08:46+00:00',
          aimed_arrival_time: '2023-02-07T08:08:46+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:14:47+00:00',
          aimed_departure_time: '2023-02-07T08:14:14+00:00',
          expected_arrival_time: '2023-02-07T08:14:19+00:00',
          aimed_arrival_time: '2023-02-07T08:13:46+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:19:15+00:00',
          aimed_departure_time: '2023-02-07T08:19:14+00:00',
          expected_arrival_time: '2023-02-07T08:18:47+00:00',
          aimed_arrival_time: '2023-02-07T08:18:46+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:51+00:00',
          aimed_departure_time: '2023-02-07T08:24:14+00:00',
          expected_arrival_time: '2023-02-07T08:24:23+00:00',
          aimed_arrival_time: '2023-02-07T08:23:46+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:29:14+00:00',
          aimed_departure_time: '2023-02-07T08:29:14+00:00',
          expected_arrival_time: '2023-02-07T08:28:46+00:00',
          aimed_arrival_time: '2023-02-07T08:28:46+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:08:48+00:00',
          aimed_departure_time: '2023-02-07T08:07:51+00:00',
          expected_arrival_time: '2023-02-07T08:08:20+00:00',
          aimed_arrival_time: '2023-02-07T08:07:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:33+00:00',
          aimed_departure_time: '2023-02-07T08:15:51+00:00',
          expected_arrival_time: '2023-02-07T08:16:05+00:00',
          aimed_arrival_time: '2023-02-07T08:15:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:51+00:00',
          aimed_departure_time: '2023-02-07T08:22:51+00:00',
          expected_arrival_time: '2023-02-07T08:22:23+00:00',
          aimed_arrival_time: '2023-02-07T08:22:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:51+00:00',
          aimed_departure_time: '2023-02-07T08:30:51+00:00',
          expected_arrival_time: '2023-02-07T08:30:23+00:00',
          aimed_arrival_time: '2023-02-07T08:30:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 51255,
      backward_id: 51256,
      line_id: 1,
      order: 16,
      latitude: 60.31743,
      longitude: 5.353706,
      name: 'Nesttun sentrum',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:05:42+00:00',
          aimed_departure_time: '2023-02-07T08:05:42+00:00',
          expected_arrival_time: '2023-02-07T08:05:19+00:00',
          aimed_arrival_time: '2023-02-07T08:05:19+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:10:42+00:00',
          aimed_departure_time: '2023-02-07T08:10:42+00:00',
          expected_arrival_time: '2023-02-07T08:10:19+00:00',
          aimed_arrival_time: '2023-02-07T08:10:19+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:15+00:00',
          aimed_departure_time: '2023-02-07T08:15:42+00:00',
          expected_arrival_time: '2023-02-07T08:15:52+00:00',
          aimed_arrival_time: '2023-02-07T08:15:19+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:43+00:00',
          aimed_departure_time: '2023-02-07T08:20:42+00:00',
          expected_arrival_time: '2023-02-07T08:20:20+00:00',
          aimed_arrival_time: '2023-02-07T08:20:19+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:19+00:00',
          aimed_departure_time: '2023-02-07T08:25:42+00:00',
          expected_arrival_time: '2023-02-07T08:25:56+00:00',
          aimed_arrival_time: '2023-02-07T08:25:19+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:42+00:00',
          aimed_departure_time: '2023-02-07T08:30:42+00:00',
          expected_arrival_time: '2023-02-07T08:30:19+00:00',
          aimed_arrival_time: '2023-02-07T08:30:19+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:07:16+00:00',
          aimed_departure_time: '2023-02-07T08:06:19+00:00',
          expected_arrival_time: '2023-02-07T08:06:53+00:00',
          aimed_arrival_time: '2023-02-07T08:05:56+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:01+00:00',
          aimed_departure_time: '2023-02-07T08:14:19+00:00',
          expected_arrival_time: '2023-02-07T08:14:38+00:00',
          aimed_arrival_time: '2023-02-07T08:13:56+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:19+00:00',
          aimed_departure_time: '2023-02-07T08:21:19+00:00',
          expected_arrival_time: '2023-02-07T08:20:56+00:00',
          aimed_arrival_time: '2023-02-07T08:20:56+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:29:19+00:00',
          aimed_departure_time: '2023-02-07T08:29:19+00:00',
          expected_arrival_time: '2023-02-07T08:28:56+00:00',
          aimed_arrival_time: '2023-02-07T08:28:56+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 51267,
      backward_id: 51266,
      line_id: 1,
      order: 17,
      latitude: 60.31129,
      longitude: 5.35299,
      name: 'Skjoldskiftet',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:02:05+00:00',
          aimed_departure_time: '2023-02-07T08:02:10+00:00',
          expected_arrival_time: '2023-02-07T08:01:43+00:00',
          aimed_arrival_time: '2023-02-07T08:01:48+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:07:10+00:00',
          aimed_departure_time: '2023-02-07T08:07:10+00:00',
          expected_arrival_time: '2023-02-07T08:06:48+00:00',
          aimed_arrival_time: '2023-02-07T08:06:48+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:12:10+00:00',
          aimed_departure_time: '2023-02-07T08:12:10+00:00',
          expected_arrival_time: '2023-02-07T08:11:48+00:00',
          aimed_arrival_time: '2023-02-07T08:11:48+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:43+00:00',
          aimed_departure_time: '2023-02-07T08:17:10+00:00',
          expected_arrival_time: '2023-02-07T08:17:21+00:00',
          aimed_arrival_time: '2023-02-07T08:16:48+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:11+00:00',
          aimed_departure_time: '2023-02-07T08:22:10+00:00',
          expected_arrival_time: '2023-02-07T08:21:49+00:00',
          aimed_arrival_time: '2023-02-07T08:21:48+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:27:47+00:00',
          aimed_departure_time: '2023-02-07T08:27:10+00:00',
          expected_arrival_time: '2023-02-07T08:27:25+00:00',
          aimed_arrival_time: '2023-02-07T08:26:48+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:05:42+00:00',
          aimed_departure_time: '2023-02-07T08:04:45+00:00',
          expected_arrival_time: '2023-02-07T08:05:20+00:00',
          aimed_arrival_time: '2023-02-07T08:04:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:27+00:00',
          aimed_departure_time: '2023-02-07T08:12:45+00:00',
          expected_arrival_time: '2023-02-07T08:13:05+00:00',
          aimed_arrival_time: '2023-02-07T08:12:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:19:45+00:00',
          aimed_departure_time: '2023-02-07T08:19:45+00:00',
          expected_arrival_time: '2023-02-07T08:19:23+00:00',
          aimed_arrival_time: '2023-02-07T08:19:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:27:45+00:00',
          aimed_departure_time: '2023-02-07T08:27:45+00:00',
          expected_arrival_time: '2023-02-07T08:27:23+00:00',
          aimed_arrival_time: '2023-02-07T08:27:23+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 51272,
      backward_id: 51271,
      line_id: 1,
      order: 18,
      latitude: 60.30951,
      longitude: 5.347668,
      name: 'Mårdalen',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:03:21+00:00',
          aimed_departure_time: '2023-02-07T08:03:21+00:00',
          expected_arrival_time: '2023-02-07T08:03:01+00:00',
          aimed_arrival_time: '2023-02-07T08:03:01+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:08:21+00:00',
          aimed_departure_time: '2023-02-07T08:08:21+00:00',
          expected_arrival_time: '2023-02-07T08:08:01+00:00',
          aimed_arrival_time: '2023-02-07T08:08:01+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:13:21+00:00',
          aimed_departure_time: '2023-02-07T08:13:21+00:00',
          expected_arrival_time: '2023-02-07T08:13:01+00:00',
          aimed_arrival_time: '2023-02-07T08:13:01+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:54+00:00',
          aimed_departure_time: '2023-02-07T08:18:21+00:00',
          expected_arrival_time: '2023-02-07T08:18:34+00:00',
          aimed_arrival_time: '2023-02-07T08:18:01+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:22+00:00',
          aimed_departure_time: '2023-02-07T08:23:21+00:00',
          expected_arrival_time: '2023-02-07T08:23:02+00:00',
          aimed_arrival_time: '2023-02-07T08:23:01+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:58+00:00',
          aimed_departure_time: '2023-02-07T08:28:21+00:00',
          expected_arrival_time: '2023-02-07T08:28:38+00:00',
          aimed_arrival_time: '2023-02-07T08:28:01+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:04:25+00:00',
          aimed_departure_time: '2023-02-07T08:03:28+00:00',
          expected_arrival_time: '2023-02-07T08:04:05+00:00',
          aimed_arrival_time: '2023-02-07T08:03:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:10+00:00',
          aimed_departure_time: '2023-02-07T08:11:28+00:00',
          expected_arrival_time: '2023-02-07T08:11:50+00:00',
          aimed_arrival_time: '2023-02-07T08:11:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:28+00:00',
          aimed_departure_time: '2023-02-07T08:18:28+00:00',
          expected_arrival_time: '2023-02-07T08:18:08+00:00',
          aimed_arrival_time: '2023-02-07T08:18:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:28+00:00',
          aimed_departure_time: '2023-02-07T08:26:28+00:00',
          expected_arrival_time: '2023-02-07T08:26:08+00:00',
          aimed_arrival_time: '2023-02-07T08:26:08+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
    },
    {
      id: 51276,
      backward_id: 51275,
      line_id: 1,
      order: 19,
      latitude: 60.30406,
      longitude: 5.333153,
      name: 'Skjold',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:04:55+00:00',
          aimed_departure_time: '2023-02-07T08:04:55+00:00',
          expected_arrival_time: '2023-02-07T08:04:35+00:00',
          aimed_arrival_time: '2023-02-07T08:04:35+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:09:55+00:00',
          aimed_departure_time: '2023-02-07T08:09:55+00:00',
          expected_arrival_time: '2023-02-07T08:09:35+00:00',
          aimed_arrival_time: '2023-02-07T08:09:35+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:14:55+00:00',
          aimed_departure_time: '2023-02-07T08:14:55+00:00',
          expected_arrival_time: '2023-02-07T08:14:35+00:00',
          aimed_arrival_time: '2023-02-07T08:14:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:28+00:00',
          aimed_departure_time: '2023-02-07T08:19:55+00:00',
          expected_arrival_time: '2023-02-07T08:20:08+00:00',
          aimed_arrival_time: '2023-02-07T08:19:35+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:56+00:00',
          aimed_departure_time: '2023-02-07T08:24:55+00:00',
          expected_arrival_time: '2023-02-07T08:24:36+00:00',
          aimed_arrival_time: '2023-02-07T08:24:35+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:32+00:00',
          aimed_departure_time: '2023-02-07T08:29:55+00:00',
          expected_arrival_time: '2023-02-07T08:30:12+00:00',
          aimed_arrival_time: '2023-02-07T08:29:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:10:34+00:00',
          aimed_departure_time: '2023-02-07T08:09:52+00:00',
          expected_arrival_time: '2023-02-07T08:10:13+00:00',
          aimed_arrival_time: '2023-02-07T08:09:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:16:52+00:00',
          aimed_departure_time: '2023-02-07T08:16:52+00:00',
          expected_arrival_time: '2023-02-07T08:16:31+00:00',
          aimed_arrival_time: '2023-02-07T08:16:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:52+00:00',
          aimed_departure_time: '2023-02-07T08:24:52+00:00',
          expected_arrival_time: '2023-02-07T08:24:31+00:00',
          aimed_arrival_time: '2023-02-07T08:24:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:52+00:00',
          aimed_departure_time: '2023-02-07T08:31:52+00:00',
          expected_arrival_time: '2023-02-07T08:31:31+00:00',
          aimed_arrival_time: '2023-02-07T08:31:31+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
    },
    {
      id: 51824,
      backward_id: 51823,
      line_id: 1,
      order: 20,
      latitude: 60.29825,
      longitude: 5.3282,
      name: 'Lagunen terminal',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:06:52+00:00',
          aimed_departure_time: '2023-02-07T08:06:52+00:00',
          expected_arrival_time: '2023-02-07T08:06:21+00:00',
          aimed_arrival_time: '2023-02-07T08:06:21+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:11:52+00:00',
          aimed_departure_time: '2023-02-07T08:11:52+00:00',
          expected_arrival_time: '2023-02-07T08:11:21+00:00',
          aimed_arrival_time: '2023-02-07T08:11:21+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:16:52+00:00',
          aimed_departure_time: '2023-02-07T08:16:52+00:00',
          expected_arrival_time: '2023-02-07T08:16:21+00:00',
          aimed_arrival_time: '2023-02-07T08:16:21+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:25+00:00',
          aimed_departure_time: '2023-02-07T08:21:52+00:00',
          expected_arrival_time: '2023-02-07T08:21:54+00:00',
          aimed_arrival_time: '2023-02-07T08:21:21+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:53+00:00',
          aimed_departure_time: '2023-02-07T08:26:52+00:00',
          expected_arrival_time: '2023-02-07T08:26:22+00:00',
          aimed_arrival_time: '2023-02-07T08:26:21+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:08:50+00:00',
          aimed_departure_time: '2023-02-07T08:08:08+00:00',
          expected_arrival_time: '2023-02-07T08:08:19+00:00',
          aimed_arrival_time: '2023-02-07T08:07:37+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:08+00:00',
          aimed_departure_time: '2023-02-07T08:15:08+00:00',
          expected_arrival_time: '2023-02-07T08:14:37+00:00',
          aimed_arrival_time: '2023-02-07T08:14:37+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:23:08+00:00',
          aimed_departure_time: '2023-02-07T08:23:08+00:00',
          expected_arrival_time: '2023-02-07T08:22:37+00:00',
          aimed_arrival_time: '2023-02-07T08:22:37+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:08+00:00',
          aimed_departure_time: '2023-02-07T08:30:08+00:00',
          expected_arrival_time: '2023-02-07T08:29:37+00:00',
          aimed_arrival_time: '2023-02-07T08:29:37+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
    },
    {
      id: 51722,
      backward_id: 51723,
      line_id: 1,
      order: 21,
      latitude: 60.30032,
      longitude: 5.31039,
      name: 'Råstølen',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:04:05+00:00',
          aimed_departure_time: '2023-02-07T08:03:50+00:00',
          expected_arrival_time: '2023-02-07T08:03:43+00:00',
          aimed_arrival_time: '2023-02-07T08:03:28+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:08:50+00:00',
          aimed_departure_time: '2023-02-07T08:08:50+00:00',
          expected_arrival_time: '2023-02-07T08:08:28+00:00',
          aimed_arrival_time: '2023-02-07T08:08:28+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:13:50+00:00',
          aimed_departure_time: '2023-02-07T08:13:50+00:00',
          expected_arrival_time: '2023-02-07T08:13:28+00:00',
          aimed_arrival_time: '2023-02-07T08:13:28+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:18:50+00:00',
          aimed_departure_time: '2023-02-07T08:18:50+00:00',
          expected_arrival_time: '2023-02-07T08:18:28+00:00',
          aimed_arrival_time: '2023-02-07T08:18:28+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:23+00:00',
          aimed_departure_time: '2023-02-07T08:23:50+00:00',
          expected_arrival_time: '2023-02-07T08:24:01+00:00',
          aimed_arrival_time: '2023-02-07T08:23:28+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:51+00:00',
          aimed_departure_time: '2023-02-07T08:28:50+00:00',
          expected_arrival_time: '2023-02-07T08:28:29+00:00',
          aimed_arrival_time: '2023-02-07T08:28:28+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:06:39+00:00',
          aimed_departure_time: '2023-02-07T08:05:57+00:00',
          expected_arrival_time: '2023-02-07T08:06:17+00:00',
          aimed_arrival_time: '2023-02-07T08:05:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:57+00:00',
          aimed_departure_time: '2023-02-07T08:12:57+00:00',
          expected_arrival_time: '2023-02-07T08:12:35+00:00',
          aimed_arrival_time: '2023-02-07T08:12:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:20:57+00:00',
          aimed_departure_time: '2023-02-07T08:20:57+00:00',
          expected_arrival_time: '2023-02-07T08:20:35+00:00',
          aimed_arrival_time: '2023-02-07T08:20:35+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:27:57+00:00',
          aimed_departure_time: '2023-02-07T08:27:57+00:00',
          expected_arrival_time: '2023-02-07T08:27:35+00:00',
          aimed_arrival_time: '2023-02-07T08:27:35+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
    },
    {
      id: 51834,
      backward_id: 51833,
      line_id: 1,
      order: 22,
      latitude: 60.296474,
      longitude: 5.287318,
      name: 'Sandslivegen',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:06:14+00:00',
          aimed_departure_time: '2023-02-07T08:05:59+00:00',
          expected_arrival_time: '2023-02-07T08:05:54+00:00',
          aimed_arrival_time: '2023-02-07T08:05:39+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:59+00:00',
          aimed_departure_time: '2023-02-07T08:10:59+00:00',
          expected_arrival_time: '2023-02-07T08:10:39+00:00',
          aimed_arrival_time: '2023-02-07T08:10:39+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:15:59+00:00',
          aimed_departure_time: '2023-02-07T08:15:59+00:00',
          expected_arrival_time: '2023-02-07T08:15:39+00:00',
          aimed_arrival_time: '2023-02-07T08:15:39+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:20:59+00:00',
          aimed_departure_time: '2023-02-07T08:20:59+00:00',
          expected_arrival_time: '2023-02-07T08:20:39+00:00',
          aimed_arrival_time: '2023-02-07T08:20:39+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:32+00:00',
          aimed_departure_time: '2023-02-07T08:25:59+00:00',
          expected_arrival_time: '2023-02-07T08:26:12+00:00',
          aimed_arrival_time: '2023-02-07T08:25:39+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:31:00+00:00',
          aimed_departure_time: '2023-02-07T08:30:59+00:00',
          expected_arrival_time: '2023-02-07T08:30:40+00:00',
          aimed_arrival_time: '2023-02-07T08:30:39+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:04:30+00:00',
          aimed_departure_time: '2023-02-07T08:03:48+00:00',
          expected_arrival_time: '2023-02-07T08:04:08+00:00',
          aimed_arrival_time: '2023-02-07T08:03:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:48+00:00',
          aimed_departure_time: '2023-02-07T08:10:48+00:00',
          expected_arrival_time: '2023-02-07T08:10:26+00:00',
          aimed_arrival_time: '2023-02-07T08:10:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:48+00:00',
          aimed_departure_time: '2023-02-07T08:18:48+00:00',
          expected_arrival_time: '2023-02-07T08:18:26+00:00',
          aimed_arrival_time: '2023-02-07T08:18:26+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:25:48+00:00',
          aimed_departure_time: '2023-02-07T08:25:48+00:00',
          expected_arrival_time: '2023-02-07T08:25:26+00:00',
          aimed_arrival_time: '2023-02-07T08:25:26+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
    },
    {
      id: 51844,
      backward_id: 51842,
      line_id: 1,
      order: 23,
      latitude: 60.29779,
      longitude: 5.275251,
      name: 'Sandslimarka',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:03:19+00:00',
          aimed_departure_time: '2023-02-07T08:02:38+00:00',
          expected_arrival_time: '2023-02-07T08:02:59+00:00',
          aimed_arrival_time: '2023-02-07T08:02:18+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:07:53+00:00',
          aimed_departure_time: '2023-02-07T08:07:38+00:00',
          expected_arrival_time: '2023-02-07T08:07:33+00:00',
          aimed_arrival_time: '2023-02-07T08:07:18+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:38+00:00',
          aimed_departure_time: '2023-02-07T08:12:38+00:00',
          expected_arrival_time: '2023-02-07T08:12:18+00:00',
          aimed_arrival_time: '2023-02-07T08:12:18+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:17:38+00:00',
          aimed_departure_time: '2023-02-07T08:17:38+00:00',
          expected_arrival_time: '2023-02-07T08:17:18+00:00',
          aimed_arrival_time: '2023-02-07T08:17:18+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:22:38+00:00',
          aimed_departure_time: '2023-02-07T08:22:38+00:00',
          expected_arrival_time: '2023-02-07T08:22:18+00:00',
          aimed_arrival_time: '2023-02-07T08:22:18+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:28:11+00:00',
          aimed_departure_time: '2023-02-07T08:27:38+00:00',
          expected_arrival_time: '2023-02-07T08:27:51+00:00',
          aimed_arrival_time: '2023-02-07T08:27:18+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:02:49+00:00',
          aimed_departure_time: '2023-02-07T08:02:05+00:00',
          expected_arrival_time: '2023-02-07T08:02:29+00:00',
          aimed_arrival_time: '2023-02-07T08:01:45+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:09:05+00:00',
          aimed_departure_time: '2023-02-07T08:09:05+00:00',
          expected_arrival_time: '2023-02-07T08:08:45+00:00',
          aimed_arrival_time: '2023-02-07T08:08:45+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:05+00:00',
          aimed_departure_time: '2023-02-07T08:17:05+00:00',
          expected_arrival_time: '2023-02-07T08:16:45+00:00',
          aimed_arrival_time: '2023-02-07T08:16:45+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:24:05+00:00',
          aimed_departure_time: '2023-02-07T08:24:05+00:00',
          expected_arrival_time: '2023-02-07T08:23:45+00:00',
          aimed_arrival_time: '2023-02-07T08:23:45+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
    },
    {
      id: 51852,
      backward_id: 51853,
      line_id: 1,
      order: 24,
      latitude: 60.29387,
      longitude: 5.267912,
      name: 'Kokstad',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:04:33+00:00',
          aimed_departure_time: '2023-02-07T08:03:52+00:00',
          expected_arrival_time: '2023-02-07T08:04:13+00:00',
          aimed_arrival_time: '2023-02-07T08:03:32+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:09:07+00:00',
          aimed_departure_time: '2023-02-07T08:08:52+00:00',
          expected_arrival_time: '2023-02-07T08:08:47+00:00',
          aimed_arrival_time: '2023-02-07T08:08:32+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:13:52+00:00',
          aimed_departure_time: '2023-02-07T08:13:52+00:00',
          expected_arrival_time: '2023-02-07T08:13:32+00:00',
          aimed_arrival_time: '2023-02-07T08:13:32+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:18:52+00:00',
          aimed_departure_time: '2023-02-07T08:18:52+00:00',
          expected_arrival_time: '2023-02-07T08:18:32+00:00',
          aimed_arrival_time: '2023-02-07T08:18:32+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:23:52+00:00',
          aimed_departure_time: '2023-02-07T08:23:52+00:00',
          expected_arrival_time: '2023-02-07T08:23:32+00:00',
          aimed_arrival_time: '2023-02-07T08:23:32+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:29:25+00:00',
          aimed_departure_time: '2023-02-07T08:28:52+00:00',
          expected_arrival_time: '2023-02-07T08:29:05+00:00',
          aimed_arrival_time: '2023-02-07T08:28:32+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:07:49+00:00',
          aimed_departure_time: '2023-02-07T08:07:49+00:00',
          expected_arrival_time: '2023-02-07T08:07:31+00:00',
          aimed_arrival_time: '2023-02-07T08:07:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:49+00:00',
          aimed_departure_time: '2023-02-07T08:15:49+00:00',
          expected_arrival_time: '2023-02-07T08:15:31+00:00',
          aimed_arrival_time: '2023-02-07T08:15:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:22:49+00:00',
          aimed_departure_time: '2023-02-07T08:22:49+00:00',
          expected_arrival_time: '2023-02-07T08:22:31+00:00',
          aimed_arrival_time: '2023-02-07T08:22:31+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:49+00:00',
          aimed_departure_time: '2023-02-07T08:30:49+00:00',
          expected_arrival_time: '2023-02-07T08:30:31+00:00',
          aimed_arrival_time: '2023-02-07T08:30:31+00:00',
          occupancy: OccupancyEnum.Full,
        },
      ],
    },
    {
      id: 51867,
      backward_id: 51868,
      line_id: 1,
      order: 25,
      latitude: 60.28833,
      longitude: 5.262007,
      name: 'Birkelandsskiftet terminal',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:05:53+00:00',
          aimed_departure_time: '2023-02-07T08:05:12+00:00',
          expected_arrival_time: '2023-02-07T08:05:32+00:00',
          aimed_arrival_time: '2023-02-07T08:04:51+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:10:27+00:00',
          aimed_departure_time: '2023-02-07T08:10:12+00:00',
          expected_arrival_time: '2023-02-07T08:10:06+00:00',
          aimed_arrival_time: '2023-02-07T08:09:51+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:15:12+00:00',
          aimed_departure_time: '2023-02-07T08:15:12+00:00',
          expected_arrival_time: '2023-02-07T08:14:51+00:00',
          aimed_arrival_time: '2023-02-07T08:14:51+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:20:12+00:00',
          aimed_departure_time: '2023-02-07T08:20:12+00:00',
          expected_arrival_time: '2023-02-07T08:19:51+00:00',
          aimed_arrival_time: '2023-02-07T08:19:51+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:25:12+00:00',
          aimed_departure_time: '2023-02-07T08:25:12+00:00',
          expected_arrival_time: '2023-02-07T08:24:51+00:00',
          aimed_arrival_time: '2023-02-07T08:24:51+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:30:45+00:00',
          aimed_departure_time: '2023-02-07T08:30:12+00:00',
          expected_arrival_time: '2023-02-07T08:30:24+00:00',
          aimed_arrival_time: '2023-02-07T08:29:51+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:06:37+00:00',
          aimed_departure_time: '2023-02-07T08:06:37+00:00',
          expected_arrival_time: '2023-02-07T08:06:18+00:00',
          aimed_arrival_time: '2023-02-07T08:06:18+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:14:37+00:00',
          aimed_departure_time: '2023-02-07T08:14:37+00:00',
          expected_arrival_time: '2023-02-07T08:14:18+00:00',
          aimed_arrival_time: '2023-02-07T08:14:18+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:21:37+00:00',
          aimed_departure_time: '2023-02-07T08:21:37+00:00',
          expected_arrival_time: '2023-02-07T08:21:18+00:00',
          aimed_arrival_time: '2023-02-07T08:21:18+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:29:37+00:00',
          aimed_departure_time: '2023-02-07T08:29:37+00:00',
          expected_arrival_time: '2023-02-07T08:29:18+00:00',
          aimed_arrival_time: '2023-02-07T08:29:18+00:00',
          occupancy: OccupancyEnum.Full,
        },
      ],
    },
    {
      id: 51862,
      backward_id: 51861,
      line_id: 1,
      order: 26,
      latitude: 60.29195,
      longitude: 5.244083,
      name: 'Kokstadflaten',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [
        {
          expected_departure_time: '2023-02-07T08:07:46+00:00',
          aimed_departure_time: '2023-02-07T08:07:05+00:00',
          expected_arrival_time: '2023-02-07T08:07:31+00:00',
          aimed_arrival_time: '2023-02-07T08:06:50+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:20+00:00',
          aimed_departure_time: '2023-02-07T08:12:05+00:00',
          expected_arrival_time: '2023-02-07T08:12:05+00:00',
          aimed_arrival_time: '2023-02-07T08:11:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:17:05+00:00',
          aimed_departure_time: '2023-02-07T08:17:05+00:00',
          expected_arrival_time: '2023-02-07T08:16:50+00:00',
          aimed_arrival_time: '2023-02-07T08:16:50+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:22:05+00:00',
          aimed_departure_time: '2023-02-07T08:22:05+00:00',
          expected_arrival_time: '2023-02-07T08:21:50+00:00',
          aimed_arrival_time: '2023-02-07T08:21:50+00:00',
          occupancy: OccupancyEnum.Full,
        },
        {
          expected_departure_time: '2023-02-07T08:27:05+00:00',
          aimed_departure_time: '2023-02-07T08:27:05+00:00',
          expected_arrival_time: '2023-02-07T08:26:50+00:00',
          aimed_arrival_time: '2023-02-07T08:26:50+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
      ],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:04:46+00:00',
          aimed_departure_time: '2023-02-07T08:04:46+00:00',
          expected_arrival_time: '2023-02-07T08:04:31+00:00',
          aimed_arrival_time: '2023-02-07T08:04:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:12:46+00:00',
          aimed_departure_time: '2023-02-07T08:12:46+00:00',
          expected_arrival_time: '2023-02-07T08:12:31+00:00',
          aimed_arrival_time: '2023-02-07T08:12:31+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:19:46+00:00',
          aimed_departure_time: '2023-02-07T08:19:46+00:00',
          expected_arrival_time: '2023-02-07T08:19:31+00:00',
          aimed_arrival_time: '2023-02-07T08:19:31+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:27:46+00:00',
          aimed_departure_time: '2023-02-07T08:27:46+00:00',
          expected_arrival_time: '2023-02-07T08:27:31+00:00',
          aimed_arrival_time: '2023-02-07T08:27:31+00:00',
          occupancy: OccupancyEnum.Full,
        },
      ],
    },
    {
      id: 51855,
      backward_id: 51856,
      line_id: 1,
      order: 27,
      latitude: 60.28815,
      longitude: 5.23213,
      name: 'Bergen lufthavn',
      response_timestamp: '2023-02-07T08:01:55.119+00:00',
      estimated_calls_forward: [],
      estimated_calls_backward: [
        {
          expected_departure_time: '2023-02-07T08:03:20+00:00',
          aimed_departure_time: '2023-02-07T08:03:00+00:00',
          expected_arrival_time: '2023-02-07T08:03:00+00:00',
          aimed_arrival_time: '2023-02-07T08:02:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:11:20+00:00',
          aimed_departure_time: '2023-02-07T08:11:00+00:00',
          expected_arrival_time: '2023-02-07T08:11:00+00:00',
          aimed_arrival_time: '2023-02-07T08:10:40+00:00',
          occupancy: OccupancyEnum.SeatsAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:18:20+00:00',
          aimed_departure_time: '2023-02-07T08:18:00+00:00',
          expected_arrival_time: '2023-02-07T08:18:00+00:00',
          aimed_arrival_time: '2023-02-07T08:17:40+00:00',
          occupancy: OccupancyEnum.StandingAvailable,
        },
        {
          expected_departure_time: '2023-02-07T08:26:20+00:00',
          aimed_departure_time: '2023-02-07T08:26:00+00:00',
          expected_arrival_time: '2023-02-07T08:26:00+00:00',
          aimed_arrival_time: '2023-02-07T08:25:40+00:00',
          occupancy: OccupancyEnum.Full,
        },
      ],
    },
  ],
}

const API_MOCK_DATA_ALL_LINES: ILine[] = [
  {
    id: 1,
    name: 'Byparken - Bergen Lufthavn',
    destination_stop: 8027,
    stops: [
      {
        id: 53130,
        backward_id: 53131,
        line_id: 1,
        order: 1,
        latitude: 60.39198,
        longitude: 5.325511,
        name: 'Byparken',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:58:13+00:00',
            aimed_departure_time: '2023-02-08T09:53:00+00:00',
            expected_arrival_time: '2023-02-08T09:53:00+00:00',
            aimed_arrival_time: '2023-02-08T09:47:47+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:39+00:00',
            aimed_departure_time: '2023-02-08T10:00:00+00:00',
            expected_arrival_time: '2023-02-08T10:00:00+00:00',
            aimed_arrival_time: '2023-02-08T09:55:21+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:13:39+00:00',
            aimed_departure_time: '2023-02-08T10:08:00+00:00',
            expected_arrival_time: '2023-02-08T10:08:00+00:00',
            aimed_arrival_time: '2023-02-08T10:02:21+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [],
      },
      {
        id: 53136,
        backward_id: 53135,
        line_id: 1,
        order: 2,
        latitude: 60.39007,
        longitude: 5.33279,
        name: 'Nonneseter',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:47:07+00:00',
            aimed_departure_time: '2023-02-08T09:46:33+00:00',
            expected_arrival_time: '2023-02-08T09:46:40+00:00',
            aimed_arrival_time: '2023-02-08T09:46:06+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:54:33+00:00',
            aimed_departure_time: '2023-02-08T09:54:33+00:00',
            expected_arrival_time: '2023-02-08T09:54:06+00:00',
            aimed_arrival_time: '2023-02-08T09:54:06+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:01:33+00:00',
            aimed_departure_time: '2023-02-08T10:01:33+00:00',
            expected_arrival_time: '2023-02-08T10:01:06+00:00',
            aimed_arrival_time: '2023-02-08T10:01:06+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:09:33+00:00',
            aimed_departure_time: '2023-02-08T10:09:33+00:00',
            expected_arrival_time: '2023-02-08T10:09:06+00:00',
            aimed_arrival_time: '2023-02-08T10:09:06+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:46:11+00:00',
            aimed_departure_time: '2023-02-08T09:46:42+00:00',
            expected_arrival_time: '2023-02-08T09:45:40+00:00',
            aimed_arrival_time: '2023-02-08T09:46:11+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:54:58+00:00',
            aimed_departure_time: '2023-02-08T09:54:16+00:00',
            expected_arrival_time: '2023-02-08T09:54:53+00:00',
            aimed_arrival_time: '2023-02-08T09:54:11+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:01:16+00:00',
            aimed_departure_time: '2023-02-08T10:01:16+00:00',
            expected_arrival_time: '2023-02-08T10:01:11+00:00',
            aimed_arrival_time: '2023-02-08T10:01:11+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:45+00:00',
            aimed_departure_time: '2023-02-08T10:09:45+00:00',
            expected_arrival_time: '2023-02-08T10:09:14+00:00',
            aimed_arrival_time: '2023-02-08T10:09:14+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 53141,
        backward_id: 53140,
        line_id: 1,
        order: 3,
        latitude: 60.38814,
        longitude: 5.33417,
        name: 'Bergen busstasjon',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:48:41+00:00',
            aimed_departure_time: '2023-02-08T09:48:07+00:00',
            expected_arrival_time: '2023-02-08T09:48:14+00:00',
            aimed_arrival_time: '2023-02-08T09:47:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:07+00:00',
            aimed_departure_time: '2023-02-08T09:56:07+00:00',
            expected_arrival_time: '2023-02-08T09:55:40+00:00',
            aimed_arrival_time: '2023-02-08T09:55:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:07+00:00',
            aimed_departure_time: '2023-02-08T10:03:07+00:00',
            expected_arrival_time: '2023-02-08T10:02:40+00:00',
            aimed_arrival_time: '2023-02-08T10:02:40+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:11:07+00:00',
            aimed_departure_time: '2023-02-08T10:11:07+00:00',
            expected_arrival_time: '2023-02-08T10:10:40+00:00',
            aimed_arrival_time: '2023-02-08T10:10:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:53:52+00:00',
            aimed_departure_time: '2023-02-08T09:53:10+00:00',
            expected_arrival_time: '2023-02-08T09:52:26+00:00',
            aimed_arrival_time: '2023-02-08T09:51:44+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:00:10+00:00',
            aimed_departure_time: '2023-02-08T10:00:10+00:00',
            expected_arrival_time: '2023-02-08T09:58:44+00:00',
            aimed_arrival_time: '2023-02-08T09:58:44+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:13+00:00',
            aimed_departure_time: '2023-02-08T10:08:13+00:00',
            expected_arrival_time: '2023-02-08T10:07:44+00:00',
            aimed_arrival_time: '2023-02-08T10:07:44+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:38+00:00',
            aimed_departure_time: '2023-02-08T10:14:13+00:00',
            expected_arrival_time: '2023-02-08T10:14:09+00:00',
            aimed_arrival_time: '2023-02-08T10:13:44+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 53144,
        backward_id: 53145,
        line_id: 1,
        order: 4,
        latitude: 60.38453,
        longitude: 5.33262,
        name: 'Nygård',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:50:39+00:00',
            aimed_departure_time: '2023-02-08T09:50:05+00:00',
            expected_arrival_time: '2023-02-08T09:50:14+00:00',
            aimed_arrival_time: '2023-02-08T09:49:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:05+00:00',
            aimed_departure_time: '2023-02-08T09:58:05+00:00',
            expected_arrival_time: '2023-02-08T09:57:40+00:00',
            aimed_arrival_time: '2023-02-08T09:57:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:05:05+00:00',
            aimed_departure_time: '2023-02-08T10:05:05+00:00',
            expected_arrival_time: '2023-02-08T10:04:40+00:00',
            aimed_arrival_time: '2023-02-08T10:04:40+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:13:05+00:00',
            aimed_departure_time: '2023-02-08T10:13:05+00:00',
            expected_arrival_time: '2023-02-08T10:12:40+00:00',
            aimed_arrival_time: '2023-02-08T10:12:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:50:56+00:00',
            aimed_departure_time: '2023-02-08T09:50:14+00:00',
            expected_arrival_time: '2023-02-08T09:50:32+00:00',
            aimed_arrival_time: '2023-02-08T09:49:50+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T09:57:14+00:00',
            aimed_departure_time: '2023-02-08T09:57:14+00:00',
            expected_arrival_time: '2023-02-08T09:56:50+00:00',
            aimed_arrival_time: '2023-02-08T09:56:50+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:14+00:00',
            aimed_departure_time: '2023-02-08T10:06:14+00:00',
            expected_arrival_time: '2023-02-08T10:05:50+00:00',
            aimed_arrival_time: '2023-02-08T10:05:50+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:39+00:00',
            aimed_departure_time: '2023-02-08T10:12:14+00:00',
            expected_arrival_time: '2023-02-08T10:12:15+00:00',
            aimed_arrival_time: '2023-02-08T10:11:50+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 53150,
        backward_id: 53151,
        line_id: 1,
        order: 5,
        latitude: 60.38162,
        longitude: 5.332997,
        name: 'Florida',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:51:57+00:00',
            aimed_departure_time: '2023-02-08T09:51:23+00:00',
            expected_arrival_time: '2023-02-08T09:51:36+00:00',
            aimed_arrival_time: '2023-02-08T09:51:02+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:59:23+00:00',
            aimed_departure_time: '2023-02-08T09:59:23+00:00',
            expected_arrival_time: '2023-02-08T09:59:02+00:00',
            aimed_arrival_time: '2023-02-08T09:59:02+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:23+00:00',
            aimed_departure_time: '2023-02-08T10:06:23+00:00',
            expected_arrival_time: '2023-02-08T10:06:02+00:00',
            aimed_arrival_time: '2023-02-08T10:06:02+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:14:23+00:00',
            aimed_departure_time: '2023-02-08T10:14:23+00:00',
            expected_arrival_time: '2023-02-08T10:14:02+00:00',
            aimed_arrival_time: '2023-02-08T10:14:02+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:49:35+00:00',
            aimed_departure_time: '2023-02-08T09:48:53+00:00',
            expected_arrival_time: '2023-02-08T09:49:14+00:00',
            aimed_arrival_time: '2023-02-08T09:48:32+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T09:55:53+00:00',
            aimed_departure_time: '2023-02-08T09:55:53+00:00',
            expected_arrival_time: '2023-02-08T09:55:32+00:00',
            aimed_arrival_time: '2023-02-08T09:55:32+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:53+00:00',
            aimed_departure_time: '2023-02-08T10:04:53+00:00',
            expected_arrival_time: '2023-02-08T10:04:32+00:00',
            aimed_arrival_time: '2023-02-08T10:04:32+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:18+00:00',
            aimed_departure_time: '2023-02-08T10:10:53+00:00',
            expected_arrival_time: '2023-02-08T10:10:57+00:00',
            aimed_arrival_time: '2023-02-08T10:10:32+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 54020,
        backward_id: 54019,
        line_id: 1,
        order: 6,
        latitude: 60.37562,
        longitude: 5.3397,
        name: 'Danmarks plass',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:46:28+00:00',
            aimed_departure_time: '2023-02-08T09:46:24+00:00',
            expected_arrival_time: '2023-02-08T09:45:59+00:00',
            aimed_arrival_time: '2023-02-08T09:45:55+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:53:58+00:00',
            aimed_departure_time: '2023-02-08T09:53:24+00:00',
            expected_arrival_time: '2023-02-08T09:53:29+00:00',
            aimed_arrival_time: '2023-02-08T09:52:55+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:01:24+00:00',
            aimed_departure_time: '2023-02-08T10:01:24+00:00',
            expected_arrival_time: '2023-02-08T10:00:55+00:00',
            aimed_arrival_time: '2023-02-08T10:00:55+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:24+00:00',
            aimed_departure_time: '2023-02-08T10:08:24+00:00',
            expected_arrival_time: '2023-02-08T10:07:55+00:00',
            aimed_arrival_time: '2023-02-08T10:07:55+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:47:29+00:00',
            aimed_departure_time: '2023-02-08T09:46:54+00:00',
            expected_arrival_time: '2023-02-08T09:47:00+00:00',
            aimed_arrival_time: '2023-02-08T09:46:25+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T09:53:54+00:00',
            aimed_departure_time: '2023-02-08T09:53:54+00:00',
            expected_arrival_time: '2023-02-08T09:53:25+00:00',
            aimed_arrival_time: '2023-02-08T09:53:25+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:54+00:00',
            aimed_departure_time: '2023-02-08T10:02:54+00:00',
            expected_arrival_time: '2023-02-08T10:02:25+00:00',
            aimed_arrival_time: '2023-02-08T10:02:25+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:19+00:00',
            aimed_departure_time: '2023-02-08T10:08:54+00:00',
            expected_arrival_time: '2023-02-08T10:08:50+00:00',
            aimed_arrival_time: '2023-02-08T10:08:25+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 54023,
        backward_id: 54022,
        line_id: 1,
        order: 7,
        latitude: 60.37065,
        longitude: 5.348263,
        name: 'Kronstad',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:48:33+00:00',
            aimed_departure_time: '2023-02-08T09:48:18+00:00',
            expected_arrival_time: '2023-02-08T09:48:06+00:00',
            aimed_arrival_time: '2023-02-08T09:47:51+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:52+00:00',
            aimed_departure_time: '2023-02-08T09:55:18+00:00',
            expected_arrival_time: '2023-02-08T09:55:25+00:00',
            aimed_arrival_time: '2023-02-08T09:54:51+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:18+00:00',
            aimed_departure_time: '2023-02-08T10:03:18+00:00',
            expected_arrival_time: '2023-02-08T10:02:51+00:00',
            aimed_arrival_time: '2023-02-08T10:02:51+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:18+00:00',
            aimed_departure_time: '2023-02-08T10:10:18+00:00',
            expected_arrival_time: '2023-02-08T10:09:51+00:00',
            aimed_arrival_time: '2023-02-08T10:09:51+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:51:51+00:00',
            aimed_departure_time: '2023-02-08T09:51:51+00:00',
            expected_arrival_time: '2023-02-08T09:51:26+00:00',
            aimed_arrival_time: '2023-02-08T09:51:26+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:51+00:00',
            aimed_departure_time: '2023-02-08T10:00:51+00:00',
            expected_arrival_time: '2023-02-08T10:00:26+00:00',
            aimed_arrival_time: '2023-02-08T10:00:26+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:16+00:00',
            aimed_departure_time: '2023-02-08T10:06:51+00:00',
            expected_arrival_time: '2023-02-08T10:06:51+00:00',
            aimed_arrival_time: '2023-02-08T10:06:26+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:30+00:00',
            aimed_departure_time: '2023-02-08T10:14:51+00:00',
            expected_arrival_time: '2023-02-08T10:15:05+00:00',
            aimed_arrival_time: '2023-02-08T10:14:26+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 54028,
        backward_id: 54027,
        line_id: 1,
        order: 8,
        latitude: 60.36548,
        longitude: 5.35203,
        name: 'Brann stadion',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:49:53+00:00',
            aimed_departure_time: '2023-02-08T09:49:38+00:00',
            expected_arrival_time: '2023-02-08T09:49:31+00:00',
            aimed_arrival_time: '2023-02-08T09:49:16+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:12+00:00',
            aimed_departure_time: '2023-02-08T09:56:38+00:00',
            expected_arrival_time: '2023-02-08T09:56:50+00:00',
            aimed_arrival_time: '2023-02-08T09:56:16+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:38+00:00',
            aimed_departure_time: '2023-02-08T10:04:38+00:00',
            expected_arrival_time: '2023-02-08T10:04:16+00:00',
            aimed_arrival_time: '2023-02-08T10:04:16+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:38+00:00',
            aimed_departure_time: '2023-02-08T10:11:38+00:00',
            expected_arrival_time: '2023-02-08T10:11:16+00:00',
            aimed_arrival_time: '2023-02-08T10:11:16+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:50:19+00:00',
            aimed_departure_time: '2023-02-08T09:50:19+00:00',
            expected_arrival_time: '2023-02-08T09:49:57+00:00',
            aimed_arrival_time: '2023-02-08T09:49:57+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:59:19+00:00',
            aimed_departure_time: '2023-02-08T09:59:19+00:00',
            expected_arrival_time: '2023-02-08T09:58:57+00:00',
            aimed_arrival_time: '2023-02-08T09:58:57+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:05:44+00:00',
            aimed_departure_time: '2023-02-08T10:05:19+00:00',
            expected_arrival_time: '2023-02-08T10:05:22+00:00',
            aimed_arrival_time: '2023-02-08T10:04:57+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:58+00:00',
            aimed_departure_time: '2023-02-08T10:13:19+00:00',
            expected_arrival_time: '2023-02-08T10:13:36+00:00',
            aimed_arrival_time: '2023-02-08T10:12:57+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 54031,
        backward_id: 54032,
        line_id: 1,
        order: 9,
        latitude: 60.36055,
        longitude: 5.35117,
        name: 'Wergeland',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:51:29+00:00',
            aimed_departure_time: '2023-02-08T09:51:14+00:00',
            expected_arrival_time: '2023-02-08T09:51:03+00:00',
            aimed_arrival_time: '2023-02-08T09:50:48+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:48+00:00',
            aimed_departure_time: '2023-02-08T09:58:14+00:00',
            expected_arrival_time: '2023-02-08T09:58:22+00:00',
            aimed_arrival_time: '2023-02-08T09:57:48+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:14+00:00',
            aimed_departure_time: '2023-02-08T10:06:14+00:00',
            expected_arrival_time: '2023-02-08T10:05:48+00:00',
            aimed_arrival_time: '2023-02-08T10:05:48+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:14+00:00',
            aimed_departure_time: '2023-02-08T10:13:14+00:00',
            expected_arrival_time: '2023-02-08T10:12:48+00:00',
            aimed_arrival_time: '2023-02-08T10:12:48+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:48:59+00:00',
            aimed_departure_time: '2023-02-08T09:48:59+00:00',
            expected_arrival_time: '2023-02-08T09:48:35+00:00',
            aimed_arrival_time: '2023-02-08T09:48:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:59+00:00',
            aimed_departure_time: '2023-02-08T09:57:59+00:00',
            expected_arrival_time: '2023-02-08T09:57:35+00:00',
            aimed_arrival_time: '2023-02-08T09:57:35+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:24+00:00',
            aimed_departure_time: '2023-02-08T10:03:59+00:00',
            expected_arrival_time: '2023-02-08T10:04:00+00:00',
            aimed_arrival_time: '2023-02-08T10:03:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:38+00:00',
            aimed_departure_time: '2023-02-08T10:11:59+00:00',
            expected_arrival_time: '2023-02-08T10:12:14+00:00',
            aimed_arrival_time: '2023-02-08T10:11:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 54038,
        backward_id: 54037,
        line_id: 1,
        order: 10,
        latitude: 60.356403,
        longitude: 5.358693,
        name: 'Sletten',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:53:32+00:00',
            aimed_departure_time: '2023-02-08T09:53:17+00:00',
            expected_arrival_time: '2023-02-08T09:53:02+00:00',
            aimed_arrival_time: '2023-02-08T09:52:47+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:51+00:00',
            aimed_departure_time: '2023-02-08T10:00:17+00:00',
            expected_arrival_time: '2023-02-08T10:00:21+00:00',
            aimed_arrival_time: '2023-02-08T09:59:47+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:17+00:00',
            aimed_departure_time: '2023-02-08T10:08:17+00:00',
            expected_arrival_time: '2023-02-08T10:07:47+00:00',
            aimed_arrival_time: '2023-02-08T10:07:47+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:17+00:00',
            aimed_departure_time: '2023-02-08T10:15:17+00:00',
            expected_arrival_time: '2023-02-08T10:14:47+00:00',
            aimed_arrival_time: '2023-02-08T10:14:47+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:46:44+00:00',
            aimed_departure_time: '2023-02-08T09:47:00+00:00',
            expected_arrival_time: '2023-02-08T09:46:17+00:00',
            aimed_arrival_time: '2023-02-08T09:46:33+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:00+00:00',
            aimed_departure_time: '2023-02-08T09:56:00+00:00',
            expected_arrival_time: '2023-02-08T09:55:33+00:00',
            aimed_arrival_time: '2023-02-08T09:55:33+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:25+00:00',
            aimed_departure_time: '2023-02-08T10:02:00+00:00',
            expected_arrival_time: '2023-02-08T10:01:58+00:00',
            aimed_arrival_time: '2023-02-08T10:01:33+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:39+00:00',
            aimed_departure_time: '2023-02-08T10:10:00+00:00',
            expected_arrival_time: '2023-02-08T10:10:12+00:00',
            aimed_arrival_time: '2023-02-08T10:09:33+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 54041,
        backward_id: 54043,
        line_id: 1,
        order: 11,
        latitude: 60.35032,
        longitude: 5.36004,
        name: 'Slettebakken',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:48:59+00:00',
            aimed_departure_time: '2023-02-08T09:46:48+00:00',
            expected_arrival_time: '2023-02-08T09:48:38+00:00',
            aimed_arrival_time: '2023-02-08T09:46:27+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:03+00:00',
            aimed_departure_time: '2023-02-08T09:54:48+00:00',
            expected_arrival_time: '2023-02-08T09:54:42+00:00',
            aimed_arrival_time: '2023-02-08T09:54:27+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:22+00:00',
            aimed_departure_time: '2023-02-08T10:01:48+00:00',
            expected_arrival_time: '2023-02-08T10:02:01+00:00',
            aimed_arrival_time: '2023-02-08T10:01:27+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:48+00:00',
            aimed_departure_time: '2023-02-08T10:09:48+00:00',
            expected_arrival_time: '2023-02-08T10:09:27+00:00',
            aimed_arrival_time: '2023-02-08T10:09:27+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:54:17+00:00',
            aimed_departure_time: '2023-02-08T09:54:17+00:00',
            expected_arrival_time: '2023-02-08T09:53:57+00:00',
            aimed_arrival_time: '2023-02-08T09:53:57+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:42+00:00',
            aimed_departure_time: '2023-02-08T10:00:17+00:00',
            expected_arrival_time: '2023-02-08T10:00:22+00:00',
            aimed_arrival_time: '2023-02-08T09:59:57+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:56+00:00',
            aimed_departure_time: '2023-02-08T10:08:17+00:00',
            expected_arrival_time: '2023-02-08T10:08:36+00:00',
            aimed_arrival_time: '2023-02-08T10:07:57+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:17+00:00',
            aimed_departure_time: '2023-02-08T10:15:17+00:00',
            expected_arrival_time: '2023-02-08T10:14:57+00:00',
            aimed_arrival_time: '2023-02-08T10:14:57+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 54049,
        backward_id: 54050,
        line_id: 1,
        order: 12,
        latitude: 60.34591,
        longitude: 5.35291,
        name: 'Fantoft',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:47:27+00:00',
            aimed_departure_time: '2023-02-08T09:46:31+00:00',
            expected_arrival_time: '2023-02-08T09:47:00+00:00',
            aimed_arrival_time: '2023-02-08T09:46:04+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:50:53+00:00',
            aimed_departure_time: '2023-02-08T09:48:42+00:00',
            expected_arrival_time: '2023-02-08T09:50:26+00:00',
            aimed_arrival_time: '2023-02-08T09:48:15+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:57+00:00',
            aimed_departure_time: '2023-02-08T09:56:42+00:00',
            expected_arrival_time: '2023-02-08T09:56:30+00:00',
            aimed_arrival_time: '2023-02-08T09:56:15+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:16+00:00',
            aimed_departure_time: '2023-02-08T10:03:42+00:00',
            expected_arrival_time: '2023-02-08T10:03:49+00:00',
            aimed_arrival_time: '2023-02-08T10:03:15+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:42+00:00',
            aimed_departure_time: '2023-02-08T10:11:42+00:00',
            expected_arrival_time: '2023-02-08T10:11:15+00:00',
            aimed_arrival_time: '2023-02-08T10:11:15+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:52:24+00:00',
            aimed_departure_time: '2023-02-08T09:52:24+00:00',
            expected_arrival_time: '2023-02-08T09:51:59+00:00',
            aimed_arrival_time: '2023-02-08T09:51:59+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:49+00:00',
            aimed_departure_time: '2023-02-08T09:58:24+00:00',
            expected_arrival_time: '2023-02-08T09:58:24+00:00',
            aimed_arrival_time: '2023-02-08T09:57:59+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:03+00:00',
            aimed_departure_time: '2023-02-08T10:06:24+00:00',
            expected_arrival_time: '2023-02-08T10:06:38+00:00',
            aimed_arrival_time: '2023-02-08T10:05:59+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:24+00:00',
            aimed_departure_time: '2023-02-08T10:13:24+00:00',
            expected_arrival_time: '2023-02-08T10:12:59+00:00',
            aimed_arrival_time: '2023-02-08T10:12:59+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 50347,
        backward_id: 50345,
        line_id: 1,
        order: 13,
        latitude: 60.33627,
        longitude: 5.34696,
        name: 'Paradis',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:49:17+00:00',
            aimed_departure_time: '2023-02-08T09:48:22+00:00',
            expected_arrival_time: '2023-02-08T09:48:56+00:00',
            aimed_arrival_time: '2023-02-08T09:48:01+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:52:44+00:00',
            aimed_departure_time: '2023-02-08T09:50:33+00:00',
            expected_arrival_time: '2023-02-08T09:52:23+00:00',
            aimed_arrival_time: '2023-02-08T09:50:12+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:48+00:00',
            aimed_departure_time: '2023-02-08T09:58:33+00:00',
            expected_arrival_time: '2023-02-08T09:58:27+00:00',
            aimed_arrival_time: '2023-02-08T09:58:12+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:07+00:00',
            aimed_departure_time: '2023-02-08T10:05:33+00:00',
            expected_arrival_time: '2023-02-08T10:05:46+00:00',
            aimed_arrival_time: '2023-02-08T10:05:12+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:33+00:00',
            aimed_departure_time: '2023-02-08T10:13:33+00:00',
            expected_arrival_time: '2023-02-08T10:13:12+00:00',
            aimed_arrival_time: '2023-02-08T10:13:12+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:50:33+00:00',
            aimed_departure_time: '2023-02-08T09:50:33+00:00',
            expected_arrival_time: '2023-02-08T09:50:11+00:00',
            aimed_arrival_time: '2023-02-08T09:50:11+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:58+00:00',
            aimed_departure_time: '2023-02-08T09:56:33+00:00',
            expected_arrival_time: '2023-02-08T09:56:36+00:00',
            aimed_arrival_time: '2023-02-08T09:56:11+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:05:12+00:00',
            aimed_departure_time: '2023-02-08T10:04:33+00:00',
            expected_arrival_time: '2023-02-08T10:04:50+00:00',
            aimed_arrival_time: '2023-02-08T10:04:11+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:33+00:00',
            aimed_departure_time: '2023-02-08T10:11:33+00:00',
            expected_arrival_time: '2023-02-08T10:11:11+00:00',
            aimed_arrival_time: '2023-02-08T10:11:11+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 51253,
        backward_id: 51252,
        line_id: 1,
        order: 14,
        latitude: 60.32626,
        longitude: 5.346303,
        name: 'Hop',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:51:13+00:00',
            aimed_departure_time: '2023-02-08T09:50:18+00:00',
            expected_arrival_time: '2023-02-08T09:50:52+00:00',
            aimed_arrival_time: '2023-02-08T09:49:57+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:54:40+00:00',
            aimed_departure_time: '2023-02-08T09:52:29+00:00',
            expected_arrival_time: '2023-02-08T09:54:19+00:00',
            aimed_arrival_time: '2023-02-08T09:52:08+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:44+00:00',
            aimed_departure_time: '2023-02-08T10:00:29+00:00',
            expected_arrival_time: '2023-02-08T10:00:23+00:00',
            aimed_arrival_time: '2023-02-08T10:00:08+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:03+00:00',
            aimed_departure_time: '2023-02-08T10:07:29+00:00',
            expected_arrival_time: '2023-02-08T10:07:42+00:00',
            aimed_arrival_time: '2023-02-08T10:07:08+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:29+00:00',
            aimed_departure_time: '2023-02-08T10:15:29+00:00',
            expected_arrival_time: '2023-02-08T10:15:08+00:00',
            aimed_arrival_time: '2023-02-08T10:15:08+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:48:36+00:00',
            aimed_departure_time: '2023-02-08T09:48:36+00:00',
            expected_arrival_time: '2023-02-08T09:48:16+00:00',
            aimed_arrival_time: '2023-02-08T09:48:16+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:01+00:00',
            aimed_departure_time: '2023-02-08T09:54:36+00:00',
            expected_arrival_time: '2023-02-08T09:54:41+00:00',
            aimed_arrival_time: '2023-02-08T09:54:16+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:15+00:00',
            aimed_departure_time: '2023-02-08T10:02:36+00:00',
            expected_arrival_time: '2023-02-08T10:02:55+00:00',
            aimed_arrival_time: '2023-02-08T10:02:16+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:36+00:00',
            aimed_departure_time: '2023-02-08T10:09:36+00:00',
            expected_arrival_time: '2023-02-08T10:09:16+00:00',
            aimed_arrival_time: '2023-02-08T10:09:16+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 51261,
        backward_id: 51260,
        line_id: 1,
        order: 15,
        latitude: 60.31967,
        longitude: 5.352712,
        name: 'Nesttun terminal',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:52:58+00:00',
            aimed_departure_time: '2023-02-08T09:52:03+00:00',
            expected_arrival_time: '2023-02-08T09:52:30+00:00',
            aimed_arrival_time: '2023-02-08T09:51:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:25+00:00',
            aimed_departure_time: '2023-02-08T09:54:14+00:00',
            expected_arrival_time: '2023-02-08T09:55:57+00:00',
            aimed_arrival_time: '2023-02-08T09:53:46+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:29+00:00',
            aimed_departure_time: '2023-02-08T10:02:14+00:00',
            expected_arrival_time: '2023-02-08T10:02:01+00:00',
            aimed_arrival_time: '2023-02-08T10:01:46+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:48+00:00',
            aimed_departure_time: '2023-02-08T10:09:14+00:00',
            expected_arrival_time: '2023-02-08T10:09:20+00:00',
            aimed_arrival_time: '2023-02-08T10:08:46+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:46:33+00:00',
            aimed_departure_time: '2023-02-08T09:46:51+00:00',
            expected_arrival_time: '2023-02-08T09:46:05+00:00',
            aimed_arrival_time: '2023-02-08T09:46:23+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:53:16+00:00',
            aimed_departure_time: '2023-02-08T09:52:51+00:00',
            expected_arrival_time: '2023-02-08T09:52:48+00:00',
            aimed_arrival_time: '2023-02-08T09:52:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:01:30+00:00',
            aimed_departure_time: '2023-02-08T10:00:51+00:00',
            expected_arrival_time: '2023-02-08T10:01:02+00:00',
            aimed_arrival_time: '2023-02-08T10:00:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:51+00:00',
            aimed_departure_time: '2023-02-08T10:07:51+00:00',
            expected_arrival_time: '2023-02-08T10:07:23+00:00',
            aimed_arrival_time: '2023-02-08T10:07:23+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 51255,
        backward_id: 51256,
        line_id: 1,
        order: 16,
        latitude: 60.31743,
        longitude: 5.353706,
        name: 'Nesttun sentrum',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:54:26+00:00',
            aimed_departure_time: '2023-02-08T09:53:31+00:00',
            expected_arrival_time: '2023-02-08T09:54:03+00:00',
            aimed_arrival_time: '2023-02-08T09:53:08+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:53+00:00',
            aimed_departure_time: '2023-02-08T09:55:42+00:00',
            expected_arrival_time: '2023-02-08T09:57:30+00:00',
            aimed_arrival_time: '2023-02-08T09:55:19+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:57+00:00',
            aimed_departure_time: '2023-02-08T10:03:42+00:00',
            expected_arrival_time: '2023-02-08T10:03:34+00:00',
            aimed_arrival_time: '2023-02-08T10:03:19+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:16+00:00',
            aimed_departure_time: '2023-02-08T10:10:42+00:00',
            expected_arrival_time: '2023-02-08T10:10:53+00:00',
            aimed_arrival_time: '2023-02-08T10:10:19+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:51:44+00:00',
            aimed_departure_time: '2023-02-08T09:51:19+00:00',
            expected_arrival_time: '2023-02-08T09:51:21+00:00',
            aimed_arrival_time: '2023-02-08T09:50:56+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:59:58+00:00',
            aimed_departure_time: '2023-02-08T09:59:19+00:00',
            expected_arrival_time: '2023-02-08T09:59:35+00:00',
            aimed_arrival_time: '2023-02-08T09:58:56+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:19+00:00',
            aimed_departure_time: '2023-02-08T10:06:19+00:00',
            expected_arrival_time: '2023-02-08T10:05:56+00:00',
            aimed_arrival_time: '2023-02-08T10:05:56+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:19+00:00',
            aimed_departure_time: '2023-02-08T10:15:19+00:00',
            expected_arrival_time: '2023-02-08T10:14:56+00:00',
            aimed_arrival_time: '2023-02-08T10:14:56+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51267,
        backward_id: 51266,
        line_id: 1,
        order: 17,
        latitude: 60.31129,
        longitude: 5.35299,
        name: 'Skjoldskiftet',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:55:54+00:00',
            aimed_departure_time: '2023-02-08T09:54:59+00:00',
            expected_arrival_time: '2023-02-08T09:55:32+00:00',
            aimed_arrival_time: '2023-02-08T09:54:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:59:21+00:00',
            aimed_departure_time: '2023-02-08T09:57:10+00:00',
            expected_arrival_time: '2023-02-08T09:58:59+00:00',
            aimed_arrival_time: '2023-02-08T09:56:48+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:05:25+00:00',
            aimed_departure_time: '2023-02-08T10:05:10+00:00',
            expected_arrival_time: '2023-02-08T10:05:03+00:00',
            aimed_arrival_time: '2023-02-08T10:04:48+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:44+00:00',
            aimed_departure_time: '2023-02-08T10:12:10+00:00',
            expected_arrival_time: '2023-02-08T10:12:22+00:00',
            aimed_arrival_time: '2023-02-08T10:11:48+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:50:10+00:00',
            aimed_departure_time: '2023-02-08T09:49:45+00:00',
            expected_arrival_time: '2023-02-08T09:49:48+00:00',
            aimed_arrival_time: '2023-02-08T09:49:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:24+00:00',
            aimed_departure_time: '2023-02-08T09:57:45+00:00',
            expected_arrival_time: '2023-02-08T09:58:02+00:00',
            aimed_arrival_time: '2023-02-08T09:57:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:45+00:00',
            aimed_departure_time: '2023-02-08T10:04:45+00:00',
            expected_arrival_time: '2023-02-08T10:04:23+00:00',
            aimed_arrival_time: '2023-02-08T10:04:23+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:45+00:00',
            aimed_departure_time: '2023-02-08T10:13:45+00:00',
            expected_arrival_time: '2023-02-08T10:13:23+00:00',
            aimed_arrival_time: '2023-02-08T10:13:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51272,
        backward_id: 51271,
        line_id: 1,
        order: 18,
        latitude: 60.30951,
        longitude: 5.347668,
        name: 'Mårdalen',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:46:30+00:00',
            aimed_departure_time: '2023-02-08T09:46:25+00:00',
            expected_arrival_time: '2023-02-08T09:46:10+00:00',
            aimed_arrival_time: '2023-02-08T09:46:05+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:05+00:00',
            aimed_departure_time: '2023-02-08T09:56:10+00:00',
            expected_arrival_time: '2023-02-08T09:56:45+00:00',
            aimed_arrival_time: '2023-02-08T09:55:50+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:32+00:00',
            aimed_departure_time: '2023-02-08T09:58:21+00:00',
            expected_arrival_time: '2023-02-08T10:00:12+00:00',
            aimed_arrival_time: '2023-02-08T09:58:01+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:36+00:00',
            aimed_departure_time: '2023-02-08T10:06:21+00:00',
            expected_arrival_time: '2023-02-08T10:06:16+00:00',
            aimed_arrival_time: '2023-02-08T10:06:01+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:55+00:00',
            aimed_departure_time: '2023-02-08T10:13:21+00:00',
            expected_arrival_time: '2023-02-08T10:13:35+00:00',
            aimed_arrival_time: '2023-02-08T10:13:01+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:48:53+00:00',
            aimed_departure_time: '2023-02-08T09:48:28+00:00',
            expected_arrival_time: '2023-02-08T09:48:33+00:00',
            aimed_arrival_time: '2023-02-08T09:48:08+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:07+00:00',
            aimed_departure_time: '2023-02-08T09:56:28+00:00',
            expected_arrival_time: '2023-02-08T09:56:47+00:00',
            aimed_arrival_time: '2023-02-08T09:56:08+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:28+00:00',
            aimed_departure_time: '2023-02-08T10:03:28+00:00',
            expected_arrival_time: '2023-02-08T10:03:08+00:00',
            aimed_arrival_time: '2023-02-08T10:03:08+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:28+00:00',
            aimed_departure_time: '2023-02-08T10:12:28+00:00',
            expected_arrival_time: '2023-02-08T10:12:08+00:00',
            aimed_arrival_time: '2023-02-08T10:12:08+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51276,
        backward_id: 51275,
        line_id: 1,
        order: 19,
        latitude: 60.30406,
        longitude: 5.333153,
        name: 'Skjold',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:47:59+00:00',
            aimed_departure_time: '2023-02-08T09:47:59+00:00',
            expected_arrival_time: '2023-02-08T09:47:39+00:00',
            aimed_arrival_time: '2023-02-08T09:47:39+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:39+00:00',
            aimed_departure_time: '2023-02-08T09:57:44+00:00',
            expected_arrival_time: '2023-02-08T09:58:19+00:00',
            aimed_arrival_time: '2023-02-08T09:57:24+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:06+00:00',
            aimed_departure_time: '2023-02-08T09:59:55+00:00',
            expected_arrival_time: '2023-02-08T10:01:46+00:00',
            aimed_arrival_time: '2023-02-08T09:59:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:10+00:00',
            aimed_departure_time: '2023-02-08T10:07:55+00:00',
            expected_arrival_time: '2023-02-08T10:07:50+00:00',
            aimed_arrival_time: '2023-02-08T10:07:35+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:29+00:00',
            aimed_departure_time: '2023-02-08T10:14:55+00:00',
            expected_arrival_time: '2023-02-08T10:15:09+00:00',
            aimed_arrival_time: '2023-02-08T10:14:35+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:47:17+00:00',
            aimed_departure_time: '2023-02-08T09:46:52+00:00',
            expected_arrival_time: '2023-02-08T09:46:56+00:00',
            aimed_arrival_time: '2023-02-08T09:46:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:31+00:00',
            aimed_departure_time: '2023-02-08T09:54:52+00:00',
            expected_arrival_time: '2023-02-08T09:55:10+00:00',
            aimed_arrival_time: '2023-02-08T09:54:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:01:52+00:00',
            aimed_departure_time: '2023-02-08T10:01:52+00:00',
            expected_arrival_time: '2023-02-08T10:01:31+00:00',
            aimed_arrival_time: '2023-02-08T10:01:31+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:52+00:00',
            aimed_departure_time: '2023-02-08T10:10:52+00:00',
            expected_arrival_time: '2023-02-08T10:10:31+00:00',
            aimed_arrival_time: '2023-02-08T10:10:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51824,
        backward_id: 51823,
        line_id: 1,
        order: 20,
        latitude: 60.29825,
        longitude: 5.3282,
        name: 'Lagunen terminal',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:49:56+00:00',
            aimed_departure_time: '2023-02-08T09:49:56+00:00',
            expected_arrival_time: '2023-02-08T09:49:25+00:00',
            aimed_arrival_time: '2023-02-08T09:49:25+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:36+00:00',
            aimed_departure_time: '2023-02-08T09:59:41+00:00',
            expected_arrival_time: '2023-02-08T10:00:05+00:00',
            aimed_arrival_time: '2023-02-08T09:59:10+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:03+00:00',
            aimed_departure_time: '2023-02-08T10:01:52+00:00',
            expected_arrival_time: '2023-02-08T10:03:32+00:00',
            aimed_arrival_time: '2023-02-08T10:01:21+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:07+00:00',
            aimed_departure_time: '2023-02-08T10:09:52+00:00',
            expected_arrival_time: '2023-02-08T10:09:36+00:00',
            aimed_arrival_time: '2023-02-08T10:09:21+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:53:47+00:00',
            aimed_departure_time: '2023-02-08T09:53:08+00:00',
            expected_arrival_time: '2023-02-08T09:53:16+00:00',
            aimed_arrival_time: '2023-02-08T09:52:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:08+00:00',
            aimed_departure_time: '2023-02-08T10:00:08+00:00',
            expected_arrival_time: '2023-02-08T09:59:37+00:00',
            aimed_arrival_time: '2023-02-08T09:59:37+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:08+00:00',
            aimed_departure_time: '2023-02-08T10:09:08+00:00',
            expected_arrival_time: '2023-02-08T10:08:37+00:00',
            aimed_arrival_time: '2023-02-08T10:08:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:08+00:00',
            aimed_departure_time: '2023-02-08T10:15:08+00:00',
            expected_arrival_time: '2023-02-08T10:14:37+00:00',
            aimed_arrival_time: '2023-02-08T10:14:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51722,
        backward_id: 51723,
        line_id: 1,
        order: 21,
        latitude: 60.30032,
        longitude: 5.31039,
        name: 'Råstølen',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:51:54+00:00',
            aimed_departure_time: '2023-02-08T09:51:54+00:00',
            expected_arrival_time: '2023-02-08T09:51:32+00:00',
            aimed_arrival_time: '2023-02-08T09:51:32+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:34+00:00',
            aimed_departure_time: '2023-02-08T10:01:39+00:00',
            expected_arrival_time: '2023-02-08T10:02:12+00:00',
            aimed_arrival_time: '2023-02-08T10:01:17+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:01+00:00',
            aimed_departure_time: '2023-02-08T10:03:50+00:00',
            expected_arrival_time: '2023-02-08T10:05:39+00:00',
            aimed_arrival_time: '2023-02-08T10:03:28+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:05+00:00',
            aimed_departure_time: '2023-02-08T10:11:50+00:00',
            expected_arrival_time: '2023-02-08T10:11:43+00:00',
            aimed_arrival_time: '2023-02-08T10:11:28+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:51:36+00:00',
            aimed_departure_time: '2023-02-08T09:50:57+00:00',
            expected_arrival_time: '2023-02-08T09:51:14+00:00',
            aimed_arrival_time: '2023-02-08T09:50:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:57+00:00',
            aimed_departure_time: '2023-02-08T09:57:57+00:00',
            expected_arrival_time: '2023-02-08T09:57:35+00:00',
            aimed_arrival_time: '2023-02-08T09:57:35+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:57+00:00',
            aimed_departure_time: '2023-02-08T10:06:57+00:00',
            expected_arrival_time: '2023-02-08T10:06:35+00:00',
            aimed_arrival_time: '2023-02-08T10:06:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:57+00:00',
            aimed_departure_time: '2023-02-08T10:12:57+00:00',
            expected_arrival_time: '2023-02-08T10:12:35+00:00',
            aimed_arrival_time: '2023-02-08T10:12:35+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51834,
        backward_id: 51833,
        line_id: 1,
        order: 22,
        latitude: 60.296474,
        longitude: 5.287318,
        name: 'Sandslivegen',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:46:59+00:00',
            aimed_departure_time: '2023-02-08T09:46:59+00:00',
            expected_arrival_time: '2023-02-08T09:46:39+00:00',
            aimed_arrival_time: '2023-02-08T09:46:39+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:54:03+00:00',
            aimed_departure_time: '2023-02-08T09:54:03+00:00',
            expected_arrival_time: '2023-02-08T09:53:43+00:00',
            aimed_arrival_time: '2023-02-08T09:53:43+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:43+00:00',
            aimed_departure_time: '2023-02-08T10:03:48+00:00',
            expected_arrival_time: '2023-02-08T10:04:23+00:00',
            aimed_arrival_time: '2023-02-08T10:03:28+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:10+00:00',
            aimed_departure_time: '2023-02-08T10:05:59+00:00',
            expected_arrival_time: '2023-02-08T10:07:50+00:00',
            aimed_arrival_time: '2023-02-08T10:05:39+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:14+00:00',
            aimed_departure_time: '2023-02-08T10:13:59+00:00',
            expected_arrival_time: '2023-02-08T10:13:54+00:00',
            aimed_arrival_time: '2023-02-08T10:13:39+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:49:27+00:00',
            aimed_departure_time: '2023-02-08T09:48:48+00:00',
            expected_arrival_time: '2023-02-08T09:49:05+00:00',
            aimed_arrival_time: '2023-02-08T09:48:26+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:48+00:00',
            aimed_departure_time: '2023-02-08T09:55:48+00:00',
            expected_arrival_time: '2023-02-08T09:55:26+00:00',
            aimed_arrival_time: '2023-02-08T09:55:26+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:48+00:00',
            aimed_departure_time: '2023-02-08T10:04:48+00:00',
            expected_arrival_time: '2023-02-08T10:04:26+00:00',
            aimed_arrival_time: '2023-02-08T10:04:26+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:48+00:00',
            aimed_departure_time: '2023-02-08T10:10:48+00:00',
            expected_arrival_time: '2023-02-08T10:10:26+00:00',
            aimed_arrival_time: '2023-02-08T10:10:26+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51844,
        backward_id: 51842,
        line_id: 1,
        order: 23,
        latitude: 60.29779,
        longitude: 5.275251,
        name: 'Sandslimarka',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:48:38+00:00',
            aimed_departure_time: '2023-02-08T09:48:38+00:00',
            expected_arrival_time: '2023-02-08T09:48:18+00:00',
            aimed_arrival_time: '2023-02-08T09:48:18+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:42+00:00',
            aimed_departure_time: '2023-02-08T09:55:42+00:00',
            expected_arrival_time: '2023-02-08T09:55:22+00:00',
            aimed_arrival_time: '2023-02-08T09:55:22+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:22+00:00',
            aimed_departure_time: '2023-02-08T10:05:27+00:00',
            expected_arrival_time: '2023-02-08T10:06:02+00:00',
            aimed_arrival_time: '2023-02-08T10:05:07+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:49+00:00',
            aimed_departure_time: '2023-02-08T10:07:38+00:00',
            expected_arrival_time: '2023-02-08T10:09:29+00:00',
            aimed_arrival_time: '2023-02-08T10:07:18+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:47:44+00:00',
            aimed_departure_time: '2023-02-08T09:47:05+00:00',
            expected_arrival_time: '2023-02-08T09:47:24+00:00',
            aimed_arrival_time: '2023-02-08T09:46:45+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:54:05+00:00',
            aimed_departure_time: '2023-02-08T09:54:05+00:00',
            expected_arrival_time: '2023-02-08T09:53:45+00:00',
            aimed_arrival_time: '2023-02-08T09:53:45+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:05+00:00',
            aimed_departure_time: '2023-02-08T10:03:05+00:00',
            expected_arrival_time: '2023-02-08T10:02:45+00:00',
            aimed_arrival_time: '2023-02-08T10:02:45+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:05+00:00',
            aimed_departure_time: '2023-02-08T10:09:05+00:00',
            expected_arrival_time: '2023-02-08T10:08:45+00:00',
            aimed_arrival_time: '2023-02-08T10:08:45+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 51852,
        backward_id: 51853,
        line_id: 1,
        order: 24,
        latitude: 60.29387,
        longitude: 5.267912,
        name: 'Kokstad',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:49:52+00:00',
            aimed_departure_time: '2023-02-08T09:49:52+00:00',
            expected_arrival_time: '2023-02-08T09:49:32+00:00',
            aimed_arrival_time: '2023-02-08T09:49:32+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:56+00:00',
            aimed_departure_time: '2023-02-08T09:56:56+00:00',
            expected_arrival_time: '2023-02-08T09:56:36+00:00',
            aimed_arrival_time: '2023-02-08T09:56:36+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:36+00:00',
            aimed_departure_time: '2023-02-08T10:06:41+00:00',
            expected_arrival_time: '2023-02-08T10:07:16+00:00',
            aimed_arrival_time: '2023-02-08T10:06:21+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:03+00:00',
            aimed_departure_time: '2023-02-08T10:08:52+00:00',
            expected_arrival_time: '2023-02-08T10:10:43+00:00',
            aimed_arrival_time: '2023-02-08T10:08:32+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:52:49+00:00',
            aimed_departure_time: '2023-02-08T09:52:49+00:00',
            expected_arrival_time: '2023-02-08T09:52:31+00:00',
            aimed_arrival_time: '2023-02-08T09:52:31+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:01:49+00:00',
            aimed_departure_time: '2023-02-08T10:01:49+00:00',
            expected_arrival_time: '2023-02-08T10:01:31+00:00',
            aimed_arrival_time: '2023-02-08T10:01:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:49+00:00',
            aimed_departure_time: '2023-02-08T10:07:49+00:00',
            expected_arrival_time: '2023-02-08T10:07:31+00:00',
            aimed_arrival_time: '2023-02-08T10:07:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:49+00:00',
            aimed_departure_time: '2023-02-08T10:15:49+00:00',
            expected_arrival_time: '2023-02-08T10:15:31+00:00',
            aimed_arrival_time: '2023-02-08T10:15:31+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 51867,
        backward_id: 51868,
        line_id: 1,
        order: 25,
        latitude: 60.28833,
        longitude: 5.262007,
        name: 'Birkelandsskiftet terminal',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:51:12+00:00',
            aimed_departure_time: '2023-02-08T09:51:12+00:00',
            expected_arrival_time: '2023-02-08T09:50:51+00:00',
            aimed_arrival_time: '2023-02-08T09:50:51+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:16+00:00',
            aimed_departure_time: '2023-02-08T09:58:16+00:00',
            expected_arrival_time: '2023-02-08T09:57:55+00:00',
            aimed_arrival_time: '2023-02-08T09:57:55+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:56+00:00',
            aimed_departure_time: '2023-02-08T10:08:01+00:00',
            expected_arrival_time: '2023-02-08T10:08:35+00:00',
            aimed_arrival_time: '2023-02-08T10:07:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:23+00:00',
            aimed_departure_time: '2023-02-08T10:10:12+00:00',
            expected_arrival_time: '2023-02-08T10:12:02+00:00',
            aimed_arrival_time: '2023-02-08T10:09:51+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:51:37+00:00',
            aimed_departure_time: '2023-02-08T09:51:37+00:00',
            expected_arrival_time: '2023-02-08T09:51:18+00:00',
            aimed_arrival_time: '2023-02-08T09:51:18+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:37+00:00',
            aimed_departure_time: '2023-02-08T10:00:37+00:00',
            expected_arrival_time: '2023-02-08T10:00:18+00:00',
            aimed_arrival_time: '2023-02-08T10:00:18+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:37+00:00',
            aimed_departure_time: '2023-02-08T10:06:37+00:00',
            expected_arrival_time: '2023-02-08T10:06:18+00:00',
            aimed_arrival_time: '2023-02-08T10:06:18+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:37+00:00',
            aimed_departure_time: '2023-02-08T10:14:37+00:00',
            expected_arrival_time: '2023-02-08T10:14:18+00:00',
            aimed_arrival_time: '2023-02-08T10:14:18+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 51862,
        backward_id: 51861,
        line_id: 1,
        order: 26,
        latitude: 60.29195,
        longitude: 5.244083,
        name: 'Kokstadflaten',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:53:05+00:00',
            aimed_departure_time: '2023-02-08T09:53:05+00:00',
            expected_arrival_time: '2023-02-08T09:52:50+00:00',
            aimed_arrival_time: '2023-02-08T09:52:50+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:09+00:00',
            aimed_departure_time: '2023-02-08T10:00:09+00:00',
            expected_arrival_time: '2023-02-08T09:59:54+00:00',
            aimed_arrival_time: '2023-02-08T09:59:54+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:49+00:00',
            aimed_departure_time: '2023-02-08T10:09:54+00:00',
            expected_arrival_time: '2023-02-08T10:10:34+00:00',
            aimed_arrival_time: '2023-02-08T10:09:39+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:16+00:00',
            aimed_departure_time: '2023-02-08T10:12:05+00:00',
            expected_arrival_time: '2023-02-08T10:14:01+00:00',
            aimed_arrival_time: '2023-02-08T10:11:50+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:49:46+00:00',
            aimed_departure_time: '2023-02-08T09:49:46+00:00',
            expected_arrival_time: '2023-02-08T09:49:31+00:00',
            aimed_arrival_time: '2023-02-08T09:49:31+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:46+00:00',
            aimed_departure_time: '2023-02-08T09:58:46+00:00',
            expected_arrival_time: '2023-02-08T09:58:31+00:00',
            aimed_arrival_time: '2023-02-08T09:58:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:46+00:00',
            aimed_departure_time: '2023-02-08T10:04:46+00:00',
            expected_arrival_time: '2023-02-08T10:04:31+00:00',
            aimed_arrival_time: '2023-02-08T10:04:31+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:46+00:00',
            aimed_departure_time: '2023-02-08T10:12:46+00:00',
            expected_arrival_time: '2023-02-08T10:12:31+00:00',
            aimed_arrival_time: '2023-02-08T10:12:31+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
      {
        id: 51855,
        backward_id: 51856,
        line_id: 1,
        order: 27,
        latitude: 60.28815,
        longitude: 5.23213,
        name: 'Bergen lufthavn',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:48:20+00:00',
            aimed_departure_time: '2023-02-08T09:48:00+00:00',
            expected_arrival_time: '2023-02-08T09:48:00+00:00',
            aimed_arrival_time: '2023-02-08T09:47:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:57:20+00:00',
            aimed_departure_time: '2023-02-08T09:57:00+00:00',
            expected_arrival_time: '2023-02-08T09:57:00+00:00',
            aimed_arrival_time: '2023-02-08T09:56:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:20+00:00',
            aimed_departure_time: '2023-02-08T10:03:00+00:00',
            expected_arrival_time: '2023-02-08T10:03:00+00:00',
            aimed_arrival_time: '2023-02-08T10:02:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:20+00:00',
            aimed_departure_time: '2023-02-08T10:11:00+00:00',
            expected_arrival_time: '2023-02-08T10:11:00+00:00',
            aimed_arrival_time: '2023-02-08T10:10:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Kaigaten - Fyllingsdalen Terminal',
    destination_stop: 809,
    stops: [
      {
        id: 106984,
        backward_id: 106985,
        line_id: 2,
        order: 1,
        latitude: 60.39194,
        longitude: 5.32682,
        name: 'Kaigaten',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:53:26+00:00',
            aimed_departure_time: '2023-02-08T09:47:00+00:00',
            expected_arrival_time: '2023-02-08T09:47:00+00:00',
            aimed_arrival_time: '2023-02-08T09:40:34+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:00:26+00:00',
            aimed_departure_time: '2023-02-08T09:54:00+00:00',
            expected_arrival_time: '2023-02-08T09:54:00+00:00',
            aimed_arrival_time: '2023-02-08T09:47:34+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:08:26+00:00',
            aimed_departure_time: '2023-02-08T10:02:00+00:00',
            expected_arrival_time: '2023-02-08T10:02:00+00:00',
            aimed_arrival_time: '2023-02-08T09:55:34+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:15:26+00:00',
            aimed_departure_time: '2023-02-08T10:09:00+00:00',
            expected_arrival_time: '2023-02-08T10:09:00+00:00',
            aimed_arrival_time: '2023-02-08T10:02:34+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [],
      },
      {
        id: 53136,
        backward_id: 53135,
        line_id: 2,
        order: 2,
        latitude: 60.39007,
        longitude: 5.33279,
        name: 'Nonneseter',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:48:50+00:00',
            aimed_departure_time: '2023-02-08T09:48:50+00:00',
            expected_arrival_time: '2023-02-08T09:48:27+00:00',
            aimed_arrival_time: '2023-02-08T09:48:27+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T09:55:50+00:00',
            aimed_departure_time: '2023-02-08T09:55:50+00:00',
            expected_arrival_time: '2023-02-08T09:55:27+00:00',
            aimed_arrival_time: '2023-02-08T09:55:27+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:03:50+00:00',
            aimed_departure_time: '2023-02-08T10:03:50+00:00',
            expected_arrival_time: '2023-02-08T10:03:27+00:00',
            aimed_arrival_time: '2023-02-08T10:03:27+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:50+00:00',
            aimed_departure_time: '2023-02-08T10:10:50+00:00',
            expected_arrival_time: '2023-02-08T10:10:27+00:00',
            aimed_arrival_time: '2023-02-08T10:10:27+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:55:36+00:00',
            aimed_departure_time: '2023-02-08T09:53:41+00:00',
            expected_arrival_time: '2023-02-08T09:55:13+00:00',
            aimed_arrival_time: '2023-02-08T09:53:18+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:41+00:00',
            aimed_departure_time: '2023-02-08T10:00:41+00:00',
            expected_arrival_time: '2023-02-08T10:00:18+00:00',
            aimed_arrival_time: '2023-02-08T10:00:18+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:41+00:00',
            aimed_departure_time: '2023-02-08T10:11:41+00:00',
            expected_arrival_time: '2023-02-08T10:11:18+00:00',
            aimed_arrival_time: '2023-02-08T10:11:18+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 106983,
        backward_id: 106982,
        line_id: 2,
        order: 3,
        latitude: 60.38815,
        longitude: 5.33571,
        name: 'Bergen busstasjon',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:50:35+00:00',
            aimed_departure_time: '2023-02-08T09:50:35+00:00',
            expected_arrival_time: '2023-02-08T09:50:12+00:00',
            aimed_arrival_time: '2023-02-08T09:50:12+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T09:57:35+00:00',
            aimed_departure_time: '2023-02-08T09:57:35+00:00',
            expected_arrival_time: '2023-02-08T09:57:12+00:00',
            aimed_arrival_time: '2023-02-08T09:57:12+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:05:35+00:00',
            aimed_departure_time: '2023-02-08T10:05:35+00:00',
            expected_arrival_time: '2023-02-08T10:05:12+00:00',
            aimed_arrival_time: '2023-02-08T10:05:12+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:35+00:00',
            aimed_departure_time: '2023-02-08T10:12:35+00:00',
            expected_arrival_time: '2023-02-08T10:12:12+00:00',
            aimed_arrival_time: '2023-02-08T10:12:12+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:53:55+00:00',
            aimed_departure_time: '2023-02-08T09:52:00+00:00',
            expected_arrival_time: '2023-02-08T09:53:32+00:00',
            aimed_arrival_time: '2023-02-08T09:51:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:59:00+00:00',
            aimed_departure_time: '2023-02-08T09:59:00+00:00',
            expected_arrival_time: '2023-02-08T09:58:37+00:00',
            aimed_arrival_time: '2023-02-08T09:58:37+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:10:00+00:00',
            aimed_departure_time: '2023-02-08T10:10:00+00:00',
            expected_arrival_time: '2023-02-08T10:09:37+00:00',
            aimed_arrival_time: '2023-02-08T10:09:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 106980,
        backward_id: 106981,
        line_id: 2,
        order: 4,
        latitude: 60.38113,
        longitude: 5.35301,
        name: 'Fløen',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:52:44+00:00',
            aimed_departure_time: '2023-02-08T09:52:44+00:00',
            expected_arrival_time: '2023-02-08T09:52:21+00:00',
            aimed_arrival_time: '2023-02-08T09:52:21+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T09:59:44+00:00',
            aimed_departure_time: '2023-02-08T09:59:44+00:00',
            expected_arrival_time: '2023-02-08T09:59:21+00:00',
            aimed_arrival_time: '2023-02-08T09:59:21+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:44+00:00',
            aimed_departure_time: '2023-02-08T10:07:44+00:00',
            expected_arrival_time: '2023-02-08T10:07:21+00:00',
            aimed_arrival_time: '2023-02-08T10:07:21+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:44+00:00',
            aimed_departure_time: '2023-02-08T10:14:44+00:00',
            expected_arrival_time: '2023-02-08T10:14:21+00:00',
            aimed_arrival_time: '2023-02-08T10:14:21+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:51:41+00:00',
            aimed_departure_time: '2023-02-08T09:49:46+00:00',
            expected_arrival_time: '2023-02-08T09:51:18+00:00',
            aimed_arrival_time: '2023-02-08T09:49:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:56:46+00:00',
            aimed_departure_time: '2023-02-08T09:56:46+00:00',
            expected_arrival_time: '2023-02-08T09:56:23+00:00',
            aimed_arrival_time: '2023-02-08T09:56:23+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:07:46+00:00',
            aimed_departure_time: '2023-02-08T10:07:46+00:00',
            expected_arrival_time: '2023-02-08T10:07:23+00:00',
            aimed_arrival_time: '2023-02-08T10:07:23+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 106979,
        backward_id: 106978,
        line_id: 2,
        order: 5,
        latitude: 60.37687,
        longitude: 5.36081,
        name: 'Haukeland sjukehus',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:54:32+00:00',
            aimed_departure_time: '2023-02-08T09:54:32+00:00',
            expected_arrival_time: '2023-02-08T09:54:09+00:00',
            aimed_arrival_time: '2023-02-08T09:54:09+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:01:32+00:00',
            aimed_departure_time: '2023-02-08T10:01:32+00:00',
            expected_arrival_time: '2023-02-08T10:01:09+00:00',
            aimed_arrival_time: '2023-02-08T10:01:09+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:09:32+00:00',
            aimed_departure_time: '2023-02-08T10:09:32+00:00',
            expected_arrival_time: '2023-02-08T10:09:09+00:00',
            aimed_arrival_time: '2023-02-08T10:09:09+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:49:58+00:00',
            aimed_departure_time: '2023-02-08T09:48:03+00:00',
            expected_arrival_time: '2023-02-08T09:49:35+00:00',
            aimed_arrival_time: '2023-02-08T09:47:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:55:03+00:00',
            aimed_departure_time: '2023-02-08T09:55:03+00:00',
            expected_arrival_time: '2023-02-08T09:54:40+00:00',
            aimed_arrival_time: '2023-02-08T09:54:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:06:03+00:00',
            aimed_departure_time: '2023-02-08T10:06:03+00:00',
            expected_arrival_time: '2023-02-08T10:05:40+00:00',
            aimed_arrival_time: '2023-02-08T10:05:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 106976,
        backward_id: 106977,
        line_id: 2,
        order: 6,
        latitude: 60.3712,
        longitude: 5.3485,
        name: 'Kronstad',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:56:22+00:00',
            aimed_departure_time: '2023-02-08T09:56:22+00:00',
            expected_arrival_time: '2023-02-08T09:55:59+00:00',
            aimed_arrival_time: '2023-02-08T09:55:59+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:03:22+00:00',
            aimed_departure_time: '2023-02-08T10:03:22+00:00',
            expected_arrival_time: '2023-02-08T10:02:59+00:00',
            aimed_arrival_time: '2023-02-08T10:02:59+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:11:22+00:00',
            aimed_departure_time: '2023-02-08T10:11:22+00:00',
            expected_arrival_time: '2023-02-08T10:10:59+00:00',
            aimed_arrival_time: '2023-02-08T10:10:59+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:48:13+00:00',
            aimed_departure_time: '2023-02-08T09:46:18+00:00',
            expected_arrival_time: '2023-02-08T09:47:50+00:00',
            aimed_arrival_time: '2023-02-08T09:45:55+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:53:18+00:00',
            aimed_departure_time: '2023-02-08T09:53:18+00:00',
            expected_arrival_time: '2023-02-08T09:52:55+00:00',
            aimed_arrival_time: '2023-02-08T09:52:55+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:04:18+00:00',
            aimed_departure_time: '2023-02-08T10:04:18+00:00',
            expected_arrival_time: '2023-02-08T10:03:55+00:00',
            aimed_arrival_time: '2023-02-08T10:03:55+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
      },
      {
        id: 106974,
        backward_id: 106975,
        line_id: 2,
        order: 7,
        latitude: 60.3658,
        longitude: 5.3453,
        name: 'Mindemyren',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:46:05+00:00',
            aimed_departure_time: '2023-02-08T09:46:03+00:00',
            expected_arrival_time: '2023-02-08T09:45:42+00:00',
            aimed_arrival_time: '2023-02-08T09:45:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:58:03+00:00',
            aimed_departure_time: '2023-02-08T09:58:03+00:00',
            expected_arrival_time: '2023-02-08T09:57:40+00:00',
            aimed_arrival_time: '2023-02-08T09:57:40+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:05:03+00:00',
            aimed_departure_time: '2023-02-08T10:05:03+00:00',
            expected_arrival_time: '2023-02-08T10:04:40+00:00',
            aimed_arrival_time: '2023-02-08T10:04:40+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:13:03+00:00',
            aimed_departure_time: '2023-02-08T10:13:03+00:00',
            expected_arrival_time: '2023-02-08T10:12:40+00:00',
            aimed_arrival_time: '2023-02-08T10:12:40+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:51:34+00:00',
            aimed_departure_time: '2023-02-08T09:51:34+00:00',
            expected_arrival_time: '2023-02-08T09:51:11+00:00',
            aimed_arrival_time: '2023-02-08T09:51:11+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:02:34+00:00',
            aimed_departure_time: '2023-02-08T10:02:34+00:00',
            expected_arrival_time: '2023-02-08T10:02:11+00:00',
            aimed_arrival_time: '2023-02-08T10:02:11+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:34+00:00',
            aimed_departure_time: '2023-02-08T10:14:34+00:00',
            expected_arrival_time: '2023-02-08T10:14:11+00:00',
            aimed_arrival_time: '2023-02-08T10:14:11+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
      },
      {
        id: 106973,
        backward_id: 106972,
        line_id: 2,
        order: 8,
        latitude: 60.35834,
        longitude: 5.34166,
        name: 'Kristianborg',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [
          {
            expected_departure_time: '2023-02-08T09:48:04+00:00',
            aimed_departure_time: '2023-02-08T09:47:59+00:00',
            expected_arrival_time: '2023-02-08T09:47:41+00:00',
            aimed_arrival_time: '2023-02-08T09:47:36+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T09:59:59+00:00',
            aimed_departure_time: '2023-02-08T09:59:59+00:00',
            expected_arrival_time: '2023-02-08T09:59:36+00:00',
            aimed_arrival_time: '2023-02-08T09:59:36+00:00',
            occupancy: OccupancyEnum.Full,
          },
          {
            expected_departure_time: '2023-02-08T10:06:59+00:00',
            aimed_departure_time: '2023-02-08T10:06:59+00:00',
            expected_arrival_time: '2023-02-08T10:06:36+00:00',
            aimed_arrival_time: '2023-02-08T10:06:36+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:59+00:00',
            aimed_departure_time: '2023-02-08T10:14:59+00:00',
            expected_arrival_time: '2023-02-08T10:14:36+00:00',
            aimed_arrival_time: '2023-02-08T10:14:36+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
        ],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:49:36+00:00',
            aimed_departure_time: '2023-02-08T09:49:36+00:00',
            expected_arrival_time: '2023-02-08T09:49:13+00:00',
            aimed_arrival_time: '2023-02-08T09:49:13+00:00',
            occupancy: OccupancyEnum.StandingAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:00:36+00:00',
            aimed_departure_time: '2023-02-08T10:00:36+00:00',
            expected_arrival_time: '2023-02-08T10:00:13+00:00',
            aimed_arrival_time: '2023-02-08T10:00:13+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:12:36+00:00',
            aimed_departure_time: '2023-02-08T10:12:36+00:00',
            expected_arrival_time: '2023-02-08T10:12:13+00:00',
            aimed_arrival_time: '2023-02-08T10:12:13+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
      },
      {
        id: 106933,
        backward_id: 106934,
        line_id: 2,
        order: 9,
        latitude: 60.34878,
        longitude: 5.28945,
        name: 'Fyllingsdalen terminal',
        response_timestamp: '2023-02-08T09:45:52.519+00:00',
        estimated_calls_forward: [],
        estimated_calls_backward: [
          {
            expected_departure_time: '2023-02-08T09:59:23+00:00',
            aimed_departure_time: '2023-02-08T09:57:00+00:00',
            expected_arrival_time: '2023-02-08T09:57:00+00:00',
            aimed_arrival_time: '2023-02-08T09:54:37+00:00',
            occupancy: OccupancyEnum.SeatsAvailable,
          },
          {
            expected_departure_time: '2023-02-08T10:14:23+00:00',
            aimed_departure_time: '2023-02-08T10:09:00+00:00',
            expected_arrival_time: '2023-02-08T10:09:00+00:00',
            aimed_arrival_time: '2023-02-08T10:03:37+00:00',
            occupancy: OccupancyEnum.Full,
          },
        ],
      },
    ],
  },
]

export { API_MOCK_DATA_STOP, API_MOCK_DATA_STOP_2, API_MOCK_DATA_LINE, API_MOCK_DATA_ALL_LINES }
