import { useEffect, useState } from 'react'

import { DirectionEnum, IStop } from '../../../../typeDefinitions'
import SearchStopDirectionCard from '../SearchStopDirectionCard/SearchStopDirectionCard'
import styles from './SearchStopDirection.module.scss'

interface IDirectionProps {
  backwardEndStop: IStop
  forwardEndStop: IStop
  setDirection: React.Dispatch<React.SetStateAction<DirectionEnum | undefined>>
  currentStop: string
  lineId: number
  navigateToRealtime?: (lineId: number, stopName: string, direction?: string) => void
  handleClose: () => void
}

export default function Direction({ backwardEndStop, forwardEndStop, setDirection, currentStop, lineId, navigateToRealtime, handleClose }: IDirectionProps) {
  // chosenDirection chooses styling for the Direction cards based on what direction is chosen.
  // They may have three states: active, inactive (default), otherActive (the other card is active)
  const [chosenDirection, setChosenDirection] = useState('')

  function handleDirectionChange(cardDirection: string): void {
    setChosenDirection((prevchosenDirection) => (prevchosenDirection === cardDirection ? '' : cardDirection))
    if (setDirection) {
      const direction = getDirectionFromCard(cardDirection)
      setDirection((prevDirection) => (prevDirection === direction ? undefined : direction))
    }
  }

  function getDirectionFromCard(cardDirection: string) {
    if (cardDirection === backwardEndStop.name) {
      return DirectionEnum.Backward
    } else if (cardDirection === forwardEndStop.name) {
      return DirectionEnum.Forward
    }
    return undefined
  }

  function changeDirection(cardDirection: string): void {
    if (currentStop === backwardEndStop.name) {
      handleDirectionChange(forwardEndStop.name)
    } else if (currentStop === forwardEndStop.name) {
      handleDirectionChange(backwardEndStop.name)
    } else {
      handleDirectionChange(cardDirection)
    }
    handleClose()
    navigateToRealtime?.(lineId, currentStop, getDirectionFromCard(cardDirection))
  }

  useEffect(() => {
    if (currentStop === backwardEndStop.name) {
      setChosenDirection(forwardEndStop.name)
      setDirection(DirectionEnum.Forward)
    } else if (currentStop === forwardEndStop.name) {
      setChosenDirection(backwardEndStop.name)
      setDirection(DirectionEnum.Backward)
    }
  }, [currentStop])

  return (
    <div className={styles.direction} data-testid="Direction">
      <div className={styles.directionCardContainer}>
        <SearchStopDirectionCard
          left
          chosenDirection={chosenDirection}
          endStopName={backwardEndStop.name}
          changeDirection={
            currentStop === forwardEndStop.name || currentStop === backwardEndStop.name ? () => {} : changeDirection
          }
          currentStop={currentStop}
        />
        <SearchStopDirectionCard
          chosenDirection={chosenDirection}
          endStopName={forwardEndStop.name}
          changeDirection={
            currentStop === forwardEndStop.name || currentStop === backwardEndStop.name ? () => {} : changeDirection
          }
          currentStop={currentStop}
        />
      </div>
    </div>
  )
}
