import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLineQuery } from '../../CustomHooks/useLineQuery'
import { useSetTitle } from '../../CustomHooks/usePageTitle'
import CardContainer from '../../components/CardContainer/CardContainer'
//import DeviationMessages from '../../components/DeviationMessages/DeviationMessages'
import GenericClickableCard from '../../components/GenericClickableCard/GenericClickableCard'
import Hero from '../../components/Hero/Hero'
import { LanguageContext } from '../../components/Providers/LanguageProvider'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import styles from './Homepage.module.scss'

export default function Homepage() {
  const lineOneStops = useLineQuery(1).line?.stops?.map((stop) => stop.name)
  const lineTwoStops = useLineQuery(2).line?.stops?.map((stop) => stop.name)

  useEffect(() => {
    document.body.tabIndex = -1
    document.body.focus()

    removeOldStopsFromLocalstorage()
  }, [lineOneStops, lineTwoStops])

  function removeOldStopsFromLocalstorage() {
    if (lineOneStops !== undefined && lineTwoStops !== undefined) {
      const lineTwoStopsMapped = lineTwoStops.map((line: string) => (line += '_2'))
      const allStops = Array.from(new Set([...lineOneStops, ...lineTwoStopsMapped]))

      const localStops: string[] = JSON.parse(localStorage.getItem('Bybanen-favoritter') || '[]')
      const validStops = localStops.filter((stop) => allStops.includes(stop))
      localStorage.setItem('Bybanen-favoritter', JSON.stringify(validStops))
    }
  }

  useSetTitle({ en: 'Homepage', no: 'Startside' })

  const navigate = useNavigate()
  const lang = useContext(LanguageContext)

  const { data: sanityData } = useQuery<IPage>([PageName.HomePage, lang.language], () =>
    getPage(PageName.HomePage, lang.language)
  )

  function navigateToRealtime(lineId: number, stopName: string, direction?: string): void {
    if (direction) {
      navigate({
        pathname: '/realtime',
        search: `?lineId=${lineId}&stopName=${stopName}&direction=${direction}`,
      })
    } else if (stopName) {
      navigate({
        pathname: '/realtime',
        search: `?lineId=${lineId}&stopName=${stopName}`,
      })
    }
  }

  function navigateToLine(lineId: string): void {
    navigate({
      pathname: '/line',
      search: `?lineId=${lineId}`,
    })
  }

  return (
    <div className={styles.homepage} data-testid="Homepage">
      <Hero imageUrl="/images/hero_desktop.webp" />
      <span>
        <CardContainer navigateToRealtime={navigateToRealtime} />
      </span>
      {/** <DeviationMessages */}
      <div className={styles.gradient}>
        <div className={styles.linkToLines}>
          <GenericClickableCard
            text={getElementFromPage('pLinesOverviewBtn', sanityData, lang.language)}
            rightIcon="/icons/art-right-arrow.svg"
            handleClick={() => navigateToLine('1')}
          />
        </div>
      </div>
    </div>
  )
}
