import React from 'react'

import { SkipLink } from '../SkipToContent/SkipToContent'
import Footer from './Footer/Footer'
import Header from './Header/Header'
import styles from './Layout.module.scss'

interface ILayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: ILayoutProps) {
  return (
    <div className={styles.layout} data-testid="Layout">
      <SkipLink />
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}
