import { MouseEventHandler, useContext } from 'react'

import { LanguageContext } from '../../Providers/LanguageProvider'
import styles from './DirectionTab.module.scss'

interface IDirectionTabProps {
  onTabClick: MouseEventHandler
  active: boolean
  lineId: number | undefined
}

export default function DirectionTab({ active, onTabClick: onClick, lineId }: IDirectionTabProps) {
  const lang = useContext(LanguageContext)

  return (
    <button onClick={onClick} className={active ? `${styles.tabActive} ` : `${styles.tabInactive}`}>
      {lang.language === 'en' ? 'Line' : 'Linje'} {lineId}
    </button>
  )
}
