import { useContext } from 'react'

import { LanguageContext } from '../../../Providers/LanguageProvider'
import styles from '../SearchStopDirection/SearchStopDirection.module.scss'

interface ISearchStopDirectionCardProps {
  left?: boolean
  chosenDirection: string
  endStopName: string
  changeDirection(cardDirection: string): void
  currentStop: string
}

export default function SearchStopDirectionCard({
  left,
  chosenDirection,
  endStopName,
  changeDirection,
  currentStop,
}: ISearchStopDirectionCardProps) {
  const direction = left ? 'Left' : 'Right'
  const lang = useContext(LanguageContext)

  function chooseStyling(direction: string, endStopName: string): string {
    return currentStop === endStopName
      ? `directionCard${direction}Blurred`
      : chosenDirection === endStopName
      ? `directionCard${direction}Active`
      : chosenDirection === ''
      ? `directionCard${direction}`
      : `directionCard${direction}OtherActive`
  }

  return (
    <div
      aria-label={lang.language === 'en' ? 'Direction ' + endStopName : 'Retning ' + endStopName}
      tabIndex={currentStop === endStopName ? -1 : 0}
      className={styles[chooseStyling(direction, endStopName)]}
      data-testid={'DirectionCard' + direction}
      onClick={() => changeDirection(endStopName)}
      onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && changeDirection(endStopName)}
    >
      <p className={styles.directionCardText} unselectable="on">
        {endStopName == 'Fyllingsdalen terminal' ? 'Fyllingsdalen' : endStopName}
      </p>
      <img
        className={styles.directionCardBybaneMob}
        src={'/icons/Bybanen-Mob.svg'}
        alt={lang.language === 'en' ? 'bybane-icon' : 'bybane-ikon'}
      />
      <img
        className={styles.directionCardBybaneDesk}
        src={'/icons/Bybanen-Desk.svg'}
        alt={lang.language === 'en' ? 'bybane-icon' : 'bybane-ikon'}
      />
      <img
        className={styles.directionCardBar}
        src={'/icons/bar.svg'}
        alt={lang.language === 'en' ? 'bybane-line' : 'bybane-linje'}
      />
      <img
        className={styles.directionCardArrow}
        src={'/icons/arrow.svg'}
        alt={lang.language === 'en' ? 'bybane-arrow' : 'bybane-pil'}
      />
      <img
        className={styles.directionCardCircle}
        src={'/icons/circle.svg'}
        alt={lang.language === 'en' ? 'bybane-circle' : 'bybane-sirkel'}
      />
    </div>
  )
}
