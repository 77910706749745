import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useRef } from 'react'

import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import { LanguageContext } from '../Providers/LanguageProvider'
import { useSkipLink } from '../SkipToContent/SkipContext'
import styles from './Hero.module.scss'

interface HeroProps {
  imageUrl: string
}

export default function Hero({ imageUrl }: HeroProps): JSX.Element {
  const lang = useContext(LanguageContext)
  const { setTargetRef } = useSkipLink()
  const contentRef = useRef<HTMLHeadingElement | null>(null)

  useEffect(() => {
    setTargetRef(contentRef)
  }, [])

  const { data: sanityData } = useQuery<IPage>([PageName.HomePage, lang.language], () =>
    getPage(PageName.HomePage, lang.language)
  )

  return (
    <div aria-label="Bybanen Sanntid" className={styles.hero} data-testid="Hero">
      <picture>
        <img
          className={styles.heroImage}
          src={imageUrl}
          alt={lang.language === 'no' ? 'Folk som går til og fra bybanen' : 'People walking from and to bybanen'}
        />
      </picture>
      <div className={styles.overlayGradient} />
      <h1 ref={contentRef} tabIndex={-1} title="heroTitle" className={styles.heroTitle}>
        {getElementFromPage('h1Title', sanityData, lang.language)}
      </h1>
    </div>
  )
}
