import React from 'react'

import ApiManager, { IApiManager } from '../../services/ApiManager/ApiManager'

const api = new ApiManager()
export const ApiContext = React.createContext<IApiManager>(api)

interface IApiProviderProps {
  children: React.ReactNode
}
export default function ApiProvider({ children }: IApiProviderProps) {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}
