import React, { ReactNode, RefObject, createContext, useContext, useState } from 'react'

interface SkipLinkContextType {
  targetRef: RefObject<HTMLElement> | null
  setTargetRef: React.Dispatch<React.SetStateAction<RefObject<HTMLElement> | null>>
}

const SkipLinkContext = createContext<SkipLinkContextType | undefined>(undefined)

export const useSkipLink = () => {
  const context = useContext(SkipLinkContext)
  if (!context) {
    throw new Error('useSkipLink must be used within a SkipLinkProvider')
  }
  return context
}

interface SkipLinkProviderProps {
  children: ReactNode
}

export const SkipLinkProvider = ({ children }: SkipLinkProviderProps) => {
  const [targetRef, setTargetRef] = useState<RefObject<HTMLElement> | null>(null)
  return <SkipLinkContext.Provider value={{ targetRef, setTargetRef }}>{children}</SkipLinkContext.Provider>
}
