import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'

import { getElementFromPage, getPage } from '../../../../services/SanityService'
import { IPage, PageName } from '../../../../typeDefinitions'
import { LanguageContext } from '../../../Providers/LanguageProvider'
import { ThemeContext } from '../../../Providers/ThemeProvider'
import styles from './SelectedStopCard.module.scss'

interface ISelectedStopCardProps {
  name: string
  lineId: number
  isSelected: boolean
  stopClosed: boolean
  trackClosedFrom: boolean
  trackClosedTo: boolean
  handleClick: (currentStop: string) => void
}
export default function SelectedStopCard({
  name,
  lineId,
  isSelected,
  stopClosed,
  trackClosedFrom,
  trackClosedTo,
  handleClick,
}: ISelectedStopCardProps) {
  const [isOpenFavouriteNotification, setIsOpenFavouriteNotification] = useState(false)
  let storedFavourites: string[] | null = JSON.parse(localStorage.getItem('Bybanen-favoritter') || '[]')
  const [favourite, setFavourite] = useState(storedFavourites?.includes(modifyName(name, lineId)))
  let timer: NodeJS.Timeout | null = null

  const lang = useContext(LanguageContext)
  const { theme } = useContext(ThemeContext)

  const { data: sanityData } = useQuery<IPage>([PageName.RealtimePage, lang.language], () =>
    getPage(PageName.RealtimePage, lang.language)
  )

  useEffect(() => {
    setFavourite(storedFavourites?.includes(modifyName(name, lineId)))
  }, [lineId])

  function favouriteNotificationTimer(): void {
    setIsOpenFavouriteNotification(true)
    if (timer !== null) {
      clearTimeout(timer)
    }
    timer = setTimeout(setIsOpenFavouriteNotification, 1000, false)
  }

  function handleFavourite(): void {
    favourite ? removeFavourite() : addFavourite()
    setFavourite((prevFavourite) => !prevFavourite)
    favouriteNotificationTimer()
  }

  function addFavourite(): void {
    storedFavourites === null
      ? localStorage.setItem('Bybanen-favoritter', JSON.stringify(modifyName(name, lineId)))
      : localStorage.setItem('Bybanen-favoritter', JSON.stringify([...storedFavourites, modifyName(name, lineId)]))
  }

  function removeFavourite(): void {
    const index = storedFavourites?.indexOf(modifyName(name, lineId))
    if (index !== undefined) {
      storedFavourites?.splice(index, 1)
    }
    localStorage.setItem('Bybanen-favoritter', JSON.stringify(storedFavourites))
  }

  function replaceNorwegianChars(stopName: string) {
    let newStopName = stopName
    newStopName.includes('å') && (newStopName = newStopName.replace('å', 'aa'))
    newStopName.includes('æ') && (newStopName = newStopName.replace('æ', 'ae'))
    newStopName.includes('ø') && (newStopName = newStopName.replace('ø', 'oe'))
    return newStopName
  }

  return (
    <div
      aria-label={name}
      className={isSelected ? `${styles.stopCardSelected} ${styles[theme]}` : `${styles.stopCard} ${styles[theme]}`}
      data-testid="SelectedStopCard"
      onClick={() => handleClick(name)}
    >
      <img
        className={styles.imgWrapper}
        src={`/images/Stops/${replaceNorwegianChars(name)}.webp`}
        alt={
          lang.language === 'no'
            ? `stoppbilde ${replaceNorwegianChars(name)}`
            : `stopimage ${replaceNorwegianChars(name)}`
        }
      />
      <img
        className={styles.favouriteStar}
        onClick={() => handleFavourite()}
        src={favourite ? '/icons/Star-fav.svg' : '/icons/Star-not-fav.svg'}
        alt={lang.language === 'no' ? 'favoritt ikon' : 'favourite icon'}
      />
      <div
        className={isOpenFavouriteNotification ? styles.favouriteNotificationShow : styles.favouriteNotificationHide}
      >
        <span>
          {favourite
            ? getElementFromPage('addedToFav', sanityData, lang.language)
            : getElementFromPage('removedFromFav', sanityData, lang.language)}
        </span>
      </div>
      <div className={styles.divivderAndIconWrapper}>
        {!(name === 'Bergen lufthavn' || name === 'Fyllingsdalen terminal') ? (
          trackClosedFrom ? (
            <div className={styles.dividerTrackClosed} />
          ) : (
            <div className={styles.dividerNormal} />
          )
        ) : (
          <div className={`${styles.dividerLastStop} ${styles[theme]}`} />
        )}
        {isSelected ? (
          <div className={`${styles.dividerIconSelected} ${styles[theme]}`}>
            <img
              src={`/icons/pin-orange-` + `${[theme]}` + `.png`}
              alt={lang.language === 'no' ? 'oransje pin' : 'orange pin'}
            />
          </div>
        ) : trackClosedFrom ? (
          <div className={styles.dividerIconTrackClosedFrom}>
            <img src="/icons/trackClosed.svg" alt="" />
          </div>
        ) : trackClosedTo ? (
          <div className={styles.dividerIconTrackClosedTo}>
            <img src="/icons/trackClosed.svg" alt="" />
          </div>
        ) : stopClosed ? (
          <div className={styles.dividerIconStopClosed}>
            <img src="/icons/stop_closed.svg" alt="" />
          </div>
        ) : (
          <div className={styles.dividerIconNormal} />
        )}
      </div>
      <h2 className={`${styles.headerStop} ${styles[theme]}`}>{name}</h2>
    </div>
  )
}
function modifyName(name: string, lineId: number): string {
  let modifiedName: string = name
  if (lineId != 1) modifiedName += '_' + lineId
  return modifiedName
}
