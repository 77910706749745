import { useSkipLink } from './SkipContext'
import styles from './SkipToContent.module.scss'

export const SkipLink: React.FC = () => {
  const { targetRef } = useSkipLink()

  const handleSkip = () => {
    targetRef?.current?.focus()
    targetRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <button className={styles.skipLinkButton} onClick={handleSkip}>
      Hopp til hovedinnhold
    </button>
  )
}
