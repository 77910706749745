import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useLinesQuery } from '../../CustomHooks/useLineQuery'
import { useSetTitle } from '../../CustomHooks/usePageTitle'
import ClickableStopCard from '../../components/ClickableStopCard/ClickableStopCard'
import { useSkipLink } from '../../components/SkipToContent/SkipContext'
import LineView from './Components/LineViewWrapper'
import styles from './Line.module.scss'

export default function Line() {
  useSetTitle({ en: 'Lines', no: 'Linjer' })

  const myRef = useRef<HTMLDivElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const lineIdqueryparam = Number(searchParams.get('lineId'))
  const [lineId, setLineId] = useState(lineIdqueryparam)
  const navigate = useNavigate()
  const [directions, setDirections] = useState({ directions: [0, 0] })
  const [loading, setLoading] = useState(true)

  const { lines } = useLinesQuery()

  const contentRef = useRef<HTMLDivElement | null>(null)
  const { setTargetRef } = useSkipLink()

  useEffect(() => {
    document.body.tabIndex = -1
    document.body.focus()
    setTargetRef(contentRef)
    return window.scrollTo(0, 0)
  }, [])

  const executeScroll = (refID: string) =>
    document.getElementById(refID)?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' })

  useEffect(() => {
    setLoading(lines ? false : true)
    executeScroll(`lines${lineId}`)
  }, [loading, lines])

  function handleClick(cardNumber: number) {
    if (directions.directions[cardNumber] === 0) {
      setDirections((prevArray) => {
        let newArray = prevArray.directions
        newArray[cardNumber] = 1
        return { directions: newArray }
      })
    } else {
      setDirections((prevArray) => {
        let newArray = prevArray.directions
        newArray[cardNumber] = 0
        return { directions: newArray }
      })
    }
  }

  const navigateToRealtime = (line: number, stop: string) => {
    navigate({
      pathname: '/realtime',
      search: `?lineId=${line}&stopName=${stop}`,
    })
  }

  const allStops = lines?.map((line, outeridx) => {
    return {
      id: line.id,
      firstAndLastStop: [line.stops[0].name, line.stops[line.stops.length - 1].name],
      stopCards: line.stops.map((stop, idx) => {
        return (
          <ClickableStopCard
            isFirst={directions.directions[outeridx] === 0 ? idx === 0 : idx === line.stops.length - 1}
            isLast={directions.directions[outeridx] === 0 ? idx === line.stops.length - 1 : idx === 0}
            stopName={stop.name}
            lineId={stop.line_id}
            handleClick={navigateToRealtime}
            rightIcon="icons/tertiary0-chevron-right.svg"
            key={'ClickableCard' + idx}
          />
        )
      }),
    }
  })

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollLeft >= e.target.clientWidth / 2
    bottom ? setLineId(2) : setLineId(1)
    searchParams.set('lineId', lineId.toString())
    setSearchParams(searchParams)
  }

  return (
    <div className={styles.line} ref={contentRef} tabIndex={-1}>
      <LineView
        mobile
        myRef={myRef}
        handleScroll={handleScroll}
        loading={loading}
        allStops={allStops ?? []}
        handleClick={handleClick}
        directions={directions.directions}
        lineId={lineId}
      />
      <LineView
        myRef={myRef}
        loading={loading}
        allStops={allStops ?? []}
        handleClick={handleClick}
        directions={directions.directions}
        lineId={lineId}
      />
    </div>
  )
}
