import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'

import { API_MOCK_DATA_ALL_LINES } from '../../lib/mockData'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import { variablesForScrollling } from '../../utils/glbVariables'
import FavouriteStopsModal from '../FavouriteStopsModal/FavouriteStopsModal'
import FindingStopModal from '../FindingStopModal/FindingStopModal'
import GenericClickableCard from '../GenericClickableCard/GenericClickableCard'
import { LanguageContext } from '../Providers/LanguageProvider'
import TurnOnGpsGuide from '../TurnOnGPSGuide/TurnOnGPSGuide'
import styles from './CardContainer.module.scss'

interface ICardContainerProps {
  navigateToRealtime: (lineId: number, stopName: string) => void
  styleguide?: boolean
}

export default function CardContainer({ navigateToRealtime, styleguide }: ICardContainerProps) {
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false)
  const [isOpenFavouriteModal, setIsOpenFavouriteModal] = useState(false)
  const [isOpenGpsGuide, setIsOpenGpsGuide] = useState(false)
  const [favouriteStops, setFavouriteStops] = useState<string[] | null>(null)
  const lang = useContext(LanguageContext)
  const [closestStop, setClosestStop] = useState<string | undefined>('')

  const { data: sanityData } = useQuery<IPage>([PageName.HomePage, lang.language], () =>
    getPage(PageName.HomePage, lang.language)
  )

  function isMobile(): boolean {
    return window.screen.width <= variablesForScrollling.screenSize
  }

  useEffect(() => {
    const favouriteStops = localStorage.getItem('Bybanen-favoritter')
    if (favouriteStops) {
      setFavouriteStops(JSON.parse(favouriteStops))
    }
  }, [])

  function euclidianDist(x2: number, x1: number, y2: number, y1: number): number {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
  }

  function findClosestStop(pos: GeolocationCoordinates) {
    // Line in this component needs to be updated to get information from API at build time
    // We do not want to load this every time the user visits the page
    const stops = [...API_MOCK_DATA_ALL_LINES[0].stops, ...API_MOCK_DATA_ALL_LINES[1].stops]
    let shortestDist = euclidianDist(stops[0].latitude, pos.latitude, stops[0].longitude, pos.longitude)
    let stopName = stops[0].name
    let lineId = stops[0].line_id
    stops.forEach((stop) => {
      let currentDist = euclidianDist(stop.latitude, pos.latitude, stop.longitude, pos.longitude)
      if (currentDist < shortestDist) {
        stopName = stop.name
        lineId = stop.line_id
        shortestDist = currentDist
      }
    })
    setClosestStop(stopName)
    navigateToRealtime(lineId, stopName)
  }

  function error(err: any) {
    console.error(err)
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          findClosestStop(pos.coords)
        },
        error,
        { timeout: 2000, maximumAge: 0, enableHighAccuracy: true }
      )
    }
  }

  function openModal(setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>) {
    setIsOpenModal(true)
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflowY = 'auto'
    }
  }

  return (
    <div data-testid="CardContainer">
      <div className={styleguide ? styles.cardContainerStyleguide : styles.cardContainer}>
        <div className={styles.cardContainerSearch}>
          <GenericClickableCard
            handleClick={() => openModal(setIsOpenSearchModal)}
            text={getElementFromPage('pSearch', sanityData, lang.language)}
            rightIcon="/icons/search.svg"
          />
        </div>
        {!(favouriteStops === null) && (
          <div className={styles.cardContainerFavourites}>
            <GenericClickableCard
              text={getElementFromPage('pFavoritesCard', sanityData, lang.language)}
              handleClick={() => openModal(setIsOpenFavouriteModal)}
              rightIcon="/icons/Star-not-fav.svg"
            />
          </div>
        )}
        <div
          className={styles.cardContainerClosestStop}
          aria-label={
            lang.language === 'en'
              ? "Uses the device's location to find the nearest stop"
              : 'Bruker enhetens posisjon til å finne nærmeste stopp'
          }
        >
          <GenericClickableCard
            text={getElementFromPage('pShowClosest', sanityData, lang.language)}
            handleClick={getLocation}
            value={closestStop}
            rightIcon="/icons/pin.png"
          />
        </div>
      </div>
      {isOpenSearchModal && (
        <FindingStopModal setIsOpen={setIsOpenSearchModal} navigateToRealtime={navigateToRealtime} />
      )}
      {isOpenFavouriteModal && (
        <FavouriteStopsModal
          setIsOpen={setIsOpenFavouriteModal}
          navigateToRealtime={navigateToRealtime}
          favouriteStops={favouriteStops ?? []}
        />
      )}
      {isOpenGpsGuide && isMobile() && <TurnOnGpsGuide setIsOpen={setIsOpenGpsGuide} />}
    </div>
  )
}
