import { useQuery } from '@tanstack/react-query'
import fuzzysort from 'fuzzysort'
import { useContext, useRef, useState } from 'react'
import React from 'react'
import ReactDOM from 'react-dom'

import { useEscapeKey } from '../../CustomHooks/useEscapeKey'
import { useOutsideClick } from '../../CustomHooks/useOutsideClick'
import { API_MOCK_DATA_ALL_LINES } from '../../lib/mockData'
import sharedStops from '../../lib/sharedStops'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import ClickableStopCard from '../ClickableStopCard/ClickableStopCard'
import { LanguageContext } from '../Providers/LanguageProvider'
import styles from './FindingStopModal.module.scss'
import SearchStopOptions from './components/SearchStopOptions/SearchStopOptions'

interface IFindingStopModalProps {
  setIsOpen: (isOpen: boolean) => void
  navigateToRealtime?: (lineId: number, stopName: string) => void
  returnStopName?: (stopName: string) => void
}

export default function FindingStopModal({ setIsOpen, navigateToRealtime, returnStopName }: IFindingStopModalProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState('')
  const [sharedStopSelected, setSharedStopSelected] = useState('')
  const lang = useContext(LanguageContext)
  useOutsideClick(handleClose, ref)
  useEscapeKey(handleClose)

  const { data: sanityData } = useQuery<IPage>([PageName.HomePage, lang.language], () =>
    getPage(PageName.HomePage, lang.language)
  )

  function handleClose() {
    return (document.body.style.overflowY = ''), setIsOpen(false)
  }

  let searchResult = fuzzysort.go(search, [...API_MOCK_DATA_ALL_LINES[0].stops, ...API_MOCK_DATA_ALL_LINES[1].stops], {
    keys: ['line_id', 'name'],
    all: false,
  })
  let filteredSearchResult = searchResult.filter(
    (stop) => !(sharedStops.includes(stop.obj.name) && stop.obj.line_id == 2)
  )

  return ReactDOM.createPortal(
    <div className={styles.findingStopModalBackground}>
      <div className={styles.findingStopModal} data-testid="FindingStopModal" ref={ref}>
        <div
          className={styles.findingStopModalTop}
          aria-label={lang.language === 'no' ? 'trykk esc for å lukke søkevindu' : 'press esc to close search modal'}
        >
          <button onClick={() => handleClose()} className={styles.findingStopModalClose}>
            {getElementFromPage('pCloseBtn', sanityData, lang.language)}
          </button>
          <div className={styles.findingStopModalSearch}>
            <form>
              <div className={styles.findingStopModalSearchContainer}>
                <input
                  placeholder={getElementFromPage('inputSearch', sanityData, lang.language)}
                  autoFocus={true}
                  onChange={(e) => setSearch(e.target.value)}
                  className={styles.search}
                  type="text"
                />
                <img src="/icons/search.svg" alt={lang.language === 'no' ? 'søke ikon' : 'search icon'} />
              </div>
            </form>
          </div>
        </div>
        <div className={styles.findingStopModalStoplist}>
          {/* In the fuzzysort the property all=boolean choses if empty string should show all content or not*/}
          {filteredSearchResult.map((stop) => (
            <React.Fragment key={stop.obj.id}>
              <ClickableStopCard
                key={stop.obj.name + '_' + stop.obj.line_id}
                handleClick={(lineId: number, stopName: string) => {
                  if (sharedStops.includes(stop.obj.name)) {
                    if (sharedStopSelected == stop.obj.name) {
                      setSharedStopSelected('')
                    } else {
                      setSharedStopSelected(stop.obj.name)
                    }
                  } else {
                    handleClose()
                    navigateToRealtime?.(lineId, stopName)
                    returnStopName?.(stopName)
                  }
                }}
                stopName={stop.obj.name}
                lineId={stop.obj.line_id}
                rightIcon="/icons/right-arrow.svg"
                fromSearch={true}
              />
              {sharedStopSelected == stop.obj.name && (
                <SearchStopOptions
                  key={stop.obj.name}
                  stopName={stop.obj.name}
                  navigateToRealtime={navigateToRealtime}
                  handleClose={handleClose}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>,
    document.body
  )
}
