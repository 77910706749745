import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useLineQuery } from '../../CustomHooks/useLineQuery'
import { useSetTitle } from '../../CustomHooks/usePageTitle'
import DepartureDetail from '../../components/DepartureDetail/DepartureDetail'
import Direction from '../../components/Direction/Direction'
import HorizontalScrollingCards from '../../components/HorizontalScrollingCards/HorizontalScrollingCards'
import News from '../../components/News/News'
import { ThemeContext } from '../../components/Providers/ThemeProvider'
import { DirectionEnum } from '../../typeDefinitions'
import styles from './Realtime.module.scss'

export default function Realtime() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [lineId, setLineId] = useState(Number(searchParams.get('lineId') as string))
  const [currentStopName, setCurrentStopName] = useState(searchParams.get('stopName') as string)
  const { line } = useLineQuery(lineId)

  const { theme } = useContext(ThemeContext)
  useSetTitle({ en: `Line ${lineId} from ${currentStopName}`, no: `Linje ${lineId} fra ${currentStopName}` }, [
    currentStopName,
  ])
  const currentStop = line?.stops?.find((stop) => stop.name === currentStopName)
  const stopNames: string[] | undefined = line?.stops?.map((stop) => stop.name)

  function handleDataUpdate() {
    if (lineId === 1) {
      setLineId(2)
      searchParams.set('lineId', '2')
    } else {
      setLineId(1)
      searchParams.set('lineId', '1')
    }
    setSearchParams(searchParams)
  }

  useEffect(() => {
    document.body.tabIndex = -1
    document.body.focus()
    return window.scrollTo(0, 0)
  }, [])

  const handleDirectionChange = (direction: DirectionEnum | undefined) => {
    if (direction === undefined) {
      searchParams.delete('direction')
    } else if (direction !== undefined) {
      searchParams.set('direction', direction)
    }
    setSearchParams(searchParams)
  }

  return (
    <div className={`${styles.realtime} ${styles[theme]}`} data-testid="Realtime">
      <div className={`${styles.realtimeScrollableArea} ${styles[theme]}`}>
        {line && stopNames && currentStopName && (
          <HorizontalScrollingCards
            stopNames={stopNames}
            lineId={lineId}
            currentStop={currentStopName}
            setCurrentStop={setCurrentStopName}
          />
        )}
      </div>
      {line && line.stops && currentStopName && (
        <Direction
          onDirectionTabChange={handleDataUpdate}
          lineNum={lineId}
          forwardEndStop={line.stops[line.stops.length - 1]}
          backwardEndStop={line.stops[0]}
          setDirection={handleDirectionChange}
          currentStop={currentStopName}
          startState={searchParams.get('direction') ?? ''}
        />
      )}
      {line && currentStop && (
        <DepartureDetail
          selectedStop={currentStop}
          direction={(searchParams.get('direction') as DirectionEnum | null) ?? undefined}
          lineId={lineId}
        />
      )}
      {line && <News />}
    </div>
  )
}
