import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import { useSetTitle } from '../../CustomHooks/usePageTitle'
import { LanguageContext } from '../../components/Providers/LanguageProvider'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import styles from './Personvern.module.scss'

export default function Personvern() {
  useSetTitle({ en: 'Privacy', no: 'Personvern' })

  const lang = useContext(LanguageContext)

  const { data: sanityData } = useQuery<IPage>([PageName.Personvern, lang.language], () =>
    getPage(PageName.Personvern, lang.language)
  )

  return (
    <>
      {sanityData && (
        <div className={styles.container}>
          <div className={styles.gradient} />
          <div className={styles.personvern}>
            <h1>{getElementFromPage('h1Privacy', sanityData, lang.language)}</h1>
            <div className={styles.personvernTextContainer}>
              <p>{getElementFromPage('pPrivacy', sanityData, lang.language)}</p>
              <h2>{getElementFromPage('h2Location', sanityData, lang.language)}</h2>
              <p>
                {getElementFromPage('pLocation1', sanityData, lang.language)}
                <br></br>
                <br></br>
                {getElementFromPage('pLocation2', sanityData, lang.language)}
              </p>
              <h2>{getElementFromPage('h2Network', sanityData, lang.language)}</h2>
              <p>
                {getElementFromPage('pNetwork1', sanityData, lang.language)}
                <br></br>
                <br></br>
                {getElementFromPage('pNetwork2', sanityData, lang.language)}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
