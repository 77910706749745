import { useQuery } from '@tanstack/react-query'
import { FormEvent, useContext, useEffect, useRef, useState } from 'react'

import { useSetTitle } from '../../CustomHooks/usePageTitle'
import { ApiContext } from '../../components/Providers/ApiProvider'
import { LanguageContext } from '../../components/Providers/LanguageProvider'
import { useSkipLink } from '../../components/SkipToContent/SkipContext'
import Spinner from '../../components/Spinner/Spinner'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import styles from './Feedback.module.scss'
import ToastMessage from './components/ToastMessage'

interface IStateObject {
  name: {
    data: string
    valid: boolean
    empty: boolean
  }
  email: {
    data: string
    valid: boolean
    empty: boolean
  }
  stop: {
    data: string
    valid: boolean
    empty: boolean
  }
  message: {
    data: string
    valid: boolean
    empty: boolean
  }
  images: {
    data: File[]
    valid: boolean
  }
  capthca: {
    data: string
    valid: boolean
    wrongAnswer: boolean
  }
}

export default function Feedback() {
  const lang = useContext(LanguageContext)
  const api = useContext(ApiContext)
  useSetTitle({ en: 'Feedback', no: 'Tilbakemelding' })

  const [numX, setNumX] = useState<number>(0)
  const [numY, setNumY] = useState<number>(0)
  const [sendtFormdata, setSendtFormdata] = useState<boolean>(false)
  const [postFormdataSuccess, setPostFormdataSuccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [formdata, setFormdata] = useState<IStateObject>({
    name: {
      data: '',
      valid: true,
      empty: true,
    },
    email: {
      data: '',
      valid: true,
      empty: true,
    },
    stop: {
      data: '',
      valid: true,
      empty: true,
    },
    message: {
      data: '',
      valid: true,
      empty: true,
    },
    images: {
      data: [],
      valid: true,
    },
    capthca: {
      data: '',
      valid: true,
      wrongAnswer: false,
    },
  })

  const { data: sanityData } = useQuery<IPage>([PageName.Feedback, lang.language], () =>
    getPage(PageName.Feedback, lang.language)
  )

  const contentRef = useRef<HTMLHeadingElement | null>(null)
  const { setTargetRef } = useSkipLink()

  useEffect(() => {
    setNumX(Math.floor(Math.random() * 20))
    setNumY(Math.floor(Math.random() * 20))

    document.body.tabIndex = -1
    document.body.focus()
    setTargetRef(contentRef)
    return window.scrollTo(0, 0)
  }, [])

  function validateInput() {
    const nameRegex = /^[\ a-zA-ZæøåÆØÅÄäÖöáóúüñÑ]+$/
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    const stopRegex = /^[\ a-zA-Z0-9æÆåÅøØÄäÖö.;:,]+$/
    const messageRegex =
      /^[\ -"a-zA-ZæÆåÅøØèÈéÉÄäÖöáóúüñÑ\?\&\:\n\;\(-9\u00a9|\u00ae|{\u2000-\u3300}|\ud83c{\ud000-\udfff}|\ud83d{\ud000-\udfff}|\ud83e{\ud000-\udfff}|\ufe0f]+$/

    const imageFileEndingRegex =
      /\.(png|PNG|jpeg|JPEG|jpg|JPG|heic|HEIC|HEVC|hevc|MOV|mov|MP4|mp4|ASF|asf|AVI|avi|MEP4|mep4)$/

    let validName = true,
      validEmail = true,
      validStop = true,
      validMessage = true,
      validImages = true

    if (!nameRegex.test(formdata.name.data)) {
      setFormdata((prevState) => ({
        ...prevState,
        name: { data: prevState.name.data, valid: false, empty: formdata.name.empty },
      }))
      validName = false
    }

    if (formdata.name.data === '') {
      setFormdata((prevState) => ({
        ...prevState,
        name: { data: prevState.name.data, valid: false, empty: true },
      }))
      validName = false
    }

    if (!emailRegex.test(formdata.email.data)) {
      setFormdata((prevState) => ({
        ...prevState,
        email: { data: prevState.email.data, valid: false, empty: formdata.email.empty },
      }))
      validEmail = false
    }

    if (formdata.email.data === '') {
      setFormdata((prevState) => ({
        ...prevState,
        email: { data: prevState.email.data, valid: false, empty: true },
      }))
      validEmail = false
    }

    if (!stopRegex.test(formdata.stop.data)) {
      setFormdata((prevState) => ({
        ...prevState,
        stop: { data: prevState.stop.data, valid: false, empty: formdata.stop.empty },
      }))
      validStop = false
    }

    if (formdata.stop.data === '') {
      setFormdata((prevState) => ({
        ...prevState,
        stop: { data: prevState.stop.data, valid: false, empty: true },
      }))
      validStop = false
    }

    if (!messageRegex.test(formdata.message.data)) {
      setFormdata((prevState) => ({
        ...prevState,
        message: { data: prevState.message.data, valid: false, empty: formdata.message.empty },
      }))
      validMessage = false
    }

    if (formdata.message.data === '') {
      setFormdata((prevState) => ({
        ...prevState,
        message: { data: prevState.message.data, valid: false, empty: true },
      }))
      validMessage = false
    }

    formdata.images.data.forEach((image) => {
      if (!imageFileEndingRegex.test(image.name)) {
        setFormdata((prevState) => ({ ...prevState, images: { data: prevState.images.data, valid: false } }))
        validImages = false
        return
      }
    })

    return validName && validEmail && validStop && validMessage && validImages
  }

  function validateCapthca() {
    const correctCaptcha: number = numX + numY
    const capthcaRegex = /^\d+$/
    let validCapthca = true

    if (String(correctCaptcha) !== formdata.capthca.data) {
      setFormdata((prevState) => ({
        ...prevState,
        capthca: { data: prevState.capthca.data, valid: false, wrongAnswer: true },
      }))
      validCapthca = false
    }

    if (!capthcaRegex.test(formdata.capthca.data)) {
      setFormdata((prevState) => ({
        ...prevState,
        capthca: { data: prevState.capthca.data, valid: false, wrongAnswer: false },
      }))
      validCapthca = false
    }

    return validCapthca
  }

  async function onFormSubmit(e: FormEvent) {
    e.preventDefault()

    const validInput = validateInput()
    const validCapthca = validateCapthca()

    if (!(validInput && validCapthca)) return

    setIsLoading(true)

    const formDataToBackend = new FormData()

    formdata.images.data.forEach((img) => formDataToBackend.append('Images', img))
    formDataToBackend.append('Name', formdata.name.data)
    formDataToBackend.append('Address', formdata.email.data)
    formDataToBackend.append('Stop', formdata.stop.data)
    formDataToBackend.append('Message', formdata.message.data)

    const responseStatus = await api.postFormdata(formDataToBackend)
    setSendtFormdata(true)

    if (responseStatus >= 200 && responseStatus < 300) {
      setPostFormdataSuccess(true)
    } else {
      setPostFormdataSuccess(false)
    }

    setIsLoading(false)
  }

  if (sendtFormdata) {
    return <ToastMessage success={postFormdataSuccess} />
  }

  return (
    <>
      <div className={styles.container} title="Feedback">
        <div className={styles.gradient} />
        <div className={styles.feedback}>
          <form
            style={{ opacity: isLoading ? '35%' : '100%' }}
            className={styles.feedbackElementContainer}
            onSubmit={onFormSubmit}
          >
            <div className={styles.headers}>
              <h1 ref={contentRef} tabIndex={-1}>
                {getElementFromPage('h1Feedback', sanityData, lang.language)}
              </h1>
              <p>{getElementFromPage('pFeedback1', sanityData, lang.language)}</p>
              <h2>{getElementFromPage('h2Required', sanityData, lang.language)}</h2>
            </div>
            <div className={styles.divLine}>
              <div className={styles.labelAndInput}>
                <label style={{ color: formdata.name.valid ? 'black' : 'red' }}>
                  {formdata.name.valid
                    ? getElementFromPage('f1Label', sanityData, lang.language)
                    : formdata.name.empty
                    ? getElementFromPage('f1ErrorLabel', sanityData, lang.language)
                    : getElementFromPage('wrongInputMessage', sanityData, lang.language)}
                </label>
                <input
                  type="text"
                  placeholder={getElementFromPage('f1Placeholder', sanityData, lang.language)}
                  onChange={(e) =>
                    setFormdata((prevState) => ({
                      ...prevState,
                      name: { data: e.target.value, valid: true, empty: false },
                    }))
                  }
                />
              </div>
              <div className={styles.labelAndInput}>
                <label style={{ color: formdata.email.valid ? 'black' : 'red' }}>
                  {formdata.email.valid
                    ? getElementFromPage('f2Label', sanityData, lang.language)
                    : formdata.email.empty
                    ? getElementFromPage('f2ErrorLabel1', sanityData, lang.language)
                    : getElementFromPage('f2ErrorLabel2', sanityData, lang.language)}
                </label>
                <input
                  type="email"
                  placeholder={getElementFromPage('f2Placeholder', sanityData, lang.language)}
                  onChange={(e) =>
                    setFormdata((prevState) => ({
                      ...prevState,
                      email: { data: e.target.value, valid: true, empty: false },
                    }))
                  }
                />
              </div>
            </div>
            <div className={styles.divLine}>
              <div className={styles.labelAndInput}>
                <label style={{ color: formdata.stop.valid ? 'black' : 'red' }}>
                  {formdata.stop.valid
                    ? getElementFromPage('f3Label', sanityData, lang.language)
                    : formdata.stop.empty
                    ? getElementFromPage('f3ErrorLabel', sanityData, lang.language)
                    : getElementFromPage('wrongInputMessage', sanityData, lang.language)}
                </label>
                <input
                  type="text"
                  placeholder={getElementFromPage('f3Placeholder', sanityData, lang.language)}
                  onChange={(e) =>
                    setFormdata((prevState) => ({
                      ...prevState,
                      stop: { data: e.target.value, valid: true, empty: false },
                    }))
                  }
                />
              </div>
            </div>
            <div className={styles.divLine}>
              <div className={styles.labelAndInput}>
                <label style={{ color: formdata.message.valid ? 'black' : 'red' }}>
                  {formdata.message.valid
                    ? getElementFromPage('f4Label', sanityData, lang.language)
                    : formdata.message.empty
                    ? getElementFromPage('f4ErrorLabel', sanityData, lang.language)
                    : getElementFromPage('wrongInputMessage', sanityData, lang.language)}
                </label>
                <textarea
                  onChange={(e) =>
                    setFormdata((prevState) => ({
                      ...prevState,
                      message: { data: e.target.value, valid: true, empty: false },
                    }))
                  }
                  placeholder={getElementFromPage('f4Placeholder', sanityData, lang.language)}
                />
              </div>
            </div>
            <div className={styles.divLine}>
              <div className={styles.labelAndInput}>
                <label style={{ color: formdata.images.valid ? 'black' : 'red' }}>
                  {formdata.images.valid
                    ? getElementFromPage('f5Label', sanityData, lang.language)
                    : getElementFromPage('f5ErrorLabel', sanityData, lang.language)}
                </label>
                <input
                  onChange={(e) =>
                    setFormdata((prevState) => ({
                      ...prevState,
                      images: {
                        data: Array.from(e.target.files ?? []),
                        valid: true,
                      },
                    }))
                  }
                  accept="image/*"
                  type="file"
                  multiple
                />
              </div>
              <div className={styles.labelAndInput}>
                <label style={{ color: formdata.capthca.valid ? 'black' : 'red' }} className={styles.captchaLabel}>
                  {formdata.capthca.valid
                    ? '‎'
                    : formdata.capthca.wrongAnswer === true
                    ? getElementFromPage('f6ErrorLabel2', sanityData, lang.language)
                    : formdata.capthca.data === ''
                    ? getElementFromPage('f6ErrorLabel3', sanityData, lang.language)
                    : getElementFromPage('f6ErrorLabel1', sanityData, lang.language)}
                </label>
                <div className={styles.captcha}>
                  <div className={styles.inline}>
                    <p>
                      {numX} + {numY} ={' '}
                    </p>
                    <input
                      maxLength={2}
                      pattern="[1-9][0-9]{2}$"
                      onChange={(e) =>
                        setFormdata((prevState) => ({
                          ...prevState,
                          capthca: { data: e.target.value, valid: true, wrongAnswer: false },
                        }))
                      }
                    />
                  </div>

                  <button type="submit" onClick={(e) => onFormSubmit(e)}>
                    {isLoading ? <Spinner /> : getElementFromPage('submitButton', sanityData, lang.language)}
                  </button>
                </div>
              </div>
            </div>
            <p>{getElementFromPage('pFeedback2', sanityData, lang.language)}</p>
          </form>
        </div>
      </div>
    </>
  )
}
