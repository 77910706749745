import { differenceInMilliseconds } from 'date-fns'

import { DirectionEnum, IEstimatedCall, ILine, IStop } from '../typeDefinitions'
import { formatTime } from '../utils/TimeUtilites'

export interface INextDeparturesPayload {
  id: number
  backwards_id: number
  name: string
  value: string
}

export interface IArrivalOffset {
  id: number
  backward_id: number
  timeOffsetToNextStop: number
  timeOffsetToPreviousStop: number
}

export interface IItineraryService {
  /**
   * Get the remaining stops by direction
   * @param selectedStop
   * @param direction
   * @param line
   * @param includeCurrent
   * @returns IStop[]
   */
  getStopsByDirection(selectedStop: IStop, direction?: DirectionEnum, line?: ILine, includeCurrent?: boolean): IStop[]

  /**
   * Calculates and adds the arrival time for the passed IStop[] based on the current time argument
   * and the timespan between the various stations
   * @param direction
   * @param stops
   * @param currentTime
   * @returns
   */
  getArrivals(direction: DirectionEnum, stops: IStop[], currentTime: string): INextDeparturesPayload[]

  /**
   * Calculates the time offset in milliseconds between the various stops
   * @param direction
   * @param stops
   * @returns IArrivalOffset[]
   */
  calculateArrivalOffsets(direction: DirectionEnum, stops: IStop[]): IArrivalOffset[]

  /**
   * Temporary solution to faulty Byparken departure times.
   * TODO: Delete this when Byparken times are correct AB#483
   * @param nonneseterStop IStop object for Nonneseter
   */
  byparkenEstimatedCallsHack(nonneseterStop: IStop, forward: boolean): IEstimatedCall[]
}

export class ItineraryService implements IItineraryService {
  getStopsByDirection(selectedStop: IStop, direction?: DirectionEnum, line?: ILine, includeCurrent = true): IStop[] {
    const filteredAndSortedStops =
      direction && line
        ? line.stops
            .filter((stop) =>
              direction === DirectionEnum.Forward ? stop.order > selectedStop.order : stop.order < selectedStop.order
            )
            .sort((stopA, stopB) => (direction === DirectionEnum.Forward ? stopA.order - stopB.order : stopB.order - stopA.order))
        : []

    if (includeCurrent) filteredAndSortedStops.unshift(selectedStop)
    return filteredAndSortedStops
  }

  getArrivals(direction: DirectionEnum, stops: IStop[], currentTime: string): INextDeparturesPayload[] {
    const result: INextDeparturesPayload[] = []
    const calculatedArrivals = this.calculateArrivalOffsets(direction, stops)
    let currentTimeInMiliseconds = Date.parse(currentTime)
    for (let index = 0; index < stops.length; index++) {
      const stop = stops[index]
      const arrival = calculatedArrivals.find((arrival) => arrival.id === stop.id)
      if (!arrival) return result

      // if current stop is first, offset should not be added to current time
      currentTimeInMiliseconds += index === 0 ? 0 : arrival.timeOffsetToPreviousStop

      result.push({
        id: stop.id,
        backwards_id: stop.backward_id,
        name: stop.name,
        value: formatTime(currentTimeInMiliseconds),
      })
    }
    return result
  }

  // TODO: Delete this when Byparken times are correct AB#483
  byparkenEstimatedCallsHack(nonneseterStop: IStop, forward: boolean) {
    return forward
      ? nonneseterStop.estimated_calls_forward.map((call) => {
          let calculatedDate = new Date(call.expected_arrival_time)
          calculatedDate.setMinutes(calculatedDate.getMinutes() - 1)
          return {
            aimed_arrival_time: call.aimed_arrival_time,
            aimed_departure_time: call.aimed_departure_time,
            expected_arrival_time: call.expected_arrival_time,
            expected_departure_time: calculatedDate.toISOString(),
            occupancy: call.occupancy,
          } as IEstimatedCall
        })
      : nonneseterStop.estimated_calls_backward.map((call) => {
          let calculatedDate = new Date(call.expected_departure_time)
          calculatedDate.setMinutes(calculatedDate.getMinutes() + 1)
          return {
            aimed_arrival_time: call.aimed_arrival_time,
            aimed_departure_time: call.aimed_departure_time,
            expected_arrival_time: calculatedDate.toISOString(),
            expected_departure_time: call.expected_departure_time,
            occupancy: call.occupancy,
          } as IEstimatedCall
        })
  }

  calculateArrivalOffsets(direction: DirectionEnum, stops: IStop[]): IArrivalOffset[] {
    const result: IArrivalOffset[] = []

    for (let index = 0; index < stops.length; index++) {
      const current = stops[index]
      let offset = 0

      if (index + 1 !== stops.length) {
        const next = stops[index + 1]

        let currentEstimatedCallsToUse =
          direction === DirectionEnum.Forward ? current.estimated_calls_forward : current.estimated_calls_backward

        let nextEstimatedCallsToUse =
          direction === DirectionEnum.Forward
            ? index + 2 !== stops.length // needed because endstops only have estimated_calls for the other direction
              ? next.estimated_calls_forward
              : next.estimated_calls_backward
            : index + 2 !== stops.length // needed because endstops only have estimated_calls for the other direction
            ? next.estimated_calls_backward
            : next.estimated_calls_forward

        // TODO: Delete these 'if' blocks when Byparken times are correct AB#483
        if (current.name === 'Byparken') {
          currentEstimatedCallsToUse = this.byparkenEstimatedCallsHack(next, true)
        }
        if (next.name === 'Byparken') {
          nextEstimatedCallsToUse = this.byparkenEstimatedCallsHack(current, false)
        }

        offset =
          index === 0 // if current stop we need to use departure time instead of arrival time
            ? this.getOffset(
                currentEstimatedCallsToUse,
                nextEstimatedCallsToUse,
                (estimatedCall) => estimatedCall.expected_departure_time
              )
            : this.getOffset(currentEstimatedCallsToUse, nextEstimatedCallsToUse)
      }

      result.push({
        id: current.id,
        backward_id: current.backward_id,
        timeOffsetToNextStop: offset,
        timeOffsetToPreviousStop: index > 0 ? result[index - 1].timeOffsetToNextStop : 0,
      })
    }

    return result
  }

  private getOffset(
    first: IEstimatedCall[],
    second: IEstimatedCall[],
    propFunction: (estimatedCall: IEstimatedCall) => string = (estimatedCall) => estimatedCall.expected_arrival_time
  ): number {
    const firstDate = first.map(propFunction).sort((callA, callB) => Date.parse(callA) - Date.parse(callB))[0]
    const secondDate = second
      .map((estimatedCall) => estimatedCall.expected_arrival_time)
      .sort((callA, callB) => Date.parse(callA) - Date.parse(callB))
      .filter((expectedArrivalTime) => expectedArrivalTime > firstDate)[0]

    return differenceInMilliseconds(new Date(secondDate), new Date(firstDate))
  }
}
