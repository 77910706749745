import { useQuery } from '@tanstack/react-query'
import { useContext, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useEscapeKey } from '../../CustomHooks/useEscapeKey'
import { useOutsideClick } from '../../CustomHooks/useOutsideClick'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import FavouriteStops from '../FavouriteStops/FavouriteStops'
import { LanguageContext } from '../Providers/LanguageProvider'
import styles from './FavouriteStopsModal.module.scss'

interface IFavouriteStopsModalModalProps {
  setIsOpen: (isOpen: boolean) => void
  navigateToRealtime: (lineId: number, stopName: string) => void
  favouriteStops: string[]
}

export default function FavouriteStopsModal({
  setIsOpen,
  navigateToRealtime,
  favouriteStops,
}: IFavouriteStopsModalModalProps) {
  const ref = useRef<HTMLDivElement>(null)
  const lang = useContext(LanguageContext)

  const { data: sanityData } = useQuery<IPage>([PageName.HomePage, lang.language], () =>
    getPage(PageName.HomePage, lang.language)
  )

  useOutsideClick(handleClose, ref)
  useEscapeKey(handleClose)

  function handleClose() {
    return (
      (document.body.style.overflowY = ''),
      setIsOpen(false)
    )
  }

  return ReactDOM.createPortal(
    <div className={styles.favouriteStopsModalBackground}>
      <div className={styles.favouriteStopsModal} data-testid="FindingStopModal" ref={ref}>
        <div className={styles.favouriteStopsModalTop}>
          <h3>{getElementFromPage('pFavoritesCard', sanityData, lang.language)}</h3>
          <p onClick={() => handleClose()} className={styles.favouriteStopsModalClose}>
            {getElementFromPage('pCloseBtn', sanityData, lang.language)}
          </p>
        </div>
        <FavouriteStops
          stopList={favouriteStops}
          handleClick={(lineId: number, stopName: string) => {
            handleClose()
            navigateToRealtime(lineId, stopName)
          }}
        />
      </div>
    </div>,
    document.body
  )
}
