import sanityClient from '../client'
import { LanguageType } from '../components/Providers/LanguageProvider'
import { IArt, ILocaleString, ILocaleText, IPage, PageName } from '../typeDefinitions'

export async function getArt(): Promise<IArt[]> {
  interface ISanityArt {
    title: string
    buttonText: ILocaleString
    link: string
    text: ILocaleText
    image: {
      asset: {
        url: string
      }
    }
  }

  const res = await sanityClient.fetch<ISanityArt[]>(
    `*[_type == "art"] {
        title,
        buttonText,
        link,
        text,
        image { //TODO: Use sanity url builder
            asset -> {
            url
            }
        },
    }`
  )

  return res.map((art) => ({
    imageUrl: art.image.asset.url,
    text: art.text,
    title: art.title,
    link: art.link,
    buttonText: art.buttonText,
  }))
}

export async function getPage(pageName: PageName, language: LanguageType): Promise<IPage> {
  const res = await sanityClient.fetch<IPage[]>(
    `*[_type == "pages" && pageName.elementId == "${pageName}"] {
        elements[]{elementId, en, no}
    }`
  )

  return res[0]
}

export function getElementFromPage(
  elementId: string,
  page: IPage | undefined,
  language: LanguageType
): string | undefined {
  return page && page.elements.find((elem) => elem.elementId === elementId)?.[language]
}

export function getRandomArtFromArtArray(arts: IArt[] | undefined, language: LanguageType): IArt | undefined {
  return arts && arts[Math.random() * arts.length]
}
