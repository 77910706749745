import { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { KEY_NAME_ARROWLEFT, KEY_NAME_ARROWRIGHT, useArrowKeys } from '../../CustomHooks/useArrowKeys'
import { variablesForScrollling } from '../../utils/glbVariables'
import { LanguageContext } from '../Providers/LanguageProvider'
import { ThemeContext } from '../Providers/ThemeProvider'
import styles from './HorizontalScrollingCards.module.scss'
import SelectedStopCard from './components/SelectedStopCard/SelectedStopCard'

interface IHorizontalScrollingCardsProps {
  stopNames: string[]
  lineId: number
  currentStop: string
  setCurrentStop: React.Dispatch<React.SetStateAction<string>>
}

export default function HorizontalScrollingCards({
  stopNames,
  lineId,
  currentStop,
  setCurrentStop,
}: IHorizontalScrollingCardsProps) {
  const refs = useRef<(HTMLDivElement | null)[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const { theme } = useContext(ThemeContext)
  const lang = useContext(LanguageContext)

  let timer: NodeJS.Timeout | null = null

  useArrowKeys(handleArrowKeys, [KEY_NAME_ARROWLEFT, KEY_NAME_ARROWRIGHT])

  function handleArrowKeys(keyName: string) {
    if (stopNames && currentStop) {
      let newStop = currentStop
      const currentStopIndex = stopNames.indexOf(newStop)
      let ref = refs.current.at(currentStopIndex)

      if (keyName === KEY_NAME_ARROWLEFT && currentStopIndex - 1 >= 0) {
        newStop = stopNames[currentStopIndex - 1]
        ref = refs.current.at(currentStopIndex - 1)
      } else if (keyName === KEY_NAME_ARROWRIGHT && currentStopIndex + 1 < stopNames.length) {
        newStop = stopNames[currentStopIndex + 1]
        ref = refs.current.at(currentStopIndex + 1)
      }
      ref?.focus()
      setStop(newStop, variablesForScrollling.scrollDelayTime1000)
      executeScroll(ref)
    }
  }

  function setStop(stopName: string, scrollDisableTime: number) {
    if (timer !== null) {
      clearTimeout(timer)
    }
    setLoading(true)
    searchParams.set('lineId', String(lineId))
    searchParams.set('stopName', stopName)
    setSearchParams(searchParams)
    timer = setTimeout(setLoading, scrollDisableTime, false)
    setCurrentStop(stopName)
  }

  useEffect(() => {
    if (currentStop === 'Kronstad') {
      setLoading(true)
      const idx = stopNames.indexOf('Kronstad')
      setTimeout(() => {
        executeScrollWithIdx(idx >= 0 ? idx : 0)
      }, 250)
      setLoading(false)
    }
  }, [lineId])

  useEffect(() => {
    setLoading(true)
    const idx = stopNames.indexOf(currentStop)
    executeScrollWithIdx(idx >= 0 ? idx : 0)
    timer = setTimeout(setLoading, variablesForScrollling.scrollDelayTime1000, false)
  }, [])

  function executeScroll(ref: HTMLDivElement | null | undefined) {
    setTimeout(() => ref?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' }))
  }

  function executeScrollWithIdx(idx: number) {
    executeScroll(refs.current.at(idx))
  }

  function handleCardClick(stopName: string) {
    setStop(stopName, variablesForScrollling.scrollDelayTime600)
    executeScrollWithIdx(stopNames.indexOf(stopName))
  }

  function handleScroll(scrollEvent: any) {
    const cardWidth = variablesForScrollling.normalCardWidth
    const bigCardWidth = variablesForScrollling.bigCardWidth
    const marginWidth = variablesForScrollling.paddingBetween

    const scrollPos = scrollEvent.target.scrollLeft
    const numberOfCards = stopNames.length
    const currentStopIndex = stopNames.indexOf(currentStop)

    let lowerThreshold = 0
    let upperThreshold = 0
    for (let i = 0; i < numberOfCards; i++) {
      // Tablet/dekstop breakpoint
      if (window.screen.width >= variablesForScrollling.screenSize) {
        if (i === currentStopIndex) {
          upperThreshold += bigCardWidth + marginWidth
        } else {
          upperThreshold += cardWidth + marginWidth
        }
        if (stopNames && scrollPos >= lowerThreshold && scrollPos <= upperThreshold) {
          setStop(stopNames[i], variablesForScrollling.scrollDelayTime500)
          executeScrollWithIdx(i)
        }

        lowerThreshold = upperThreshold + 0.1
        // Mobile breakpoint
      } else {
        upperThreshold += cardWidth + marginWidth
        if (stopNames && scrollPos >= lowerThreshold && scrollPos <= upperThreshold) {
          setStop(stopNames[i], variablesForScrollling.scrollDelayTime500)
        }
        lowerThreshold = upperThreshold + 0.1
      }
    }
  }

  return (
    <>
      <div className={`${styles.backgroundDivider} ${styles[theme]}`} />
      <div
        aria-label={
          lang.language === 'en'
            ? 'List of stops. Use arrow right and left to navigate. Use tab to proceed to select direction'
            : 'Liste av stop. Bruk pil høyre og venstre for å navigere. Bruk tab for å gå videre til å velge retning'
        }
        className={styles.horizontalScrollingCardContainer}
        tabIndex={0}
        data-testid="HorizontalScrollingCards"
        onScroll={(scrollEvent) => {
          if (!loading) {
            if (timer !== null) {
              clearTimeout(timer)
            }
            timer = setTimeout(handleScroll, 75, scrollEvent)
          }
        }}
      >
        {stopNames.map((stopName: string, index) => (
          <div tabIndex={-1} ref={(el) => (refs.current[index] = el)} key={index} id={`scrollableCard${stopName}`}>
            <SelectedStopCard
              name={stopName}
              lineId={lineId}
              isSelected={stopName === currentStop}
              handleClick={handleCardClick}
              stopClosed={false}
              trackClosedFrom={false}
              trackClosedTo={false}
            />
          </div>
        ))}
      </div>
    </>
  )
}
