import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { useSetTitle } from '../../CustomHooks/usePageTitle'
import { LanguageContext } from '../../components/Providers/LanguageProvider'
import { getElementFromPage, getPage } from '../../services/SanityService'
import { IPage, PageName } from '../../typeDefinitions'
import styles from './Page404.module.scss'

export default function Page404() {
  useSetTitle({ en: 'Error page', no: 'Feilmelding' })

  const lang = useContext(LanguageContext)

  const { data: sanityData } = useQuery<IPage>([PageName.ErrorPage, lang.language], () =>
    getPage(PageName.ErrorPage, lang.language)
  )

  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.page404} data-testid="Page404">
        <h1>{getElementFromPage('h1Error', sanityData, lang.language)}</h1>
        <div className={styles.page404TextWrapper}>
          <h2>Vi kan dessverre ikke finne siden du leter etter.</h2>
          <span className={styles.page404BodyText}>Her er noen lenker som kan kanskje vil være til hjelp:</span>
        </div>
        <div className={styles.page404LinkContainer}>
          <Link className={styles.page404LinkContainerLink} to={'/'}>
            {getElementFromPage('aHome', sanityData, lang.language)}
            <img className={styles.footerIconArrow} src="icons/footerPilRett.svg" alt="" />
          </Link>
          <a className={styles.page404LinkContainerLink} href="https://www.bybanen.no/kontakt-oss/">
            {getElementFromPage('aContact', sanityData, lang.language)}
            <img className={styles.footerIconArrow} src="icons/footerPilRett.svg" alt="" />
          </a>
          <a className={styles.page404LinkContainerLink} href="https://www.bybanen.no/kontakt-oss/">
            {getElementFromPage('aFaq', sanityData, lang.language)}
            <img className={styles.footerIconArrow} src="icons/footerPilRett.svg" alt="" />
          </a>
        </div>
      </div>
      <p className={styles.errorMessage}>{getElementFromPage('pMessage', sanityData, lang.language)}</p>
    </div>
  )
}
