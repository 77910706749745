import { useQuery } from '@tanstack/react-query'
import { useContext, useState } from "react"
import { useLineQuery } from "../../../../CustomHooks/useLineQuery"
import { DirectionEnum } from "../../../../typeDefinitions"
import { IPage, PageName } from '../../../../typeDefinitions'
import { getElementFromPage, getPage } from '../../../../services/SanityService'
import SearchStopDirection from "../SearchStopDirection/SearchStopDirection"
import { LanguageContext } from '../../../Providers/LanguageProvider'
import styles from './SearchStopOptions.module.scss'

interface ISearchStopOptionsProps {
    stopName: string
    navigateToRealtime?: (lineId: number, stopName: string) => void
    handleClose: () => void
}

export default function SearchStopOptions({ stopName, navigateToRealtime, handleClose } : ISearchStopOptionsProps) {
    const line1 = useLineQuery(1).line
    const line2 = useLineQuery(2).line
    const [direction, setDirection] = useState<DirectionEnum | undefined>(undefined)
    const lang = useContext(LanguageContext)
    const { data: sanityData } = useQuery<IPage>([PageName.HomePage, lang.language], () =>
    getPage(PageName.HomePage, lang.language)
  )

    return (
        <div className={styles.findingStopModalOptionsContainer}>
            <h4 className={styles.findingStopModalOptionsHeader}>
            {getElementFromPage('h4SelectDestination', sanityData, lang.language)}
            {direction}
            </h4>
            {(line1 && line2) &&
                <>
                    <SearchStopDirection
                        forwardEndStop={line1.stops[line1.stops.length - 1]}
                        backwardEndStop={line1.stops[0]}
                        setDirection={setDirection}
                        currentStop={stopName}
                        lineId={1}
                        navigateToRealtime={navigateToRealtime}
                        handleClose={handleClose}
                    />
                    <SearchStopDirection
                        forwardEndStop={line2.stops[line2.stops.length - 1]}
                        backwardEndStop={line2.stops[0]}
                        setDirection={setDirection}
                        currentStop={stopName}
                        lineId={2}
                        navigateToRealtime={navigateToRealtime}
                        handleClose={handleClose}
                    />
                </>
            }
        </div>
    )
}