import { RefObject } from 'react'

import { IAllStops } from '../../../typeDefinitions'
import LineViewCard from './LineViewCard'
import styles from './LineViewMobile.module.scss'

interface IMobileView {
  handleScroll: (e: any) => void
  loading: boolean
  allStops: IAllStops[]
  myRef: RefObject<HTMLDivElement>
  handleClick: (cardNumber: number) => void
  directions: number[]
  lineId: number
}

export function MobileView({ handleScroll, loading, allStops, myRef, handleClick, directions, lineId }: IMobileView) {
  return (
    <div className={styles.allLinesContainer}>
      <div className={styles.linesContainer} onScroll={handleScroll}>
        {loading ? (
          <span>Loading</span>
        ) : (
          allStops?.map((line, index) => (
            <LineViewCard
              line={line}
              key={index}
              idx={index}
              lineRef={myRef}
              handleClick={() => handleClick(lineId - 1)}
              directions={directions}
              mobile
            />
          ))
        )}
      </div>
      <div className={styles.coloredDots}>
        {lineId === 1 ? (
          <img src="/icons/orangeDot.svg" alt="orange dot" />
        ) : (
          <img src="/icons/greyDot.svg" alt="grey dot" />
        )}
        {lineId === 2 ? (
          <img src="/icons/orangeDot.svg" alt="orange dot" />
        ) : (
          <img src="/icons/greyDot.svg" alt="grey dot" />
        )}
      </div>

      <div className={styles.container}>
        {lineId - 1 === 0
          ? directions[0] === 0
            ? allStops.at(0)?.stopCards
            : allStops.at(0)?.stopCards.map((_e, i, a) => a[a.length - 1 - i])
          : directions[1] === 0
          ? allStops.at(1)?.stopCards
          : allStops.at(1)?.stopCards.map((_e, i, a) => a[a.length - 1 - i])}
      </div>
    </div>
  )
}
