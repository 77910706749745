/** Sanity models */
export interface IPage {
  elements: Array<ILocaleString | ILocaleText | IImage>
  elementsId: PageName
}

export enum PageName {
  HomePage = 'homePage',
  RealtimePage = 'realtimePage',
  Personvern = 'privacyPage',
  LinePage = 'linePage',
  ErrorPage = 'errorPage',
  Layout = 'layout',
  Feedback = 'feedbackPage',
}

export interface IImage {
  no: string
  en: string
  elementId: string
}

export interface ILocaleString {
  no: string
  en: string
  elementId: string
}

export interface ILocaleText {
  no: string
  en: string
  elementId: string
}

export interface IArt {
  imageUrl: string
  text: ILocaleText
  title: string
  link: string
  buttonText: ILocaleString
}

/** Domain models */
export interface ILine {
  id: number
  name: string
  destination_stop: number
  stops: IStop[]
}

export interface IStop {
  id: number
  backward_id: number
  line_id: number
  order: number
  latitude: number
  longitude: number
  name: string
  response_timestamp: string
  estimated_calls_forward: IEstimatedCall[]
  estimated_calls_backward: IEstimatedCall[]
}

export interface IEstimatedCall {
  expected_departure_time: string
  aimed_departure_time: string
  expected_arrival_time: string
  aimed_arrival_time: string
  occupancy: OccupancyEnum
}

export interface ITram {
  time_stamp: string
  tram_id: number
  stop_id: number
  stop_name: string
  next_stop_id: number
  occupancy?: OccupancyEnum
  time_to_next_stop: string | null
  departure_time: string
  arrival_time: string
  line_number: number
  direction: DirectionEnum
  monitored: boolean
  progress_between_stop: number
  distance_between_stops: number
  monitored_call: IMonitoredCall
}

export enum DirectionEnum {
  Backward = 'backward', // (line 1: direction towards Byparken)
  Forward = 'forward', // (line 1 : direction towards Bergen lufthavn )
}

export interface IMonitoredCall {
  stopPointRef: number
  order: number
  stopPointName: string
  vehicleAtStop: boolean
  aimedArrivalTime: string
  actualArrivalTime: string
  actualArrivalTimeSpecified: boolean
  expectedArrivalTime: string
  expectedArrivalTimeSpecified: boolean
  arrivalPlatformName: string
  aimedDepartureTime: string
  actualDepartureTime: string
  actualDepartureTimeSpecified: boolean
  expectedDepartureTime: string
  expectedDepartureTimeSpecified: boolean
  departurePlatformName: string
}

export enum OccupancyEnum {
  Unknown = 'unknown',
  ManySeatsAvailable = 'manySeatsAvailable', // more than 50% of seats available (green color)
  SeatsAvailable = 'seatsAvailable', // less than 50% of seats available (green color)
  StandingAvailable = 'standingAvailable', // less than 10% of seats available (orange color)
  Full = 'full', // close to or at full capacity (red color)
  NotAcceptingPassengers = 'notAcceptingPassengers',
}

export interface IDeviationMessage {
  id: number
  line_id: number
  message: string
  time_stamp: string
  valid_from: string
  valid_to: string
  is_public: boolean
}

/** Other Types */
export interface IAllStops {
  id: number
  firstAndLastStop: string[]
  stopCards: React.ReactNode[]
}
