import { useQuery } from '@tanstack/react-query'
import { useContext, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useOutsideClick } from '../../../CustomHooks/useOutsideClick'
import { getElementFromPage, getPage } from '../../../services/SanityService'
import { IPage, PageName } from '../../../typeDefinitions'
import { LanguageContext } from '../../Providers/LanguageProvider'
// import { ThemeContext  } from '../../Providers/ThemeProvider'
import styles from './Header.module.scss'

export default function Header() {
  const ref = useRef<HTMLHeadElement>(null)
  const [extendNavbar, setExtendNavbar] = useState(false)
  const lang = useContext(LanguageContext)
  const location = useLocation()
  // const { theme, setTheme } = useContext(ThemeContext);

  useOutsideClick(handleClose, ref)

  const { data: sanityData } = useQuery<IPage>([PageName.Layout, lang.language], () =>
    getPage(PageName.Layout, lang.language)
  )

  function handleClose() {
    setExtendNavbar(false)
  }

  return (
    <header className={styles.header} ref={ref}>
      <nav className={styles.navbarInnerContainer}>
        <div className={styles.leftContainer}>
          <Link aria-label={lang.language === 'en' ? 'Back to homepage' : 'Gå tilbake til hjemmeside'} to="/">
            <img className={styles.logo} src="/images/logo.svg" alt="Bybane logo" />
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <Link
            className={location.pathname === '/line' ? styles.navbarLinkLine : styles.navbarLink}
            to="/line?lineId=1"
          >
            {getElementFromPage('headerLineOverview', sanityData, lang.language)}
          </Link>
          <Link
            className={location.pathname === '/tilbakemelding' ? styles.navbarLinkLine : styles.navbarLink}
            to="/tilbakemelding"
          >
            {getElementFromPage('footerContactUs', sanityData, lang.language)}
          </Link>
          <button
            className={styles.languageButtonDesktop}
            onClick={lang.language === 'no' ? () => lang.setLanguage('en') : () => lang.setLanguage('no')}
          >
            {lang.language === 'no' ? 'English' : 'Norsk'}
          </button>

          {/* <img className={styles.darkmodeIcon} src={`${"/icons/Darkmode-"}${[theme]}${".svg"}`} onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}/> */}

          <button
            className={styles.hamburgerButton}
            onClick={() => {
              setExtendNavbar((curr) => !curr)
            }}
            aria-label={lang.language === 'en' ? 'Menu button' : 'Meny knapp'}
            tabIndex={0}
          >
            {extendNavbar ? (
              <img
                src="/icons/hamburgerOpen.svg"
                alt={lang.language === 'no' ? 'lukk navigasjonsmeny' : 'close navigation menu'}
              />
            ) : (
              <img
                src="/icons/hamburgerClosed.svg"
                alt={lang.language === 'no' ? 'åpne navigasjonsmeny' : 'open navigation menu'}
                tabIndex={0}
              />
            )}
          </button>
        </div>
      </nav>

      {extendNavbar && (
        <div className={styles.navbarExtenderContainer}>
          <Link to="/line?lineId=1" onClick={() => setExtendNavbar(false)}>
            {getElementFromPage('headerLineOverview', sanityData, lang.language)}
            <img className={styles.navbarExtendedLinkImg} src="/icons/vector.svg" alt="" />
          </Link>
          <Link to="/tilbakemelding" onClick={() => setExtendNavbar(false)}>
            {getElementFromPage('footerContactUs', sanityData, lang.language)}
            <img className={styles.navbarExtendedLinkImg} src="/icons/vector.svg" alt="" />
          </Link>
          <button
            className={styles.languageButtonMobile}
            onClick={lang.language === 'no' ? () => lang.setLanguage('en') : () => lang.setLanguage('no')}
          >
            {lang.language === 'no' ? 'English' : 'Norsk'}
          </button>
        </div>
      )}
    </header>
  )
}
