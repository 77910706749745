import SyncLoader from 'react-spinners/PuffLoader'

import styles from './Spinner.module.scss'

interface ISpinnerProps {
  isTab?: boolean
}

export default function Spinner({ isTab }: ISpinnerProps) {
  return (
    <div className={isTab ? styles.tabSpinner : styles.spinner}>
      <SyncLoader size={isTab ? 10 : 50} loading={true} aria-label="Loading Spinner" data-testid="Spinner" />
    </div>
  )
}
