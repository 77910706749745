import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import './App.scss'
import Layout from './components/Layout/Layout'
import ApiProvider from './components/Providers/ApiProvider'
import LanguageProvider from './components/Providers/LanguageProvider'
import ServiceCollectionProvider from './components/Providers/ServicesProvider'
import { ThemeProvider } from './components/Providers/ThemeProvider'
import { SkipLinkProvider } from './components/SkipToContent/SkipContext'
import Feedback from './pages/Feedback/Feedback'

/* import AdminPage from './pages/AdminPage/AdminPage' */
import Homepage from './pages/Homepage/Homepage'
import Line from './pages/Line/Line'
import Page404 from './pages/Page404/Page404'
import Personvern from './pages/Personvern/Personvern'
import Realtime from './pages/Realtime/Realtime'

const queryClient = new QueryClient()

export default function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ApiProvider>
          <ServiceCollectionProvider>
            <LanguageProvider>
              <ThemeProvider>
                <SkipLinkProvider>
                  <Layout>
                    <Routes>
                      <Route index element={<Homepage />} />
                      <Route path="line" element={<Line />} />
                      <Route path="realtime" element={<Realtime />} />
                      {/* <Route path="admin" element={<AdminPage />} /> */}
                      <Route path="personvern" element={<Personvern />} />
                      <Route path="*" element={<Page404 />} />
                      <Route path="tilbakemelding" element={<Feedback />} />
                    </Routes>
                  </Layout>
                </SkipLinkProvider>
              </ThemeProvider>
            </LanguageProvider>
          </ServiceCollectionProvider>
        </ApiProvider>
      </QueryClientProvider>
    </Router>
  )
}
