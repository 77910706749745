import { RefObject } from 'react'

import { IAllStops } from '../../../typeDefinitions'
import LineViewCard from './LineViewCard'
import tabletStyles from './LineViewDesktop.module.scss'

interface IDesktopView {
  loading: boolean
  allStops: IAllStops[]
  myRef: RefObject<HTMLDivElement>
  handleClick: (cardNumber: number) => void
  directions: number[]
}

export function DesktopView({ loading, allStops, myRef, handleClick, directions }: IDesktopView) {
  return (
    <div className={tabletStyles.tabletContainer}>
      {loading ? (
        <span>Loading</span>
      ) : (
        allStops.map((line, index) => (
          <div key={index}>
            <div className={tabletStyles.tabletLines}>
              <LineViewCard
                line={line}
                idx={index}
                lineRef={myRef}
                handleClick={() => handleClick(index)}
                directions={directions}
              />
            </div>
            <div className={tabletStyles.tabletStopContainer}>
              {index === 0
                ? directions[0] === 0
                  ? // If index and direction is 0 do this
                    allStops.at(0)?.stopCards
                  : // if index is 0 and direction != 0 .map((_e, i, a) => a[a.length - 1 - i] reverses the array
                    allStops.at(0)?.stopCards.map((_e, i, a) => a[a.length - 1 - i])
                : directions[1] === 0
                ? // else check if direction for tablet is 0 return array
                  allStops.at(1)?.stopCards
                : // else reverse array and return it
                  allStops.at(1)?.stopCards.map((_e, i, a) => a[a.length - 1 - i])}
            </div>
          </div>
        ))
      )}
    </div>
  )
}
