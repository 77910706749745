import React from 'react'

import { IItineraryService, ItineraryService } from '../../services/ItineraryService'

interface IServiceCollection {
  itineraryService: IItineraryService
}

const serviceCollection: IServiceCollection = {
  itineraryService: new ItineraryService(),
}

export const ServiceCollectionContext = React.createContext<IServiceCollection>(serviceCollection)

interface IServiceCollectionProviderProps {
  children: React.ReactNode
}

export default function ServiceCollectionProvider({ children }: IServiceCollectionProviderProps) {
  return <ServiceCollectionContext.Provider value={serviceCollection}>{children}</ServiceCollectionContext.Provider>
}
