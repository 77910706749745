import { useContext, useEffect } from 'react'

import { LanguageContext, LanguageType } from '../components/Providers/LanguageProvider'

export function useSetTitle(titles: Record<LanguageType, string> | string, dep: React.DependencyList = []) {
  const lang = useContext(LanguageContext)

  const baseTitle = 'Bybanen'
  useEffect(() => {
    if (typeof titles === 'string') {
      document.title = `${titles} | ${baseTitle}`
    } else {
      document.title = `${lang.language === 'en' ? titles.en : titles.no} | ${baseTitle}`
    }
  }, [lang.language, ...dep])
}
