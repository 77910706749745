import { useContext } from 'react'

import { LanguageContext } from '../Providers/LanguageProvider'
import styles from './GenericClickableCard.module.scss'

interface IGenericClickableCardProps {
  leftIcon?: string
  rightIcon?: string
  text?: string
  handleClick?: (urlString?: string) => void
  value?: string
  lineId?: number
}

export default function GenericClickableCard({
  leftIcon,
  rightIcon,
  text,
  handleClick,
  value,
  lineId,
}: IGenericClickableCardProps) {
  const lang = useContext(LanguageContext)
  return (
    <button
      className={styles.genericClickableCard}
      tabIndex={0}
      data-testid="GenericClickableCard"
      onClick={(event) => {
        event.preventDefault()
        handleClick?.(value)
      }}
      onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && handleClick}
    >
      {leftIcon && (
        <img
          className={styles.genericClickableCardLeftIcon}
          src={leftIcon}
          alt={lang.language === 'no' ? 'ikon venstreeside' : 'left side icon'}
        />
      )}
      <span>{text}</span>
      {lineId ? (
        <img
          className={styles.genericClickableCardLineIcon}
          src={`/icons/Linje-${lineId}-ikon.svg`}
          alt={lang.language === 'no' ? 'linje ikon' : 'line icon'}
        />
      ) : (
        ''
      )}
      {rightIcon && (
        <img
          className={leftIcon ? styles.genericClickableCardRightIcon : styles.genericClickableCardRightIconNoLeft}
          src={rightIcon}
          alt={lang.language === 'no' ? 'ikon høyreside' : 'right side icon'}
        />
      )}
    </button>
  )
}
