import { useCallback, useEffect } from 'react'

// Key events
export const KEY_NAME_ARROWUP = 'ArrowUp'
export const KEY_NAME_ARROWLEFT = 'ArrowLeft'
export const KEY_NAME_ARROWDOWN = 'ArrowDown'
export const KEY_NAME_ARROWRIGHT = 'ArrowRight'
export const KEYUP_EVENT = 'keyup'
export const KEYDOWN_EVENT = 'keydown'

// Provide a function that will handle the keys, and a list of keys to listen for
export function useArrowKeys(handleArrowKeys: Function, keysToHandle: string[]) {
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => keysToHandle.includes(event.key) && handleArrowKeys(event.key),
    [handleArrowKeys]
  )

  useEffect(() => {
    document.addEventListener(KEYUP_EVENT, handleKeyUp, false)
    return () => {
      document.removeEventListener(KEYUP_EVENT, handleKeyUp, false)
    }
  }, [handleKeyUp])

  // Next part is used to disable default arrowkey keydown events, like scrolling
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => keysToHandle.includes(event.key) && event.preventDefault(),
    []
  )

  useEffect(() => {
    document.addEventListener(KEYDOWN_EVENT, handleKeyDown, false)
    return () => {
      document.removeEventListener(KEYDOWN_EVENT, handleKeyDown, false)
    }
  }, [handleKeyDown])
}
