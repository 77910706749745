import { useContext } from 'react'

import { LanguageContext } from '../../Providers/LanguageProvider'
import { ThemeContext } from '../../Providers/ThemeProvider'
import styles from '../Direction.module.scss'

interface IDirectionCardProps {
  left?: boolean
  chosenDirection: string
  endStopName: string
  changeDirection(cardDirection: string): void
  currentStop: string
}

export default function DirectionCard({
  left,
  chosenDirection,
  endStopName,
  changeDirection,
  currentStop,
}: IDirectionCardProps) {
  const direction = left ? 'Left' : 'Right'
  const { theme } = useContext(ThemeContext)
  const lang = useContext(LanguageContext)

  function chooseStyling(direction: string, endStopName: string): string {
    return currentStop === endStopName
      ? `directionCard${direction}Blurred`
      : chosenDirection === endStopName
      ? `directionCard${direction}Active`
      : chosenDirection === ''
      ? `directionCard${direction}`
      : `directionCard${direction}OtherActive`
  }
  return (
    <div
      aria-label={lang.language === 'en' ? 'Direction ' + endStopName : 'Retning ' + endStopName}
      tabIndex={currentStop === endStopName ? -1 : 0}
      className={`${styles[chooseStyling(direction, endStopName)]} ${styles[theme]}`}
      data-testid={'DirectionCard' + direction}
      onClick={() => changeDirection(endStopName)}
      onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && changeDirection(endStopName)}
    >
      <p className={`${styles.directionCardText} ${styles[theme]}`} unselectable="on">
        {endStopName == 'Fyllingsdalen terminal' ? 'Fyllingsdalen' : endStopName}
      </p>
      <img className={styles.directionCardBybaneMob} src={'/icons/Bybanen-Mob.svg'} alt="bybane-ikon" />
      <img className={styles.directionCardBybaneDesk} src={'/icons/Bybanen-Desk.svg'} alt="bybane-ikon" />
      <img className={styles.directionCardBar} src={'/icons/bar.svg'} alt="bybane-linje" />
      <img className={styles.directionCardBar} src={'/icons/bar.svg'} alt="bybane-linje" />
      <img className={styles.directionCardArrow} src={'/icons/arrow.svg'} alt="bybane-pil" />
      {currentStop === endStopName ? (
        <img className={styles.directionCardCircle} src={'/icons/pin.svg'} alt="bybane-pin" />
      ) : (
        <img
          className={styles.directionCardCircle}
          src={`${'/icons/circle-'}${[theme]}${'.svg'}`}
          alt={lang.language == 'en' ? 'bybane-circle' : 'bybane-sirkel'}
        />
      )}
    </div>
  )
}
