import { useQuery } from '@tanstack/react-query'
import { RefObject, useContext, useState } from 'react'

import { LanguageContext } from '../../../components/Providers/LanguageProvider'
import { getElementFromPage, getPage } from '../../../services/SanityService'
import { IAllStops, IPage, PageName } from '../../../typeDefinitions'
import tabletStyles from './LineViewDesktop.module.scss'
import styles from './LineViewMobile.module.scss'

interface ILineViewCardProps {
  lineRef: RefObject<HTMLDivElement>
  idx: number
  line: IAllStops
  handleClick: () => void
  directions: number[]
  mobile?: boolean
}

export default function LineViewCard({ lineRef, idx, line, handleClick, directions, mobile }: ILineViewCardProps) {
  const [active, setActive] = useState(false)
  const lang = useContext(LanguageContext)

  const { data: sanityData } = useQuery<IPage>([PageName.LinePage, lang.language], () =>
    getPage(PageName.LinePage, lang.language)
  )
  return (
    <div
      ref={lineRef}
      className={mobile ? styles.line : tabletStyles.tablet}
      key={`lineId${idx}`}
      id={`lines${line.id}`}
      onClick={() => {
        handleClick(), setActive(!active)
      }}
    >
      <div className={mobile ? styles.lineID : tabletStyles.lineID}>
        <h2>{`${getElementFromPage('h2Line', sanityData, lang.language)} ${line.id}`}</h2>
      </div>
      <div className={mobile ? styles.firstStop : tabletStyles.firstStop}>
        <DirectionChange mobile={mobile} directions={directions} line={line} idx={idx} direction={0} />
      </div>
      <div className={mobile ? styles.lastStop : tabletStyles.lastStop}>
        <DirectionChange mobile={mobile} directions={directions} line={line} idx={idx} direction={1} />
      </div>
      <button
        aria-label={lang.language === 'no' ? 'Bytt linje knapp' : 'Change line button'}
        className={mobile ? styles.button : idx === 0 ? styles.button : tabletStyles.tabletButton}
        onClick={() => setActive(!active)}
      >
        <img
          className={active ? styles.normal : styles.active}
          src="/icons/switchline.svg"
          alt={lang.language === 'no' ? 'bytt retning' : 'switch direction'}
        />
      </button>
    </div>
  )
}

interface IDirectionChange {
  mobile?: boolean
  directions: number[]
  line: IAllStops
  idx: number
  direction: number
}
function DirectionChange({ mobile = false, directions, line, idx, direction }: IDirectionChange) {
  const directionSwitch = (directions: number, direction: number): string => {
    return directions === direction ? line.firstAndLastStop[0] : line.firstAndLastStop[1]
  }

  return (
    <h2>
      {mobile
        ? directionSwitch(directions[idx], direction)
        : idx === 0
        ? directionSwitch(directions[0], direction)
        : directionSwitch(directions[1], direction)}
    </h2>
  )
}
