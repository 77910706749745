import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './ToastMessage.module.scss'

export interface ToastMessageProps {
  success: boolean
}

export default function ToastMessage({ success }: ToastMessageProps) {
  const navigate = useNavigate()

  const prompt1 = success ? 'Tusen takk!' : 'Noe gikk feil!'
  const prompt2 = success ? 'Din melding har blitt sendt.' : 'Vennligst prøv igjen senere.'
  const imageURL = success ? '/icons/Checkmark.svg' : '/icons/Crossmark.svg'

  useEffect(() => {
    const timeout = setTimeout(() => navigate('/'), 2000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <div className={styles.messageContainer}>
        <div className={styles.gradient}>
          <div className={styles.wrapper}>
            <img src={imageURL} />
            <h1>{prompt1}</h1>
            <h2>{prompt2}</h2>
          </div>
        </div>
      </div>
    </>
  )
}
