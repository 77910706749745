import { useContext } from 'react'

import { OccupancyEnum } from '../../../../typeDefinitions'
import { LanguageContext } from '../../../Providers/LanguageProvider'
import styles from './Occupancy.module.scss'

interface IOccupancyProps {
  occupancy?: OccupancyEnum
}

export default function Occupancy({ occupancy }: IOccupancyProps) {
  function getOccupancyIconSrc(): string[] {
    const lang = useContext(LanguageContext)
    switch (occupancy) {
      case OccupancyEnum.Full:
        return new Array(
          'icons/Kapasitet=Trangt.svg',
          lang.language === 'no' ? 'lite ledig eller fullt' : 'limited availability'
        )
      case OccupancyEnum.StandingAvailable:
        return new Array(
          'icons/Kapasitet=Folksomt.svg',
          lang.language === 'no' ? 'ledig ståplass' : 'free standing space'
        )
      case OccupancyEnum.SeatsAvailable:
        return new Array('icons/Kapasitet=Ledig.svg', lang.language === 'no' ? 'ledig sitteplass' : 'free seats')
      case OccupancyEnum.ManySeatsAvailable:
        return new Array('icons/Kapasitet=Ledig.svg', lang.language === 'no' ? 'ledig sitteplass' : 'free seats')
      default:
        return new Array('icons/Kapasitet=Placeholder.svg', lang.language === 'no' ? 'kapasitet' : 'capacity')
    }
  }
  return (
    <img
      className={styles.occupancy}
      data-testid="occupancy"
      title="occupancy"
      src={getOccupancyIconSrc()[0]}
      alt={getOccupancyIconSrc()[1]}
    />
  )
}
